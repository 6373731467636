import AuthContextProvider from "../context/AuthContext";
import MainContextProvider from "../context/MainContext";
import MainContainer from "./MainContainer";

export default function Layout() {
	return (
		<AuthContextProvider>
			<MainContextProvider>
				<MainContainer />
			</MainContextProvider>
		</AuthContextProvider>
	);
}
