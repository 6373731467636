import { useContext, useState } from "react";
import { MagnifyingGlassIcon, FunnelIcon } from "@heroicons/react/24/outline";

import { UIContext } from "../../context/UIContext";
import NewTransfer from "../../components/forms/NewTransfer";
import { handleNavigate } from "../../utils/misc";

export default function Transfers() {
	const [showFilter, setShowFilter] = useState(false);
	const { setSlideOver } = useContext(UIContext);
	const showAddTransfer = (e) => {
		handleNavigate(e, "showAddTransferPage", setSlideOver);
	};
	return (
		<>
			<section>
				<div className="flex justify-between items-center mt-6">
					<div>
						<h1 className="greeting text-3xl">Transfers</h1>
						<p className="pt-3">A list of all your transfers.</p>
					</div>
					<div className="flex items-center gap-4">
						<form className="flex items-center">
							<div className="relative mt-1 rounded-md shadow-sm w-72">
								<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
									<MagnifyingGlassIcon
										className="h-5 w-5 text-gray-400"
										aria-hidden="true"
									/>
								</div>
								<input
									type="text"
									name="filter"
									id="filter"
									className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm outline-none"
									placeholder="Search or filter results"
								/>
							</div>
							<button
								className="flex items-center justify-center pl-3"
								onClick={(e) => {
									e.preventDefault();
									setShowFilter(!showFilter);
								}}
							>
								<FunnelIcon
									className="h-6 w-6"
									aria-hidden="true"
								/>
							</button>
						</form>
						<button
							className="select flex items-center text-[#2046cf] p-2 px-4 rounded-3xl border border-[#2046cf] hover:bg-[#2046cf] hover:text-white"
							onClick={showAddTransfer}
						>
							<span className="text-md font-medium">
								New transfer
							</span>
							<span className="material-symbols-outlined">
								add
							</span>
						</button>
					</div>
				</div>
				<div
					className={`grid-cols-3 mt-3 gap-4 ${
						showFilter ? "grid" : "hidden"
					}`}
				>
					<div>
						<label
							htmlFor="branch"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by type
						</label>
						<select
							id="branch"
							name="branch"
							className="bg-white border mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						>
							<option>Invoice</option>
							<option>Customer Payment</option>
							<option>Bill</option>
						</select>
					</div>
					<div>
						<label
							htmlFor="status"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by account
						</label>
						<select
							id="status"
							name="status"
							className="border bg-white mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						>
							<option>All</option>
							<option>Canada</option>
							<option>Mexico</option>
						</select>
					</div>
				</div>
			</section>
			<section className="mt-2">
				<table className="min-w-full divide-y divide-gray-200">
					<thead className="bg-gray-50">
						<tr>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Created Date
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								From Account
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								To Account
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Amount
							</th>
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						<tr>
							<td className="px-6 py-4 whitespace-nowrap">
								<div className="flex items-center">
									<div className="ml-4">
										<div className="text-sm font-medium text-gray-900">
											06 Apr 2023
										</div>
										<div className="text-sm text-gray-500">
											ACT-000001
										</div>
									</div>
								</div>
							</td>
							<td className="px-6 py-4 whitespace-nowrap">
								<div className="text-sm text-gray-900">
									Cash
								</div>
							</td>
							<td className="px-6 py-4 whitespace-nowrap">
								<div className="text-sm text-gray-900">
									Cash
								</div>
							</td>
							<td className="px-6 py-4 whitespace-nowrap">
								<div className="text-sm text-gray-900">
									D200.00
								</div>
							</td>
							<td
								className="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
							>
								<button className="text-indigo-600 hover:text-indigo-900">
									View
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</section>
			<NewTransfer />
		</>
	);
}
