import HighCharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { formatCashflow } from "../../lib/formatter";

const today = new Date().getDate();

const placeholder = {
	income: [
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
	],
	outgoing: [
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
		null,
	],
	categories: [],
};

const ProfitLoss = ({ profitloss }) => {
	const [data, setData] = useState({
		...placeholder,
	});
	const options = {
		colors: ["#23C770", "#8AA2B2"],
		chart: {
			type: "column",
		},
		title: {
			text: "",
		},
		xAxis: {
			categories:
				data.categories.length === 0
					? [
							`Jan ${today}`,
							`Feb ${today}`,
							`Mar ${today}`,
							`Apr ${today}`,
							`May ${today}`,
							`Jun ${today}`,
							`Jul ${today}`,
							`Aug ${today}`,
							`Sep ${today}`,
							`Oct ${today}`,
							`Nov ${today}`,
							`Dec ${today}`,
					  ]
					: data.categories,
			crosshair: true,
		},
		yAxis: {
			min: 0,
		},
		tooltip: {
			headerFormat:
				'<span style="font-size:10px">{point.key}</span><table>',
			pointFormat:
				'<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
				'<td style="padding:0"><b>D{point.y:.1f}</b></td></tr>',
			footerFormat: "</table>",
			shared: true,
			useHTML: true,
		},
		plotOptions: {
			column: {
				pointPadding: 0.2,
				borderWidth: 0,
			},
		},
		series: [
			{
				name: "Profit",
				data: data.income,
			},
			{
				name: "Loss",
				data: data.outgoing,
			},
		],
	};

	//const getChart = () => reflowChart();
	useEffect(() => {
		if (profitloss) {
			if (profitloss[0]) {
				const { income, outgoing, netchange, categories } =
					formatCashflow(profitloss[0].profitLoss);
				setData({ income, outgoing, netchange, categories });
			}
		}
	}, [profitloss]);

	return <HighchartsReact highcharts={HighCharts} options={options} />;
};

export default ProfitLoss;
