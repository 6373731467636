import { useContext, useState } from "react";
import { MagnifyingGlassIcon, FunnelIcon } from "@heroicons/react/24/outline";

import NewStocksComponent from "../components/forms/NewStocks";
import { UIContext } from "../context/UIContext";
import { formatPrice, handleNavigate } from "../utils/misc";
import { useQuery } from "react-query";
import { get } from "../utils/transfers";
import EmptyState from "../components/utils/EmptyState";

export default function Stocks() {
	const { data: transfers } = useQuery("transfers", get);
	const { setSlideOver } = useContext(UIContext);
	const [showFilter, setShowFilter] = useState(false);

	const showAddStock = (e) => {
		handleNavigate(e, "showAddStockTransferPage", setSlideOver);
	};

	const totalPrice = (products) => {
		let total = 0;
		products.forEach((prod) => {
			total += prod.selling_price * prod.quantity;
		});

		return formatPrice(total);
	};

	return (
		<>
			<section>
				<div className="flex justify-between items-center mt-6">
					<div>
						<h1 className="greeting text-3xl">Stocks</h1>
						<p className="pt-3">
							A list of all stock transfers initiated.
						</p>
					</div>
					<div className="flex items-center gap-4">
						<form className="flex items-center">
							<div className="relative mt-1 rounded-md shadow-sm w-72">
								<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
									<MagnifyingGlassIcon
										className="h-5 w-5 text-gray-400"
										aria-hidden="true"
									/>
								</div>
								<input
									type="text"
									name="filter"
									id="filter"
									className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm outline-none"
									placeholder="Search"
								/>
							</div>
							<button
								className="flex items-center justify-center pl-3"
								onClick={(e) => {
									e.preventDefault();
									setShowFilter(!showFilter);
								}}
							>
								<FunnelIcon
									className="h-6 w-6"
									aria-hidden="true"
								/>
							</button>
						</form>
						<button
							onClick={showAddStock}
							className="select flex items-center text-[#2046cf] p-2 px-4 rounded-3xl border border-[#2046cf] hover:bg-[#2046cf] hover:text-white"
						>
							<span className="text-md font-medium">
								New stock transfer
							</span>
							<span className="material-symbols-outlined">
								add
							</span>
						</button>
					</div>
				</div>
				<div
					className={`grid-cols-3 mt-3 gap-4 ${
						showFilter ? "grid" : "hidden"
					}`}
				>
					<div>
						<label
							htmlFor="branch"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by store (from)
						</label>
						<select
							id="branch"
							name="branch"
							className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						>
							<option>Brikama</option>
							<option>Canada</option>
							<option>Mexico</option>
						</select>
					</div>
					<div>
						<label
							htmlFor="status"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by store (to)
						</label>
						<select
							id="status"
							name="status"
							className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						>
							<option>All</option>
							<option>Canada</option>
							<option>Mexico</option>
						</select>
					</div>
				</div>
			</section>
			<section className="mt-2">
				{!transfers || transfers?.length === 0 ? (
					<EmptyState
						title={"transfer"}
						stateKey="showAddStockTransferPage"
					/>
				) : (
					<table className="min-w-full divide-y divide-gray-200">
						<thead className="bg-gray-50">
							<tr>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									#
								</th>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Price of Products
								</th>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									No. of Products
								</th>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									To
								</th>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Added By
								</th>
								<th scope="col" className="relative px-6 py-3">
									<span className="sr-only">Edit</span>
								</th>
							</tr>
						</thead>
						<tbody className="bg-white divide-y divide-gray-200">
							{transfers?.map((transfer, index) => {
								return (
									<tr key={index}>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="flex items-center">
												<div className="text-sm font-medium text-gray-900">
													TRN-0000{index + 1}
												</div>
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												D{totalPrice(transfer.products)}
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												{transfer.products.length}
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												{transfer.store_destination_id
													?.name ||
													transfer.shop_destination_id
														?.name}
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												{transfer.transfer_by.name}
											</div>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}

				<NewStocksComponent />
			</section>
		</>
	);
}
