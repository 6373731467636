import apiClient from "./fetch";

export async function create(form, setErrors, setLoading) {
	setLoading(true);
	let success = false;

	try {
		const { data: payment } = await apiClient.post("/payment", form);
		success = true;
		setLoading(false);

		return [success, payment];
	} catch (error) {
		if (error.response.status === 400) {
			if (typeof error.response.data.error === "string") {
				setErrors({
					type: "credentials",
					info: error.response.data.error,
				});
			} else {
				setErrors({
					type: "validation",
					info: error.response.data.error.details,
				});
			}
		}
		success = false;
		setLoading(false);

		return [success, error];
	}
}

export async function get() {
	try {
		const { data: payment } = await apiClient.get("/payment");
		return payment.data;
	} catch (error) {
		throw error;
	}
}
