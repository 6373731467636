import { Link } from "react-router-dom";

export default function Charts() {
	return (
		<div className="grid min-h-full place-items-center bg-white py-24 sm:py-32">
			<div className="text-center">
				<p className="text-base font-semibold text-[#2046cf]">404</p>
				<h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
					Coming Soon!
				</h1>
				<p className="mt-6 text-base leading-7 text-gray-600">
					The service you are looking for is not yet available.
					<br /> Please check again later.
				</p>
				<div className="mt-10 flex items-center justify-center gap-x-6">
					<Link
                        to={'/'}
						className="rounded-3xl bg-[#2046cf] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
					>
						Go back home
					</Link>
					<Link to={'/'} className="text-sm font-semibold text-gray-900">
						Contact support <span aria-hidden="true">&rarr;</span>
					</Link>
				</div>
			</div>
		</div>
	);
}
