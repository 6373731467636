import { Fragment, useState, useEffect, useContext } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Dialog, Transition } from "@headlessui/react";
import {
	XMarkIcon,
	XCircleIcon,
	ArrowPathIcon,
} from "@heroicons/react/24/outline";

import { UIContext } from "../../context/UIContext";
import Comboboxes from "../utils/Combobox";
import * as Product from "../../utils/products";
import * as Customer from "../../utils/customers";
import * as Sales from "../../utils/sales";
import NewCustomer from "./NewCustomer";
import { handleNavigate } from "../../utils/misc";
import Toggle from "../utils/Toggle";

export default function NewReceipt({ setSuccess, setFailure }) {
	const queryClient = useQueryClient();
	const { data: allProducts } = useQuery("products", Product.get);
	const { data: customers } = useQuery("customers", Customer.get);
	const { slideOver, setSlideOver } = useContext(UIContext);
	const [customer, setCustomer] = useState({});
	const [product, setProduct] = useState({});
	const [products, setProducts] = useState([]);
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [isCredit, setIsCredit] = useState(false);
	const [form, setForm] = useState({
		date: "",
	});

	const handleQuantityChange = (e, id) => {
		const value = e.target.value;
		const newProducts = products.map((p) => {
			if (p._id === id) {
				p.quantity = value;
				p.available_for_Sale = value;
			}
			return p;
		});

		setProducts(newProducts);
	};

	const handlePriceChange = (e, id) => {
		const value = e.target.value;
		const newProducts = products.map((p) => {
			if (p._id === id) {
				p.selling_price = value;
			}
			return p;
		});
		setProducts(newProducts);
	};

    const closeModal = event => {
        event.preventDefault();
        setSlideOver(prevState => {
            return {
                ...prevState,
                showAddReceiptPage: false
            }
        });
    }

	const removeProduct = (e, id) => {
		e.preventDefault();
		const newProducts = products.filter((p) => p._id !== id);
		if (newProducts.length === 0) setProduct({});
		setProducts(newProducts);
	};

	const updateForm = (e) => {
		setForm((prevForm) => {
			return {
				...prevForm,
				date: e.target.value,
			};
		});
	};

	const generateReceipt = async (e) => {
		e.preventDefault();

		const payload = {
			products,
			customer_id: customer._id,
			payment_mode: "cash",
		};
		if (isCredit) {
			payload.isCredit = true;
			let total = 0;
			products.forEach((prod) => {
				total += prod.selling_price * prod.available_for_Sale;
			});
			payload.credit_amount = total;
			payload.payment_mode = "credit";
		}
		const [status, sale] = await Sales.create(
			payload,
			setErrors,
			setLoading
		);
		if (status) {
			queryClient.invalidateQueries("sales");
			setSlideOver((prevState) => {
				return {
					...prevState,
					showAddReceiptPage: false,
				};
			});
			setSuccess(true);
		} else {
			setFailure(true);
			setSlideOver((prevState) => {
				return {
					...prevState,
					showAddReceiptPage: false,
				};
			});
		}
	};

	const showAddCustomer = (e) => {
		handleNavigate(e, "showAddCustomerPage", setSlideOver);
	};

	useEffect(() => {
		if (product._id && !products.includes(product)) {
			setProducts((prevState) => {
				return [...prevState, product];
			});
		}
	}, [product]);

	return (
		<>
			<Transition.Root
				show={slideOver.showAddReceiptPage || false}
				as={Fragment}
			>
				<Dialog
					as="div"
					className="relative z-10"
					onClose={setSlideOver}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-in-out duration-500"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in-out duration-500"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-hidden">
						<div className="absolute inset-0 overflow-hidden">
							<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
								<Transition.Child
									as={Fragment}
									enter="transform transition ease-in-out duration-500 sm:duration-700"
									enterFrom="translate-x-full"
									enterTo="translate-x-0"
									leave="transform transition ease-in-out duration-500 sm:duration-700"
									leaveFrom="translate-x-0"
									leaveTo="translate-x-full"
								>
									<Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
										<div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
											<div className="px-4 sm:px-6">
												<div className="flex items-start justify-between">
													<Dialog.Title className="text-xl font-medium text-gray-900">
														New Receipt
													</Dialog.Title>
													<div className="ml-3 flex h-7 items-center">
														<button
															type="button"
															className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
															onClick={() =>
																setSlideOver(
																	(
																		prevState
																	) => {
																		return {
																			...prevState,
																			showAddReceiptPage: false,
																		};
																	}
																)
															}
														>
															<span className="sr-only">
																Close panel
															</span>
															<XMarkIcon
																className="h-6 w-6"
																aria-hidden="true"
															/>
														</button>
													</div>
												</div>
											</div>
											<div className="relative mt-6 flex-1 px-4 sm:px-6">
												<form>
													<div className="mb-4">
														<Comboboxes
															title={
																"Select Customer *"
															}
															list={customers}
															selectedItem={
																customer
															}
															setSelectedItem={
																setCustomer
															}
															loadView={
																showAddCustomer
															}
														/>
													</div>
													<div className="grid grid-cols-2 gap-3">
														<div className="mb-4">
															<label
																htmlFor="receipt_number"
																className="block text-sm font-medium text-gray-700"
															>
																Receipt Number
																<span className="text-red-700">
																	*
																</span>
															</label>
															<div className="mt-2 mb-2 ">
																<input
																	type="text"
																	name="receipt_number"
																	disabled
																	value={
																		"SLR-000001"
																	}
																	id="receipt_number"
																	className="block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
																/>
															</div>
														</div>
														<div className="mb-4">
															<label
																htmlFor="date"
																className="block text-sm font-medium text-gray-700"
															>
																Date{" "}
																<span className="text-red-700">
																	*
																</span>
															</label>
															<div className="mt-2 mb-2 ">
																<input
																	type="date"
																	value={
																		form.date
																	}
																	onChange={
																		updateForm
																	}
																	name="date"
																	id="date"
																	className="block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
																/>
															</div>
														</div>
													</div>
													<div className="mt-5 sm:mt-6">
														<p className="block text-sm font-medium text-gray-700 pb-1">
															Add receipt as a
															credit
														</p>
														<Toggle
															enabled={isCredit}
															setEnabled={
																setIsCredit
															}
														/>
													</div>
													<div className="mt-8">
														<Comboboxes
															list={allProducts}
															title={
																"Enter product name *"
															}
															selectedItem={
																product
															}
															setSelectedItem={
																setProduct
															}
														/>
														<p className="text-sm text-gray-500 mt-2">
															Type the product
															name you want to
															include in the
															receipt and select.
														</p>
														{products.length > 0 ? (
															<div className="mt-2">
																<table className="min-w-full divide-y divide-gray-200">
																	<thead className="bg-gray-50">
																		<tr>
																			<th
																				scope="col"
																				className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
																			>
																				Name
																			</th>
																			<th
																				scope="col"
																				className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
																			>
																				Quantity
																				(Available)
																			</th>
																			<th
																				scope="col"
																				className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
																			>
																				Price
																			</th>
																		</tr>
																	</thead>
																	<tbody className="bg-white divide-y divide-gray-200">
																		{products.map(
																			(
																				p
																			) => {
																				return (
																					<tr
																						key={
																							p._id
																						}
																					>
																						<td className="px-6 py-4 whitespace-nowrap">
																							<div className="text-sm text-gray-900">
																								{
																									p.name
																								}
																							</div>
																						</td>
																						<td className="px-6 py-4 whitespace-nowrap">
																							<div className="text-sm text-gray-900">
																								<input
																									value={
																										p.available_for_Sale
																									}
																									placeholder={p.available_for_Sale.toString()}
																									className="border border-gray-300 p-1 rounded-md w-16"
																									name={
																										p._id
																									}
																									onChange={(
																										e
																									) =>
																										handleQuantityChange(
																											e,
																											p._id
																										)
																									}
																								/>
																							</div>
																						</td>
																						<td className="px-6 py-4 whitespace-nowrap">
																							<div className="text-sm font-bold text-gray-900">
																								GMD{" "}
																								<input
																									value={
																										p.selling_price
																									}
																									name={
																										p._id
																									}
																									onChange={(
																										e
																									) =>
																										handlePriceChange(
																											e,
																											p._id
																										)
																									}
																									className="border border-gray-300 p-1 rounded-md w-16"
																								/>
																							</div>
																						</td>
																						<td className="py-4 whitespace-nowrap">
																							<button
																								onClick={(
																									e
																								) =>
																									removeProduct(
																										e,
																										p._id
																									)
																								}
																							>
																								<XCircleIcon
																									width={
																										20
																									}
																									height={
																										20
																									}
																									className="text-red-500"
																								/>
																							</button>
																						</td>
																					</tr>
																				);
																			}
																		)}
																	</tbody>
																</table>
															</div>
														) : (
															<>
																<div className="text-center mt-12">
																	<svg
																		className="mx-auto h-12 w-12 text-gray-400"
																		fill="none"
																		viewBox="0 0 24 24"
																		stroke="currentColor"
																		aria-hidden="true"
																	>
																		<path
																			vectorEffect="non-scaling-stroke"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																			strokeWidth={
																				2
																			}
																			d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
																		/>
																	</svg>
																	<h3 className="mt-2 text-sm font-medium text-gray-900">
																		No
																		products
																	</h3>
																	<p className="mt-1 text-sm text-gray-500">
																		Click on
																		the
																		select
																		menu to
																		add a
																		product
																	</p>
																</div>
															</>
														)}
														<div className="flex gap-4 mt-8">
															<button
																type="button"
                                                                onClick={(e) => closeModal(e)}
																className="w-32 inline-flex items-center rounded-md justify-center border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-1/3"
															>
																Cancel
															</button>
															<button
																onClick={
																	generateReceipt
																}
																type="button"
																className="inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm bg-[#2046cf] w-2/3"
															>
																Generate receipt
																{loading && (
																	<ArrowPathIcon
																		height={
																			23
																		}
																		width={
																			23
																		}
																		className="animate-spin"
																	/>
																)}
															</button>
														</div>
													</div>
												</form>
											</div>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</div>
				</Dialog>
			</Transition.Root>

			<NewCustomer />
		</>
	);
}
