import { useState } from "react";
import { formatPrice, toggleStatusColor } from "../../../utils/misc";
import { vendorBalances } from "../../../utils/reports";

export default function VendorAccounts() {
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({
		startDate: "",
		endDate: "",
	});
	const [bills, setBills] = useState([]);

	const updateForm = (event) => {
		setForm((prevState) => {
			return {
				...prevState,
				[event.target.name]: event.target.value,
			};
		});
	};

    const calculateAmount = products => {
        let total = 0;
        products.forEach(product => {
            total += product.selling_price * product.quantity
        });

        return total;
    }

	const fetchReport = async (event) => {
		event.preventDefault();
		setLoading(true);
		if (Object.keys(form).length === 2) {
			try {
				const { data } = await vendorBalances({ ...form });
				console.log(data);
				setBills(data);
			} catch (error) {
				console.log("error fetching customers information");
			} finally {
				setLoading(false);
			}
		}
	};
	return (
		<>
			<form className="flex items-center gap-6">
				<div className="flex items-center gap-5">
					<input
						type={"date"}
						name="startDate"
						value={form.startDate}
						onChange={updateForm}
						className="border border-gray-400 p-2 rounded w-48"
					/>
					<input
						type={"date"}
						name="endDate"
						value={form.endDate}
						onChange={updateForm}
						className="border border-gray-400 p-2 rounded w-48"
					/>
				</div>
				<button
					className="select flex items-center text-white p-3 px-4 rounded-3xl bg-[#2046cf]"
					onClick={fetchReport}
					disabled={loading}
				>
					<span className="text-sm">Update report</span>
				</button>
			</form>
			<table className="min-w-full divide-y divide-gray-200 mt-8">
				<thead className="bg-gray-50">
					<tr>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Status
						</th>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Bill Date
						</th>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Due Date
						</th>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Bill #
						</th>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Vendor Name
						</th>

						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Bill Amount
						</th>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Balance
						</th>
					</tr>
				</thead>
				<tbody className="bg-white divide-y divide-gray-200">
					{bills?.map((bill, index) => {
						return (
							<tr key={index}>
								<td className="px-6 py-4 whitespace-nowrap">
                                    {toggleStatusColor(bill.status)}
								</td>
								{/*
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="text-sm text-gray-900">
											PRD-000001
										</div>
									</td>
                                    */}
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										{new Date(bill.createdAt).toDateString()}
									</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										{new Date(bill.due_date).toDateString()}
									</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										{bill.number}
									</div>
								</td>

								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										{bill.vendor.name}
									</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										D{formatPrice(calculateAmount(bill.products))}
									</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										D{formatPrice(calculateAmount(bill.products) - bill.totalPayments)}
									</div>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
}
