import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { UserIcon } from "@heroicons/react/24/solid";

export default function PreviewCustomer({ open, setOpen }) {
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-500"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-500"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
									<div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
										<div className="px-4 sm:px-6">
											<div className="flex items-start justify-between">
												<Dialog.Title className="text-xl font-medium text-gray-900">
													Saul Sawaneh
												</Dialog.Title>
												<div className="ml-3 flex h-7 items-center">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
														onClick={() =>
															setOpen(!open)
														}
													>
														<span className="sr-only">
															Close panel
														</span>
														<XMarkIcon
															className="h-6 w-6"
															aria-hidden="true"
														/>
													</button>
												</div>
											</div>
										</div>
										<div className="relative mt-1 flex-1 px-4 sm:px-6">
											<div className="flex items-center">
												<div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center">
													<UserIcon
														width={15}
														height={15}
														color={"#fff"}
													/>
												</div>
												<div className="pl-2 flex flex-col">
													<span className="text-sm">
														alieundimbalane@gmail.com
													</span>
													<div className="flex items-center gap-3">
														<button className="text-xs text-blue-500">
															Edit
														</button>
														<button className="text-xs text-blue-500">
															Delete
														</button>
													</div>
												</div>
											</div>
											<div className="flex gap-24 mt-4">
												<div className="flex flex-col gap-3">
													<span className="text-sm text-gray-400">
														Customer Type
													</span>
													<span className="text-sm text-gray-400">
														Phone
													</span>
													<span className="text-sm text-gray-400">
														Balance | Due
													</span>
													<span className="text-sm text-gray-400">
														Unused Credit
													</span>
												</div>
												<div className="flex flex-col gap-3">
													<span className="text-sm">
														Individual
													</span>
													<span className="text-sm">
														(220) 2123445
													</span>
													<span className="text-sm">
														D600.00
													</span>
													<span className="text-sm">
														D900.00
													</span>
												</div>
											</div>
											<div>
												<h3 className="mt-4">Unpaid invoices</h3>
												<table className="min-w-full divide-y divide-gray-200 mt-2">
					<thead className="bg-gray-50">
						<tr>
							<th
								scope="col"
								className="
                      pr-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Status
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Due
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Number
							</th>

							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Amount
							</th>
							<th scope="col" className="relative px-6 py-3">
								<span className="sr-only">Edit</span>
							</th>
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						<tr>
							<td className="pr-6 py-4 whitespace-nowrap">
								<span
									className="
                        px-2
                        inline-flex
                        text-xs
                        leading-5
                        font-semibold
                        rounded-full
                        bg-green-100
                        text-green-800
                      "
								>
									Paid
								</span>
							</td>
							<td className="px-6 py-4 whitespace-nowrap">
								<div className="text-sm text-gray-900">
									0 days ago
								</div>
							</td>
							<td className="px-6 py-4 whitespace-nowrap">
								<div className="text-sm text-gray-900">1</div>
							</td>
							<td className="px-6 py-4 whitespace-nowrap">
								<div className="text-sm font-bold text-gray-900">
									GMD 100.00
								</div>
							</td>
							
							<td
								className="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
							>
								<button className="text-indigo-600 hover:text-indigo-900">
									View
								</button>
							</td>
						</tr>
					</tbody>
				</table>
											</div>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
