import apiClient from "./fetch";

export async function create(form, setErrors, setLoading) {
	setLoading(true);
    let success = false;
	try {
		const employee = await apiClient.post("/employees/add", form);
        success = true;
		setLoading(false);
		return [success, employee];
	} catch (error) {
		if (error.response.status < 500) {
            if(typeof error.response.data.error === 'string') {
		        setErrors({ type: "credentials", info: error.response.data.error });
            } else {
		        setErrors({ type: "validation", info: error.response.data.error.details });
            }
		}
        success = false;
		setLoading(false);
		return [success, error];
	}
}

export async function get() {
	try {
		const { data: employees } = await apiClient.get("/employees/all");
		return Object.values(employees);
	} catch (error) {
        throw error;
	}
}

export async function deleteEmployee(id) {
    let success = false;

    try {
        const { data } = await apiClient.delete(`/employees/${id}`);
        return data;
    } catch(error) {
        return success;
    }
}
