import { useState } from "react";
import { customerBalances } from "../../../utils/reports";
import { formatPrice } from "../../../utils/misc";

export default function CustomerBalances() {
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({
		startDate: "",
		endDate: "",
	});
	const [customers, setCustomers] = useState([]);

	const updateForm = (event) => {
		setForm((prevState) => {
			return {
				...prevState,
				[event.target.name]: event.target.value,
			};
		});
	};

	const fetchReport = async (event) => {
		event.preventDefault();
        setLoading(true);
        if(Object.keys(form).length === 2) {
            try {
                const { data } = await customerBalances({...form});
                console.log(data);
                setCustomers(data);
            } catch(error) {
                console.log('error fetching customers information')
            } finally {
                setLoading(false);
            }
        }
	};

	return (
		<>
			<form className="flex items-center gap-6">
				<div className="flex items-center gap-5">
					<input
						type={"date"}
						value={form.startDate}
						onChange={updateForm}
						name="startDate"
						className="border border-gray-400 p-2 rounded w-48"
					/>
					<input
						type={"date"}
						name="endDate"
						value={form.endDate}
						onChange={updateForm}
						className="border border-gray-400 p-2 rounded w-48"
					/>
				</div>
				<button
					className="select flex items-center text-white p-2 px-4 rounded-3xl bg-[#2046cf]"
                    disabled={loading}
					onClick={fetchReport}
				>
					<span className="text-sm">Update report</span>
				</button>
			</form>
			<table className="min-w-full divide-y divide-gray-200 mt-8">
				<thead className="bg-gray-50">
					<tr>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Customer Name
						</th>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Invoice Balance
						</th>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Unpaid Credit
						</th>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Overdue Invoices
						</th>
					</tr>
				</thead>
				<tbody className="bg-white divide-y divide-gray-200">
					{customers?.map((customer, index) => {
						return (
							<tr key={index}>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										{customer.name}
									</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										D{formatPrice(customer.total_unpaid_invoices)}
									</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										D{formatPrice(customer.total_unpaid_credit)}
									</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										D{formatPrice(customer.total_overdue_invoices)}
									</div>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
}
