import { Fragment, useContext, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon, ArrowPathIcon } from "@heroicons/react/24/outline";

import { UIContext } from "../../context/UIContext";
import { toggleErrorBorder } from "../../utils/errors";

import * as Stores from "../../utils/store";
import * as Shops from "../../utils/shop";
import * as Expense from "../../utils/expenses";
import * as Employee from "../../utils/employee";
import Toggle from "../utils/Toggle";
import { useUser } from "../../hooks/auth";
import { notAdmin } from "../../utils/misc";

export default function NewExpense({ setSuccess }) {
	const user = useUser();
	const { slideOver, setSlideOver } = useContext(UIContext);
	const queryClient = useQueryClient();
	const { data: stores } = useQuery("stores", Stores.get);
	const { data: shops } = useQuery("shops", Shops.get);
	const { data: employees } = useQuery("employees", Employee.get);

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [storeMode, setStoreMode] = useState(false);

	const [form, setForm] = useState({
		expense_date: "",
		expense_for: "",
		description: "",
		amount: "",
	});

	const updateForm = (e) => {
		setForm((prevForm) => {
			return {
				...prevForm,
				[e.target.name]: e.target.value,
			};
		});
	};

	const addExpense = async (event) => {
		event.preventDefault();

		const payload = { ...form };
		if (storeMode) {
			payload.store_id = payload.store;
		} else {
			payload.shop_id = payload.store;
		}
		const [success] = await Expense.create(payload, setErrors, setLoading);
		if (success) {
			queryClient.invalidateQueries("expenses");
			setForm({
				expense_date: "",
				expense_for: "",
				description: "",
				amount: "",
			});
			setSlideOver((prevState) => {
				return {
					...prevState,
					showAddExpensePage: false,
				};
			});
			setSuccess(true);
		}
	};

	return (
		<Transition.Root
			show={slideOver.showAddExpensePage || false}
			as={Fragment}
		>
			<Dialog as="div" className="relative z-10" onClose={setSlideOver}>
				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-500"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-500"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto w-screen max-w-lg">
									<div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
										<div className="px-4 sm:px-6">
											<div className="flex items-start justify-between">
												<Dialog.Title className="text-xl font-medium text-gray-900">
													New Expense
												</Dialog.Title>
												<div className="ml-3 flex h-7 items-center">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
														onClick={() =>
															setSlideOver(
																(prevState) => {
																	return {
																		...prevState,
																		showAddExpensePage: false,
																	};
																}
															)
														}
													>
														<span className="sr-only">
															Close panel
														</span>
														<XMarkIcon
															className="h-6 w-6"
															aria-hidden="true"
														/>
													</button>
												</div>
											</div>
										</div>
										<div className="relative mt-6 flex-1 px-4 sm:px-6">
											<form className="mt-5">
												<div className="grid grid-cols-2 gap-3 w-full">
													<div>
														<label
															htmlFor="expense_for"
															className="block text-sm font-medium text-gray-700"
														>
															Expense For
															<span className="text-red-700">
																*
															</span>
														</label>
														<select
															id="expense_for"
															name="expense_for"
															onChange={
																updateForm
															}
															value={
																form.expense_for
															}
															className={`border bg-white mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
																errors,
																"expense_for"
															)}`}
														>
															<option>
																What is the
																expense for
															</option>
															<option
																value={"food"}
															>
																Food
															</option>
															<option
																value={"rent"}
															>
																Rent
															</option>
															<option
																value={"duty"}
															>
																Duty
															</option>
															<option
																value={
																	"unspecified"
																}
															>
																Unspecified
															</option>
														</select>
													</div>
													<div className="">
														<label
															htmlFor="amount"
															className="block text-sm font-medium text-gray-700"
														>
															Amount
															<span className="text-red-700">
																*
															</span>
														</label>
														<div className="mt-1 mb-2 ">
															<input
																type="number"
																name="amount"
																value={
																	form.amount
																}
																onChange={
																	updateForm
																}
																id="amount"
																className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
																	errors,
																	"amount"
																)}`}
															/>
														</div>
													</div>

													<div>
														<label
															htmlFor="expense_date"
															className="block text-sm font-medium text-gray-700"
														>
															Expense Date
															<span className="text-red-700">
																*
															</span>
														</label>
														<div className="mt-1 mb-2 ">
															<input
																type="date"
																name="expense_date"
																onChange={
																	updateForm
																}
																id="expense_date"
																className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
																	errors,
																	"expense_date"
																)}`}
															/>
														</div>
													</div>
												</div>
												<div className="my-4">
													<p className="block text-sm font-medium text-gray-700 pb-1">
														Expense to a store/shop
													</p>
													<Toggle
														enabled={storeMode}
														setEnabled={
															setStoreMode
														}
													/>
												</div>
												<div className="my-4">
													<label
														htmlFor="store"
														className="block text-sm font-medium text-gray-700 pb-1"
													>
														{storeMode
															? "Store"
															: "Shop"}
													</label>
													<select
														id="store"
														name="store"
														value={form.store}
														onChange={updateForm}
														className={`mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm bg-white border ${toggleErrorBorder(
															errors,
															"store_id"
														)}`}
													>
														<option>
															Select a store/shop
														</option>
														{storeMode
															? stores?.data.map(
																	(
																		store,
																		index
																	) => {
																		return (
																			<option
																				value={
																					store._id
																				}
																				key={
																					index
																				}
																			>
																				{
																					store.name
																				}
																			</option>
																		);
																	}
															  )
															: shops?.data.map(
																	(
																		shop,
																		index
																	) => {
																		return (
																			<option
																				value={
																					shop._id
																				}
																				key={
																					index
																				}
																			>
																				{
																					shop.name
																				}
																			</option>
																		);
																	}
															  )}
													</select>
												</div>
												{notAdmin(user) && (
													<>
														<div className="my-4">
															<label
																htmlFor="employee"
																className="block text-sm font-medium text-gray-700 pb-1"
															>
																Employee
															</label>
															<select
																id="employee"
																name="employee"
																value={
																	form.store
																}
																onChange={
																	updateForm
																}
																className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm bg-white border"
															>
																<option>
																	Select an
																	employee
																</option>
																{employees?.map(
																	(
																		employee,
																		index
																	) => {
																		return (
																			<option
																				key={
																					index
																				}
																			>
																				{
																					employee.name
																				}
																			</option>
																		);
																	}
																)}
															</select>
														</div>
													</>
												)}
												<div className="mt-8 mb-8">
													<label
														htmlFor="description"
														className="block text-sm font-medium text-gray-700"
													>
														Description
														<span className="text-red-700">
															*
														</span>
													</label>
													<div className="mt-2 mb-2">
														<textarea
															type="text"
															rows={5}
															name="description"
															value={
																form.description
															}
															onChange={
																updateForm
															}
															placeholder="What is the expense about"
															id="description"
															className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
																errors,
																"description"
															)}`}
														/>
													</div>
												</div>
												<div className="flex gap-4 mt-8">
													<button
														type="button"
														className="inline-flex items-center rounded-md justify-center border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-1/3"
														onClick={() =>
															setSlideOver(
																(prevState) => {
																	return {
																		...prevState,
																		showAddExpensePage: false,
																	};
																}
															)
														}
													>
														Cancel
													</button>
													<button
														onClick={addExpense}
														type="button"
														className="inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm bg-[#2046cf] w-2/3"
													>
														<span className="pr-1">
															Add expense
														</span>
														{loading && (
															<ArrowPathIcon
																height={23}
																width={23}
																className="animate-spin"
															/>
														)}
													</button>
												</div>
											</form>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
