import { Fragment, useEffect, useState } from "react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import { useQuery, useQueryClient } from "react-query";

import Toggle from "../utils/Toggle";
import * as Stores from "../../utils/store";
import * as Shops from "../../utils/shop";
import * as Employees from "../../utils/employee";

export default function NewStore({ open, setOpen, setSuccess }) {
	const queryClient = useQueryClient();
	const { data: employees } = useQuery("employees", Employees.get);

	const [loading, setLoading] = useState(false);
	const [storeMode, setStoreMode] = useState(false);
	const [errors, setErrors] = useState({});
	const [employeeTypes, setEmployeeTypes] = useState({
		storeManagers: [],
		shopManagers: [],
	});
	const [form, setForm] = useState({
		name: "",
		email: "",
		location: "",
		phone: "",
		manager: "",
	});

	const updateForm = (e) => {
		setForm((prevForm) => {
			return {
				...prevForm,
				[e.target.name]: e.target.value,
			};
		});
	};

	const addStore = async () => {
		console.log(form);
		if (storeMode) {
			const store = await Stores.create(
				storeMode,
				form,
				setErrors,
				setLoading
			);
			if (store) {
				queryClient.invalidateQueries("stores");
				setSuccess(true);
				setOpen(false);
			}
		} else {
			const shop = await Shops.create(form, setErrors, setLoading);
			if (shop) {
				queryClient.invalidateQueries("shops");
				setSuccess(true);
				setOpen(false);
			}
		}
	};

	useEffect(() => {
		if (employees) {
			const storeManagers = [];
			const shopManagers = [];
			employees.forEach((employee) => {
				if (employee.role?.name === "store_manager") {
					storeManagers.push(employee);
				} else if (employee.role?.name === "shop_manager") {
					shopManagers.push(employee);
				}
			});

			setEmployeeTypes({
				shopManagers,
				storeManagers,
			});
		}
	}, [employees]);

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
								<div className="mt-5 sm:mt-6 flex items-center gap-4">
									<div className="w-full">
										<label
											htmlFor="name"
											className="block text-sm font-medium text-gray-700"
										>
											Name{" "}
											<span className="text-red-700">
												*
											</span>
										</label>
										<div className="mt-2 mb-2 ">
											<input
												type="text"
												value={form.name}
												onChange={updateForm}
												name="name"
												id="name"
												placeholder="Name of store/shop"
												className="block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
											/>
										</div>
									</div>
									<div className="w-full">
										<label
											htmlFor="email"
											className="block text-sm font-medium text-gray-700"
										>
											Email{" "}
											<span className="text-red-700">
												*
											</span>
										</label>
										<div className="mt-2 mb-2 ">
											<input
												type="email"
												value={form.email}
												onChange={updateForm}
												name="email"
												id="email"
												placeholder="Email address of store/shop"
												className="block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
											/>
										</div>
									</div>
								</div>
								<div className="mt-5 sm:mt-6 flex items-center gap-4">
									<div className="w-full">
										<label
											htmlFor="location"
											className="block text-sm font-medium text-gray-700"
										>
											Location{" "}
											<span className="text-red-700">
												*
											</span>
										</label>
										<div className="mt-2 mb-2 ">
											<input
												type="text"
												value={form.location}
												onChange={updateForm}
												name="location"
												id="location"
												placeholder="Location of store/shop"
												className="block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
											/>
										</div>
									</div>
									<div className="w-full">
										<label
											htmlFor="phone"
											className="block text-sm font-medium text-gray-700"
										>
											Phone{" "}
											<span className="text-red-700">
												*
											</span>
										</label>
										<div className="mt-2 mb-2 ">
											<input
												type="text"
												name="phone"
												value={form.phone}
												onChange={updateForm}
												id="phone"
												placeholder="Phone number of store"
												className="block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
											/>
										</div>
									</div>
								</div>
								<div className="mt-5 sm:mt-6">
									<p className="block text-sm font-medium text-gray-700 pb-1">
										I am adding a store.
									</p>
									<Toggle
										enabled={storeMode}
										setEnabled={setStoreMode}
									/>
								</div>
								<div className="mt-5 sm:mt-6">
									<label
										htmlFor="manager"
										className="block text-sm font-medium text-gray-700 pb-1"
									>
										{storeMode ? "Store" : "Shop"} Manager
									</label>
									<select
										id="manager"
										name="manager"
										value={form.manager}
										onChange={updateForm}
										className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm bg-white border"
									>
										<option>Select an employee</option>
										{storeMode
											? employeeTypes.storeManagers.map(
													(employee, index) => {
														return (
															<option
																value={
																	employee.user_id?._id
																}
																key={index}
															>
																{employee.name}
															</option>
														);
													}
											  )
											: employeeTypes.shopManagers.map(
													(employee, index) => {
														return (
															<option
																value={
																	employee.user_id?._id
																}
																key={index}
															>
																{employee.name}
															</option>
														);
													}
											  )}
									</select>
								</div>
								<button
									type="button"
									disabled={loading}
									onClick={addStore}
									className="text-sm select flex items-center text-white p-2 px-3 mt-6 rounded-md border border-[#2046cf] bg-[#2046cf] w-28 justify-center"
								>
									<span className="font-medium">Save</span>
									{loading && (
										<div className="pl-1">
											<ArrowPathIcon
												height={20}
												width={20}
												className="animate-spin"
											/>
										</div>
									)}
								</button>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
