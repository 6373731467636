import apiClient from './fetch';

export async function create(form, setErrors, setLoading) {
	setLoading(true);
    let success = false;
	try {
		const expense = await apiClient.post("/expenses/add", form);
        success = true;
		setLoading(false);
		return [success, expense];
	} catch (error) {
		if (error.response.status < 500) {
            if(typeof error.response.data.error === 'string') {
		        setErrors({ type: "credentials", info: error.response.data.error });
            } else {
		        setErrors({ type: "validation", info: error.response.data.error.details });
            }
		}
        success = false;
		setLoading(false);
		return [success, error];
	}
}

export async function get() {
	try {
		const { data: expenses } = await apiClient.get("/expenses");
        return expenses.data;
	} catch (error) {
        throw error;
	}
}
