import axios from "axios";

const BASE_URL =
	process.env.NODE_ENV === "production"
		? "https://api.mspcgambia.net/api"
		: "http://localhost:5000/api";

const client = axios.create({
	baseURL: BASE_URL,
	withCredentials: true,
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
});

export default client;
