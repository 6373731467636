import { useState } from "react";
import { cashFlow } from "../../../utils/reports";
import { formatPrice } from "../../../utils/misc";

export default function CashFlow() {
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({
		startDate: "",
		endDate: "",
	});
	const [cashflowData, setCashflowData] = useState({
		sales: 0,
		purchases: 0,
		netcash: 0,
		startingBalance: 0,
		grossInflow: 0,
		netCashChange: 0,
		endingBalance: 0,
	});

	const fetchReport = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (Object.keys(form).length === 2) {
			try {
				const { data } = await cashFlow({ ...form });
                if(data.length === 0) return
				setCashflowData((prevState) => {
					return {
						...prevState,
						sales: data[0].sales,
						purchases: data[0].purchases,
					};
				});
			} catch (error) {
				console.log("some error occured");
			} finally {
				setLoading(false);
			}
		}
	};

	const updateForm = (event) => {
		setForm((prevState) => {
			return {
				...prevState,
				[event.target.name]: event.target.value,
			};
		});
	};

	return (
		<>
			<form className="flex items-center gap-6">
				<div className="flex items-center gap-5">
					<input
						type={"date"}
						value={form.startDate}
						onChange={updateForm}
						name="startDate"
						className="border border-gray-400 p-2 rounded w-48"
						defaultValue={"2023-03-03"}
					/>
					<input
						type={"date"}
						name="endDate"
						value={form.endDate}
						onChange={updateForm}
						className="border border-gray-400 p-2 rounded w-48"
						defaultValue={"2023-03-03"}
					/>
				</div>
				<button
					className="select flex items-center text-white p-3 px-4 rounded-3xl bg-[#2046cf]"
					onClick={fetchReport}
					disabled={loading}
				>
					<span className="text-sm">Update report</span>
				</button>
			</form>
			<div className="mt-12">
				<div className="flex items-end justify-between border-b border-gray-400 pb-2 px-2">
					<span className="uppercase font-bold text-sm">
						Operating Activities
					</span>
					<span className="uppercase font-bold text-sm w-32">
						{new Date(form.startDate).toDateString()} to{" "}
						{new Date(form.endDate).toDateString()}
					</span>
				</div>
				<div>
					<div className="flex items-center justify-between border-b border-gray-300 p-2">
						<span>Sales</span>
						<span>D{formatPrice(cashflowData.sales)}</span>
					</div>
					<div className="flex items-center justify-between border-b border-gray-300 p-2">
						<span>Purchases</span>
						<span>D{formatPrice(cashflowData.purchases)}</span>
					</div>
					<div className="flex items-center justify-between border-b border-gray-300 py-2 bg-gray-100 px-2">
						<div className="flex flex-col">
							<span className="font-bold">
								Net Cash from Operating Activities
							</span>
						</div>
						<div className="flex flex-col">
							<span className="font-bold">
								D
								{formatPrice(
									cashflowData.sales - cashflowData.purchases
								)}
							</span>
						</div>
					</div>
				</div>
				<div className="flex items-end justify-between border-b border-gray-400 pb-2 px-2 mt-12">
					<span className="uppercase font-bold text-sm">
						Overview
					</span>
					<span className="uppercase font-bold text-sm">
						{new Date(form.startDate).toDateString()} to{" "}
						{new Date(form.endDate).toDateString()}
					</span>
				</div>
				<div>
					<div className="flex hidden items-center justify-between border-b border-gray-300 p-2 bg-gray-100">
						<span className="font-bold">Starting Balance</span>
						<span className="font-bold">D750.00</span>
					</div>
					<div className="flex items-center justify-between border-b border-gray-300 p-2">
						<span>Gross Cash Inflow</span>
						<span>D{formatPrice(cashflowData.sales)}</span>
					</div>
					<div className="flex items-center justify-between border-b border-gray-300 p-2">
						<span>Gross Cash Outflow</span>
						<span>D{formatPrice(cashflowData.purchases)}</span>
					</div>
					<div className="flex items-center justify-between border-b border-gray-300 py-2 px-2">
						<div className="flex flex-col">
							<span className="font-bold">Net Cash Change</span>
						</div>
						<div className="flex flex-col">
							<span className="font-bold">
								D
								{formatPrice(
									cashflowData.sales - cashflowData.purchases
								)}
							</span>
						</div>
					</div>
					<div className="flex items-center hidden justify-between border-b border-gray-300 py-2 bg-gray-100 px-2">
						<div className="flex flex-col">
							<span className="font-bold">Ending Balance</span>
						</div>
						<div className="flex flex-col">
							<span className="font-bold">D750.00</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
