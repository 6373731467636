import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { MagnifyingGlassIcon, FunnelIcon } from "@heroicons/react/24/outline";

import NewExpense from "../components/forms/NewExpense";
import PreviewExpense from "../components/previews/PreviewExpense";

import * as Store from "../utils/store";
import * as Shop from "../utils/shop";
import * as Employee from "../utils/employee";
import { get } from "../utils/expenses";
import { formatPrice, handleNavigate } from "../utils/misc";
import { UIContext } from "../context/UIContext";
import SuccessAlert from "../components/utils/SuccessAlert";

export default function Expenses() {
	const { setSlideOver } = useContext(UIContext);
	const [preview, setPreview] = useState(false);
    const [currentExpense, setCurrentExpense] = useState({});
	const [success, setSuccess] = useState(false);
	const [showFilter, setShowFilter] = useState(false);

	const { data: stores } = useQuery("stores", Store.get);
	const { data: shops } = useQuery("shops", Shop.get);
	const { data: employees } = useQuery("employees", Employee.get);
	const { data: expenses } = useQuery("expenses", get);

	const showAddNewExpense = (e) => {
		handleNavigate(e, "showAddExpensePage", setSlideOver);
	};

    const showPreview = (expense) => {
        setCurrentExpense(expense);
        setPreview(!preview);
    }

	return (
		<>
			<section className="main">
				<SuccessAlert
					title={"Success"}
					message={"Expense has been added successfully."}
					open={success}
					setOpen={setSuccess}
					fallbackPath={"/expenses"}
					fallbackName={"expenses"}
				/>
				<div className="flex items-center justify-between mb-5">
					<div>
						<h1 className="greeting text-3xl">Expenses</h1>
						<p>A list of all expenses.</p>
					</div>
					<div className="flex items-center gap-6">
						<form className="flex items-center">
							<div className="relative mt-1 rounded-md shadow-sm w-72">
								<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
									<MagnifyingGlassIcon
										className="h-5 w-5 text-gray-400"
										aria-hidden="true"
									/>
								</div>
								<input
									type="text"
									name="filter"
									id="filter"
									className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm outline-none"
									placeholder="Search"
								/>
							</div>
							<button
								className="flex items-center justify-center pl-3"
								onClick={(e) => {
									e.preventDefault();
									setShowFilter(!showFilter);
								}}
							>
								<FunnelIcon
									className="h-6 w-6"
									aria-hidden="true"
								/>
							</button>
						</form>
						<button
							className="select flex items-center text-[#2046cf] p-2 px-4 rounded-3xl border border-[#2046cf] hover:bg-[#2046cf] hover:text-white"
							onClick={showAddNewExpense}
						>
							<span className="text-md font-medium">
								Add new expense
							</span>
							<span className="material-symbols-outlined">
								add
							</span>
						</button>
					</div>
				</div>
				<div
					className={`grid-cols-3 mt-3 gap-4 ${
						showFilter ? "grid" : "hidden"
					}`}
				>
					<div>
						<label
							htmlFor="date"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by date
						</label>
						<input
							id="date"
							type={"date"}
							name="date"
							className="mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						/>
					</div>
					<div>
						<label
							htmlFor="store"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by store or shop
						</label>
						<select
							id="store"
							name="store_id"
							className="bg-white border mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						>
							<option value={"all"}>All stores & shops</option>
							{stores?.data.map((store, index) => {
								return (
									<option value={store._id} key={index}>
										{store.name}
									</option>
								);
							})}
							{shops?.data.map((shop, index) => {
								return (
									<option value={shop._id} key={index}>
										{shop.name}
									</option>
								);
							})}
						</select>
					</div>
					<div>
						<label
							htmlFor="customer"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by employee
						</label>
						<select
							id="employee"
							name="employee"
							className="mt-1 block w-full rounded-md border bg-transparent border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						>
							<option>All</option>
							{employees?.map((employee, index) => {
								return (
									<option key={index}>{employee.name}</option>
								);
							})}
						</select>
					</div>
				</div>
				<table className="min-w-full divide-y divide-gray-200 mt-6">
					<thead className="bg-gray-50">
						<tr>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Number
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Expense For
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Amount
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Date
							</th>
							<th scope="col" className="relative px-6 py-3">
								<span className="sr-only">Edit</span>
							</th>
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						{expenses?.map((expense, index) => {
							return (
								<tr key={index}>
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="text-sm text-gray-900">
											{expense.expense_number}
										</div>
									</td>
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="text-sm text-gray-900 capitalize">
											{expense.expense_for}
										</div>
									</td>
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="text-sm text-gray-900 font-bold">
											D{formatPrice(expense.amount)}
										</div>
									</td>

									<td className="px-6 py-4 w-20 whitespace-nowrap">
										<div className="text-sm text-gray-900">
											{new Date(
												expense.createdAt
											).toDateString()}
										</div>
									</td>

									<td
										className="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
									>
										<button
											className="text-indigo-600 hover:text-indigo-900"
											onClick={() => showPreview(expense)}
										>
											View
										</button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</section>
			<PreviewExpense open={preview} setOpen={setPreview} expense={currentExpense}/>
			<NewExpense setSuccess={setSuccess} />
		</>
	);
}
