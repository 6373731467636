import apiClient from './fetch'

export async function get() {
	try {
		const { data: orders } = await apiClient.get("/orders");
        return orders;
	} catch (error) {
        throw error;
	}
}
