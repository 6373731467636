import { useState } from "react";

import { settingsNavigation } from "../lib/constants";
import { classNames } from "../utils/tailwindcss";
import PreviewGeneral from "../components/previews/settings/PreviewGeneral";
import ComingSoon from "../components/utils/ComingSoon";
import PreviewBilling from "../components/previews/settings/PreviewBilling";
import PreviewUsers from "../components/previews/settings/PreviewUsers";
import PreviewResetPassword from "../components/previews/settings/PreviewResetPassword";

export default function Settings() {
	const [show, setShow] = useState(true);
	const [navigation, setNavigation] = useState(settingsNavigation);
	const [currentItem, setCurrentItem] = useState(settingsNavigation[0]);
	const setCurrent = (navitem) => {
		setCurrentItem(navitem);
		const newNavigation = navigation.map((item) => {
			return {
				...item,
				current: item.name === navitem.name,
			};
		});
		setNavigation(newNavigation);
	};
	return show ? (
		<section>
			<div className="mt-6">
				<h1 className="greeting text-3xl">Settings</h1>
				<p className="pt-3">Manage your settings</p>
			</div>
			<div className="flex justify-between mt-6 gap-4">
				<ul
					role="list"
					className="flex flex-1 flex-col gap-y-2 w-1/6 border-r border-gray-200 pr-3"
				>
					{navigation.map((item) => (
						<li key={item.name}>
							<button
								onClick={() => setCurrent(item)}
								className={classNames(
									item.current
										? "bg-gray-200"
										: "hover:bg-gray-50 bg-white",
									"w-full group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700"
								)}
							>
								{item.icon}
								{item.name}
							</button>
						</li>
					))}
				</ul>
				<div className="w-5/6">
					{currentItem.name === "General" && <PreviewGeneral />}
                    {currentItem.name === "Billing & Plan" && <PreviewBilling />}
                    {currentItem.name === "Users" && <PreviewUsers />}
                    {currentItem.name === "Password" && <PreviewResetPassword />}
				</div>
			</div>
		</section>
	) : (
		<ComingSoon />
	);
}
