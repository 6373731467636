export default function StepTwo() {
	return (
		<>
			<div className="flex flex-col mb-6">
				<label
					htmlFor="name"
					className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
				>
					Contact Person's Full Name:
				</label>
				<div className="relative">
					<div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
						<span className="material-symbols-outlined">
							support_agent
						</span>
					</div>

					<input
						id="name"
						type="text"
						name="name"
						className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-300 w-full py-2 focus:outline-none focus:border-[#2046cf]"
						placeholder="Contact Person"
					/>
				</div>
			</div>
			<div className="flex flex-col mb-6">
				<label
					htmlFor="country"
					className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
				>
					Country:
				</label>
				<div className="relative">
					<div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
						<span className="material-symbols-outlined">flag</span>
					</div>

					<input
						id="country"
						type="text"
						name="country"
						className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-300 w-full py-2 focus:outline-none focus:border-[#2046cf]"
						placeholder="Country"
					/>
				</div>
			</div>
			<div className="flex flex-col mb-6">
				<label
					htmlFor="region"
					className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
				>
					Region:
				</label>
				<div className="relative">
					<div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
						<span className="material-symbols-outlined">
							near_me
						</span>
					</div>

					<input
						id="region"
						type="text"
						name="region"
						className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-300 w-full py-2 focus:outline-none focus:border-[#2046cf]"
						placeholder="Region"
					/>
				</div>
			</div>
			<div className="flex flex-col mb-6">
				<label
					htmlFor="address"
					className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
				>
					Address:
				</label>
				<div className="relative">
					<div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
						<span className="material-symbols-outlined">
							location_on
						</span>
					</div>

					<input
						id="address"
						type="text"
						name="address"
						className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-300 w-full py-2 focus:outline-none focus:border-[#2046cf]"
						placeholder="Address"
					/>
				</div>
			</div>
		</>
	);
}
