import apiClient from './fetch';

export async function create(form, setErrors, setLoading) {
	setLoading(true);
    let success = false;
	try {
		const sale = await apiClient.post("/sales/add", {...form, transactionType: 'Sales'});
        success = true;
		setLoading(false);
		return [success, sale];
	} catch (error) {
		if (error.response.status < 500 && error.response.status !== 403) {
            if(typeof error.response.data.error === 'string') {
		        setErrors({ type: "credentials", info: error.response.data.error });
            } else {
		        setErrors({ type: "validation", info: error.response.data.error.details });
            }
		}
        success = false;
		setLoading(false);
		return [success, error];
	}
}

export async function get() {
	try {
		const { data: sales } = await apiClient.get("/sales");
        return sales.data;
	} catch (error) {
        throw error;
	}
}
