import { useState } from "react";
import { MagnifyingGlassIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { useQuery, useQueryClient } from "react-query";

import Tabs from "../components/utils/Tabs";
import AlertDanger from "../components/utils/AlertDanger";
import NewStore from "../components/forms/NewStore";
import SuccessAlert from "../components/utils/SuccessAlert";
import * as Store from "../utils/store";
import * as Shops from "../utils/shop";

export default function Stores() {
	const queryClient = useQueryClient();
	const [showFilter, setShowFilter] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [showAddNewStore, setShowAddNewStore] = useState(false);
	const [success, setSuccess] = useState(false);
	const [active, setActive] = useState("stores");
	const [currentStore, setCurrentStore] = useState({});

	const { data: stores } = useQuery("stores", Store.get);
	const { data: shops } = useQuery("shops", Shops.get);

	const remove = (store) => {
		setCurrentStore(store);
		setShowAlert(true);
	};

	const removeStore = async () => {
		const storeMode = active === "stores";
		if (storeMode) {
			const data = Store.deleteStore(currentStore._id);
			if (data) {
				queryClient.invalidateQueries("stores");
				setShowAlert(false);
			}
		} else {
			const data = Shops.deleteShop(currentStore._id);
			if (data) {
				queryClient.invalidateQueries("shops");
				setShowAlert(false);
			}
		}
	};

	return (
		<>
			<section>
				<SuccessAlert
					title={"Success"}
					message={"Store/Shop has been added successfully."}
					open={success}
					setOpen={setSuccess}
					fallbackPath={"/stores"}
					fallbackName={"stores"}
				/>
				<div className="flex justify-between items-center mt-6">
					<div>
						<h1 className="greeting text-3xl">Stores & Shops</h1>
						<p className="pt-3">A list of all stores and shops.</p>
					</div>
					<div className="flex items-center gap-6">
						<form className="flex items-center">
							<div className="relative mt-1 rounded-md shadow-sm w-72">
								<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
									<MagnifyingGlassIcon
										className="h-5 w-5 text-gray-400"
										aria-hidden="true"
									/>
								</div>
								<input
									type="text"
									name="filter"
									id="filter"
									className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm outline-none"
									placeholder="Search"
								/>
							</div>
							<button
								className="flex items-center justify-center pl-3"
								onClick={(e) => {
									e.preventDefault();
									setShowFilter(!showFilter);
								}}
							>
								<FunnelIcon
									className="h-6 w-6"
									aria-hidden="true"
								/>
							</button>
						</form>
						<button
							className="select flex items-center text-[#2046cf] p-2 px-4 rounded-3xl border border-[#2046cf] hover:bg-[#2046cf] hover:text-white"
							onClick={() => setShowAddNewStore(!showAddNewStore)}
						>
							<span className="text-md font-medium">
								Add store/shop
							</span>
							<span className="material-symbols-outlined">
								add
							</span>
						</button>
					</div>
				</div>
				<div
					className={`grid-cols-3 mt-3 gap-4 ${
						showFilter ? "grid" : "hidden"
					}`}
				>
					<div>
						<label
							htmlFor="branch"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by location
						</label>
						<select
							id="branch"
							name="branch"
							className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						>
							<option>Brikama</option>
							<option>Canada</option>
							<option>Mexico</option>
						</select>
					</div>
					<div>
						<label
							htmlFor="status"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by manager
						</label>
						<select
							id="status"
							name="status"
							className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						>
							<option>All</option>
							<option>Canada</option>
							<option>Mexico</option>
						</select>
					</div>
				</div>
				<Tabs active={active} setActive={setActive} />
			</section>
			<section className="mt-2">
				<table className="min-w-full divide-y divide-gray-200">
					<thead className="bg-gray-50">
						<tr>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Name
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Location
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Manager
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Email
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Phone
							</th>
							<th scope="col" className="relative px-6 py-3">
								<span className="sr-only">Edit</span>
							</th>
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						{active === "stores"
							? stores?.data.map((store, index) => {
									return (
										<tr key={index}>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="flex items-center">
													<div className="text-sm font-medium text-gray-900">
														{store.name}
													</div>
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="flex items-center">
													<div className="text-sm font-medium text-gray-900">
														{store.location}
													</div>
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{store.manager?.name}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{store.email}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{store.phone}
												</div>
											</td>

											<td
												className="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
											>
												<button
													className="text-indigo-600 hover:text-indigo-900 text-red-500"
													onClick={() =>
														remove(store)
													}
												>
													Remove
												</button>
											</td>
										</tr>
									);
							  })
							: shops?.data.map((store, index) => {
									return (
										<tr key={index}>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="flex items-center">
													<div className="text-sm font-medium text-gray-900">
														{store.name}
													</div>
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="flex items-center">
													<div className="text-sm font-medium text-gray-900">
														{store.location}
													</div>
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{store.manager?.name}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{store.email}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{store.phone}
												</div>
											</td>

											<td
												className="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
											>
												<button
													className="text-indigo-600 hover:text-indigo-900 text-red-500"
													onClick={() =>
														remove(store)
													}
												>
													Remove
												</button>
											</td>
										</tr>
									);
							  })}
					</tbody>
				</table>
				<AlertDanger
					action={removeStore}
					open={showAlert}
					setOpen={setShowAlert}
					title={"Remove store"}
					message={
						"Are you sure you want to remove this store? All of your data will be permanently removed from our servers forever. This action cannot be undone."
					}
				/>
				<NewStore
					open={showAddNewStore}
					setOpen={setShowAddNewStore}
					setSuccess={setSuccess}
				/>
			</section>
		</>
	);
}
