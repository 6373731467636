import apiClient from './fetch';

export async function create(form, setErrors, setLoading) {
    setLoading(true);
    let success = false;

    try {
        const { data: transfer } = await apiClient.post('/transfer_stocks/add', form);
        success = true;
        setLoading(false);

        return [success, transfer];
    } catch(error) {
        success = false;
        setLoading(false);

        return [success, error];
    }
}

export async function get() {
	try {
		const { data: transfers } = await apiClient.get("/transfer_stocks");
        return transfers.data;
	} catch (error) {
        throw error;
	}
}
