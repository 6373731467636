import apiClient from "./fetch";

export async function profitLoss(payload) {
	try {
		const { data } = await apiClient.get(
			`/reports/profitloss/${payload.startDate}/${payload.endDate}`
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export async function balanceSheet(startDate) {
	try {
		const { data } = await apiClient.get(
			`/reports/balancesheet/${startDate}`
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export async function cashFlow(payload) {
	try {
		const { data } = await apiClient.get(
			`/reports/cashflow/${payload.startDate}/${payload.endDate}`
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export async function customerBalances(payload) {
	try {
		const { data } = await apiClient.get(
			`/reports/customerbalances/${payload.startDate}/${payload.endDate}`
		);
		return data;
	} catch (error) {
		throw error;
	}
}

export async function vendorBalances(payload) {
	try {
		const { data } = await apiClient.get(
			`/reports/vendorbalances/${payload.startDate}/${payload.endDate}`
		);
		return data;
	} catch (error) {
		throw error;
	}
}
