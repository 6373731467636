import { Fragment, useContext, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { useQuery, useQueryClient } from "react-query";

import Toggle from "../utils/Toggle";
import { UIContext } from "../../context/UIContext";
import { toggleErrorBorder } from "../../utils/errors";
import { add } from "../../utils/products";
import * as Stores from "../../utils/store";
import * as Shops from "../../utils/shop";
import { ProductContext } from "../../context/ProductContext";
import { categories } from "../../lib/constants";

export default function NewProduct({ setSuccess }) {
	const queryClient = useQueryClient();
	const { data: stores } = useQuery("stores", Stores.get);
	const { data: shops } = useQuery("shops", Shops.get);

	const { setProduct } = useContext(ProductContext);
	const { slideOver, setSlideOver } = useContext(UIContext);
	const [storeMode, setStoreMode] = useState(false);
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({
		name: "",
		description: "No description",
		unit: "",
		quantity: "",
		reorder_point: "",
		cost_price: "",
		selling_price: "",
		product_image: "",
		category: "",
		subcategory: "",
	});

	const updateForm = (e) => {
		if (e.target.name == "store") {
			setForm((prevForm) => {
				return {
					...prevForm,
					[storeMode ? "store_id" : "shop_id"]: e.target.value,
				};
			});
		} else {
			setForm((prevForm) => {
				return {
					...prevForm,
					[e.target.name]: e.target.value,
				};
			});
		}
	};

	const handleFileUpload = (event) => {
		setForm((prevForm) => {
			return {
				...prevForm,
				product_image: event.target.files[0],
			};
		});
	};

	const addProduct = async (e) => {
		e.preventDefault();
		const payload = new FormData();
		for (const formItem in form) {
			if (form.hasOwnProperty(formItem)) {
				payload.append(formItem, form[formItem]);
			}
		}

		console.log(payload);

		const [success, _] = await add(payload, setErrors, setLoading);
		if (success) {
			queryClient.invalidateQueries("products");
			setProduct({});
			setForm({
				name: "",
				description: "",
				unit: "",
				quantity: "",
				reorder_point: "",
				cost_price: "",
				selling_price: "",
				product_image: "",
			});
			setSlideOver((prevState) => {
				return {
					...prevState,
					showAddProductPage: false,
				};
			});
			setSuccess(true);
		}
	};

	return (
		<Transition.Root
			show={slideOver.showAddProductPage || false}
			as={Fragment}
		>
			<Dialog as="div" className="relative z-10" onClose={setSlideOver}>
				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-500"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-500"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto w-screen max-w-lg">
									<div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
										<div className="px-4 sm:px-6">
											<div className="flex items-start justify-between">
												<Dialog.Title className="text-xl font-medium text-gray-900">
													New Product
												</Dialog.Title>
												<div className="ml-3 flex h-7 items-center">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
														onClick={() =>
															setSlideOver(
																(prevState) => {
																	return {
																		...prevState,
																		showAddProductPage: false,
																	};
																}
															)
														}
													>
														<span className="sr-only">
															Close panel
														</span>
														<XMarkIcon
															className="h-6 w-6"
															aria-hidden="true"
														/>
													</button>
												</div>
											</div>
										</div>
										<div className="relative mt-6 flex-1 px-4 sm:px-6">
											<p className="text-sm">
												To start, all you need is a
												name, price, and file.
											</p>
											<form className="mt-5">
												<div className="mb-8">
													<label
														htmlFor="name"
														className="block text-sm font-medium text-gray-700"
													>
														Name
														<span className="text-red-700">
															*
														</span>
													</label>
													<div className="mt-2 mb-2">
														<input
															type="text"
															value={form.name}
															onChange={
																updateForm
															}
															name="name"
															id="name"
															className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
																errors,
																"name"
															)}`}
														/>
													</div>
													<p className="text-sm text-gray-500">
														Give your product a
														short and clear name.
													</p>
												</div>
												<div className="mb-8">
													<label
														htmlFor="description"
														className="block text-sm font-medium text-gray-700"
													>
														Description
														<span className="text-red-700">
															*
														</span>
													</label>
													<div className="mt-2 mb-2">
														<input
															type="text"
															name="description"
															value={
																form.description
															}
															onChange={
																updateForm
															}
															id="description"
															className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
																errors,
																"description"
															)}`}
														/>
													</div>
													<p className="text-sm text-gray-500">
														Give your product a
														short and clear
														description.
													</p>
												</div>
												<div className="mb-8">
													<label
														htmlFor="code"
														className="block text-sm font-medium text-gray-700"
													>
														SKU
													</label>
													<div className="mt-2 mb-2">
														<input
															type="text"
															value={form.sku}
															onChange={
																updateForm
															}
															name="code"
															id="code"
															className="block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
														/>
													</div>
													<p className="text-sm text-gray-500">
														Give your product a
														stock tracking unit
													</p>
												</div>
												<div className="mb-8">
													<label
														htmlFor="unit"
														className="block text-sm font-medium text-gray-700"
													>
														Unit{" "}
														<span className="text-red-700">
															*
														</span>
													</label>
													<select
														id="unit"
														name="unit"
														value={form.unit}
														onChange={updateForm}
														className={`border bg-white mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
															errors,
															"unit"
														)}`}
													>
														<option>
															Select unit
														</option>
														<option
															value={"pieces"}
														>
															pcs
														</option>
														<option value={"bags"}>
															bag
														</option>
														<option
															value={"packets"}
														>
															pkt
														</option>
														<option value={"rolls"}>
															rolls
														</option>
														<option
															value={"bundles"}
														>
															bundle
														</option>
														<option
															value={"cartons"}
														>
															cartons
														</option>
														<option value={"boxes"}>
															box
														</option>
														<option
															value={
																"centimetres"
															}
														>
															cm
														</option>
														<option
															value={"dozens"}
														>
															dz
														</option>
														<option value={"feet"}>
															ft
														</option>
														<option value={"grams"}>
															g
														</option>
														<option
															value={"kilograms"}
														>
															kg
														</option>
														<option
															value={"inches"}
														>
															in
														</option>
														<option
															value={"metres"}
														>
															m
														</option>
														<option
															value={"milligrams"}
														>
															mg
														</option>
														<option
															value={"pounds"}
														>
															lb
														</option>
														<option
															value={
																"millilitres"
															}
														>
															ml
														</option>
														<option
															value={"litres"}
														>
															l
														</option>
													</select>
												</div>
												<div className="mb-8">
													<label
														htmlFor="category"
														className="block text-sm font-medium text-gray-700"
													>
														Category{" "}
														<span className="text-red-700">
															*
														</span>
													</label>
													<select
														id="category"
														name="category"
														value={form.category}
														onChange={updateForm}
														className={`border bg-white mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
															errors,
															"category"
														)}`}
													>
														<option>
															Select category
														</option>
														{Object.keys(
															categories
														).map((cat, index) => {
															return (
																<option
																	value={cat}
																	key={index}
																>
																	{cat}
																</option>
															);
														})}
													</select>
												</div>
												<div className="mb-8">
													<label
														htmlFor="subcategory"
														className="block text-sm font-medium text-gray-700"
													>
														Sub Category{" "}
														<span className="text-red-700">
															*
														</span>
													</label>
													<select
														id="subcategory"
														name="subcategory"
														value={form.subcategory}
														onChange={updateForm}
														className={`border bg-white mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
															errors,
															"subcategory"
														)}`}
													>
														<option>
															Select subcategory
														</option>
														{categories[
															form.category
														]?.subcategories.map(
															(cat, index) => {
																return (
																	<option
																		key={
																			index
																		}
																		value={
																			cat
																		}
																	>
																		{cat}
																	</option>
																);
															}
														)}
													</select>
												</div>
												<div className="mb-8">
													<label
														htmlFor="quantity"
														className="block text-sm font-medium text-gray-700"
													>
														Quantity
													</label>
													<div className="mt-2 mb-2">
														<input
															type="number"
															value={
																form.quantity
															}
															onChange={
																updateForm
															}
															name="quantity"
															id="quantity"
															className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
																errors,
																"quantity"
															)}`}
														/>
													</div>
													<p className="text-sm text-gray-500">
														Give the number of stock
														available for sale
													</p>
												</div>
												<div className="mb-8">
													<label
														htmlFor="reorder_point"
														className="block text-sm font-medium text-gray-700"
													>
														Reorder Point
													</label>
													<div className="mt-2 mb-2">
														<input
															type="number"
															name="reorder_point"
															value={
																form.reorder_point
															}
															onChange={
																updateForm
															}
															id="reorder_point"
															className="block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
														/>
													</div>
													<p className="text-sm text-gray-500">
														When to notify you to
														reorder this product
													</p>
												</div>

												<div className="mb-8">
													<label
														htmlFor="cost_price"
														className="block text-sm font-medium text-gray-700"
													>
														Cost Price
													</label>
													<div className="relative mt-2 mb-2 rounded-md shadow-sm">
														<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
															<span className="text-gray-500 sm:text-sm">
																D
															</span>
														</div>
														<input
															type="number"
															name="cost_price"
															value={
																form.cost_price
															}
															onChange={
																updateForm
															}
															id="cost_price"
															className="block p-2 w-full rounded-md border border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
															placeholder="0.00"
															aria-describedby="price-currency"
														/>
														<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
															<span
																className="text-gray-500 sm:text-sm"
																id="price-currency"
															>
																GMD
															</span>
														</div>
													</div>
												</div>
												<div className="mb-8">
													<label
														htmlFor="selling_price"
														className="block text-sm font-medium text-gray-700"
													>
														Selling Price
														<span className="text-red-700">
															*
														</span>
													</label>
													<div
														className={`relative mt-2 mb-2 rounded-md shadow-sm ${toggleErrorBorder(
															errors,
															"selling_price"
														)}`}
													>
														<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
															<span className="text-gray-500 sm:text-sm">
																D
															</span>
														</div>
														<input
															type="number"
															name="selling_price"
															id="selling_price"
															value={
																form.selling_price
															}
															onChange={
																updateForm
															}
															className="block p-2 w-full rounded-md border border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
															placeholder="0.00"
															aria-describedby="price-currency"
														/>
														<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
															<span
																className="text-gray-500 sm:text-sm"
																id="price-currency"
															>
																GMD
															</span>
														</div>
													</div>
												</div>
												{/* Store / Shop  */}
												<div className="mt-5 sm:mt-6">
													<p className="block text-sm font-medium text-gray-700 pb-1">
														I am adding to a store.
													</p>
													<Toggle
														enabled={storeMode}
														setEnabled={
															setStoreMode
														}
													/>
												</div>
												<div className="mt-5 sm:mt-6">
													<label
														htmlFor="store"
														className="block text-sm font-medium text-gray-700 pb-1"
													>
														{storeMode
															? "Store"
															: "Shop"}
													</label>
													<select
														id="store"
														name="store"
														value={form.store}
														onChange={updateForm}
														className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm bg-white border"
													>
														<option>
															Select a store/shop
														</option>
														{storeMode
															? stores?.data.map(
																	(
																		store,
																		index
																	) => {
																		return (
																			<option
																				value={
																					store._id
																				}
																				key={
																					index
																				}
																			>
																				{
																					store.name
																				}
																			</option>
																		);
																	}
															  )
															: shops?.data.map(
																	(
																		shop,
																		index
																	) => {
																		return (
																			<option
																				value={
																					shop._id
																				}
																				key={
																					index
																				}
																			>
																				{
																					shop.name
																				}
																			</option>
																		);
																	}
															  )}
													</select>
												</div>
												<div className="mt-8">
													<label
														htmlFor="cover-photo"
														className="block text-sm font-medium text-gray-700 sm:mt-px pb-2"
													>
														Product photos
													</label>
													<div className="mt-1 sm:col-span-2 sm:mt-0">
														<div className="flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
															<div className="space-y-1 text-center">
																<svg
																	className="mx-auto h-12 w-12 text-gray-400"
																	stroke="currentColor"
																	fill="none"
																	viewBox="0 0 48 48"
																	aria-hidden="true"
																>
																	<path
																		d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
																		strokeWidth={
																			2
																		}
																		strokeLinecap="round"
																		strokeLinejoin="round"
																	/>
																</svg>
																<div className="flex text-sm text-gray-600">
																	<label
																		htmlFor="product_image"
																		className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
																	>
																		Upload
																		files
																		<input
																			id="product_image"
																			onChange={
																				handleFileUpload
																			}
																			name="product_image"
																			multiple
																			type="file"
																			className="sr-only"
																		/>
																	</label>
																	<p className="pl-1">
																		or drag
																		and drop
																	</p>
																</div>
																<p className="text-xs text-gray-500">
																	PNG, JPG,
																	GIF up to
																	10MB
																</p>
															</div>
														</div>
													</div>
												</div>
												<div className="flex gap-4 mt-8">
													<button
														type="button"
														className="inline-flex items-center rounded-md justify-center border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-1/3"
														onClick={() => {
															setSlideOver(
																(prevState) => {
																	return {
																		...prevState,
																		showAddProductPage: false,
																	};
																}
															);
															setProduct({});
														}}
													>
														Cancel
													</button>
													<button
														onClick={addProduct}
														type="button"
														className="inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm bg-[#2046cf] w-2/3"
													>
														Add product
														{loading && (
															<ArrowPathIcon
																height={23}
																width={23}
																className="animate-spin"
															/>
														)}
													</button>
												</div>
											</form>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
