import { useState, useEffect, useContext } from "react";
import { useQuery } from "react-query";
import { XCircleIcon, ArrowPathIcon } from "@heroicons/react/24/outline";

import Comboboxes from "../components/utils/Combobox";
import * as Customers from "../utils/customers";
import { toggleErrorBorder } from "../utils/errors";
import { get } from "../utils/products";
import { create } from "../utils/invoice";
import SuccessAlert from "../components/utils/SuccessAlert";
import { Link } from "react-router-dom";
import ErrorAlert from "../components/utils/ErrorAlert";
import { UIContext } from "../context/UIContext";
import { handleNavigate } from "../utils/misc";
import NewCustomer from "../components/forms/NewCustomer";

export default function NewInvoice() {
	const { data: customers } = useQuery("customers", Customers.get);
	const { data: allProducts } = useQuery("products", get);

	const { setSlideOver } = useContext(UIContext);

	const [customer, setCustomer] = useState({});
	const [product, setProduct] = useState({});
	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [success, setSuccess] = useState(false);
	const [showCustomerError, setShowCustomerError] = useState(false);
	const [form, setForm] = useState({
		invoice_number: "INV-000002",
		shipped_date: "",
		due_date: "",
	});

	const handleQuantityChange = (e, id) => {
		const value = e.target.value;
		const newProducts = products.map((p) => {
			if (p._id === id) {
				p.quantity = value;
				p.available_for_Sale = value;
			}
			return p;
		});

		setProducts(newProducts);
	};

	const removeProduct = (e, id) => {
		e.preventDefault();
		const newProducts = products.filter((p) => p._id !== id);
		if (newProducts.length === 0) setProduct({});
		setProducts(newProducts);
	};

	const updateForm = (e) => {
		setForm((prevForm) => {
			return {
				...prevForm,
				[e.target.name]: e.target.value,
			};
		});
	};

	const checkCustomerError = () => {
		let status = false;
		if (errors.type === "validation") {
			errors.info.forEach((error) => {
				if (
					error.message.includes("customer_id") ||
					error.message.includes("products")
				) {
					status = true;
				}
			});
		}

		return status;
	};

	const generateInvoice = async (e) => {
		e.preventDefault();

		const payload = {
			customer_id: customer._id,
			products,
			shipped_date: form.shipped_date,
			due_date: form.due_date,
		};

		const [successfull, _] = await create(payload, setErrors, setLoading);
		if (successfull) {
			setSuccess(true);
			setForm({
				invoice_number: "INV-000002",
				shipped_date: "",
				due_date: "",
			});
		}
	};

	const showAddCustomer = (e) => {
		handleNavigate(e, "showAddCustomerPage", setSlideOver);
	};

	const handlePriceChange = (e, id) => {
		const value = e.target.value;
		const newProducts = products.map((p) => {
			if (p._id === id) {
				p.selling_price = value;
			}
			return p;
		});

		setProducts(newProducts);
	};

	useEffect(() => {
		if (product._id && !products.includes(product)) {
			setProducts((prevState) => {
				return [...prevState, product];
			});
		}
	}, [product]);

	return (
		<>
			<section className="main">
				<SuccessAlert
					title={"Success"}
					message={"Invoice has been generated successfully."}
					open={success}
					setOpen={setSuccess}
					fallbackPath={"/invoices"}
					fallbackName={"invoices"}
				/>
				<div className="mb-5">
					<h1 className="greeting text-3xl">New Invoice</h1>
				</div>
				<form className="sm:w-8/12">
					<div className="grid grid-cols-2 gap-3">
						<div className="mb-4" style={{ width: "70%" }}>
							<label
								htmlFor="invoice_number"
								className="block text-sm font-medium text-gray-700"
							>
								Invoice Number
								<span className="text-red-700">*</span>
							</label>
							<div className="mt-2 mb-2 ">
								<input
									type="text"
									name="invoice_number"
									disabled
									onChange={updateForm}
									value={form.invoice_number}
									id="invoice_number"
									className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
										errors,
										"invoice_number"
									)}`}
								/>
							</div>
						</div>
						<div className="mb-4" style={{ width: "70%" }}>
							<label
								htmlFor="shipped_date"
								className="block text-sm font-medium text-gray-700"
							>
								Shipped Date{" "}
								<span className="text-red-700">*</span>
							</label>
							<div className="mt-2 mb-2 ">
								<input
									value={form.shipped_date}
									onChange={updateForm}
									type="date"
									name="shipped_date"
									id="shipped_date"
									className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
										errors,
										"shipped_date"
									)}`}
								/>
							</div>
						</div>
						<div className="" style={{ width: "70%" }}>
							<label
								htmlFor="due_date"
								className="block text-sm font-medium text-gray-700"
							>
								Due Date
								<span className="text-red-700">*</span>
							</label>
							<div className="mt-1 mb-2 ">
								<input
									value={form.due_date}
									onChange={updateForm}
									type="date"
									name="due_date"
									id="due_date"
									className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
										errors,
										"due_date"
									)}`}
								/>
							</div>
						</div>
						<div style={{ width: "70%" }}>
							<Comboboxes
								title={"Select Customer *"}
								list={customers}
								selectedItem={customer}
								setSelectedItem={setCustomer}
								loadView={showAddCustomer}
							/>
						</div>
					</div>
					<div className="mt-8">
						<Comboboxes
							list={allProducts}
							title={"Enter product name *"}
							selectedItem={product}
							setSelectedItem={setProduct}
						/>
						<p className="text-sm text-gray-500 mt-2">
							Type the product name you want to include in the
							invoice and select.
						</p>
						{products.length > 0 ? (
							<div className="mt-2">
								<table className="min-w-full divide-y divide-gray-200">
									<thead className="bg-gray-50">
										<tr>
											<th
												scope="col"
												className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
											>
												Name
											</th>
											<th
												scope="col"
												className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
											>
												Quantity
											</th>
											<th
												scope="col"
												className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
											>
												Selling Price
											</th>

											<th
												scope="col"
												className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
											>
												Cost Price
											</th>
										</tr>
									</thead>
									<tbody className="bg-white divide-y divide-gray-200">
										{products.map((p) => {
											return (
												<tr key={p._id}>
													<td className="px-6 py-4 whitespace-nowrap">
														<div className="text-sm text-gray-900">
															{p.name}
														</div>
													</td>
													<td className="px-6 py-4 whitespace-nowrap">
														<div className="text-sm text-gray-900">
															<input
																value={
																	p.available_for_Sale
																}
																className="border border-gray-300 p-1 rounded-md w-16"
																placeholder={
																	p.available_for_Sale
																}
																name={p._id}
																onChange={(e) =>
																	handleQuantityChange(
																		e,
																		p._id
																	)
																}
															/>
														</div>
													</td>
													<td className="px-6 py-4 whitespace-nowrap">
														<div className="text-sm font-bold text-gray-900">
															GMD{" "}
															<input
																value={
																	p.selling_price
																}
																className="border border-gray-300 p-1 rounded-md w-16"
																placeholder={
																	p.selling_price
																}
																name={p._id}
																onChange={(e) =>
																	handlePriceChange(
																		e,
																		p._id
																	)
																}
															/>
														</div>
													</td>
													<td className="px-6 py-4 whitespace-nowrap">
														<div className="text-sm font-bold text-gray-900">
															GMD{" "}
															{product.cost_price ||
																"N/A"}
														</div>
													</td>
													<td className="py-4 whitespace-nowrap">
														<button
															onClick={(e) =>
																removeProduct(
																	e,
																	p._id
																)
															}
														>
															<XCircleIcon
																width={20}
																height={20}
																className="text-red-500"
															/>
														</button>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						) : (
							<>
								<div className="text-center mt-12">
									<svg
										className="mx-auto h-12 w-12 text-gray-400"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path
											vectorEffect={"non-scaling-stroke"}
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth={2}
											d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
										/>
									</svg>
									<h3 className="mt-2 text-sm font-medium text-gray-900">
										No products
									</h3>
									<p className="mt-1 text-sm text-gray-500">
										Click on the select menu to add a
										product
									</p>
								</div>
							</>
						)}
						<div className="flex gap-4 mt-8">
							<button
								type="button"
								className="w-32 inline-flex items-center rounded-md justify-center border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium shadow-sm hover:text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-1/3"
							>
								<Link to={"/invoices"}>Cancel</Link>
							</button>
							<button
								onClick={generateInvoice}
								type="button"
								className="inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm bg-[#2046cf] w-2/3"
							>
								Create invoice
								{loading && (
									<ArrowPathIcon
										height={23}
										width={23}
										className="animate-spin"
									/>
								)}
							</button>
						</div>
					</div>
				</form>
			</section>
			<ErrorAlert
				message={"Customer & product(s) are required."}
				title={"Notice!"}
				setOpen={setErrors}
				open={checkCustomerError()}
				fallbackName={"adding an invoice"}
				fallbackPath={"#"}
			/>

			<NewCustomer />
		</>
	);
}
