import { Fragment, useContext, useState, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Dialog, Transition } from "@headlessui/react";
import {
	XMarkIcon,
	XCircleIcon,
	ArrowPathIcon,
} from "@heroicons/react/24/outline";

import Comboboxes from "../utils/Combobox";
import Toggle from "../utils/Toggle";
import * as Invoice from "../../utils/invoice";
import * as Customer from "../../utils/customers";
import * as Payment from "../../utils/payments";

import { UIContext } from "../../context/UIContext";
import { PaymentContext } from "../../context/PaymentContext";
import { toggleErrorBorder } from "../../utils/errors";

export default function NewCustomerPayment({setSuccess}) {
	const queryClient = useQueryClient();
	const { data: invoices } = useQuery("invoices", Invoice.get);
	const { data: customers } = useQuery("customers", Customer.get);
	const { slideOver, setSlideOver } = useContext(UIContext);
	const { payment, setPayment } = useContext(PaymentContext);
	const [creditPayment, setCreditPayment] = useState(false);
	const [customer, setCustomer] = useState({});
	const [product, setProduct] = useState({});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({
		amount: "",
		payment_date: "",
		payment_mode: "Cash",
	});

	const updateForm = (e) => {
		setForm((prevForm) => {
			return {
				...prevForm,
				[e.target.name]: e.target.value,
			};
		});
	};

	const addPayment = async (e) => {
		e.preventDefault();

		const payload = {
			customer_id: customer._id,
			amount: form.amount,
			payment_mode: form.payment_mode,
			payment_date: form.payment_date,
			payment_for: "Credit",
		};

		if (!creditPayment) {
			payload.invoices = product;
			payload.payment_for = "Invoice";
		}

		const [success, _] = await Payment.create(
			payload,
			setErrors,
			setLoading
		);
		if (success) {
			queryClient.invalidateQueries("payments");
			setSlideOver((prevState) => {
				return {
					...prevState,
					showAddCustomerPaymentPage: false,
				};
			});
            setSuccess(true)
		} else {
			console.log(errors);
		}
	};

	useEffect(() => {
		if (payment) {
			setCustomer(payment.customer || payment.customer_id);
			if (invoices) {
				const currentInvoice = invoices?.find(
					(invoice) =>
						invoice.invoice_number === payment.invoice_number
				);
				setProduct(currentInvoice);
			}
		}
	}, [payment]);
	return (
		<Transition.Root
			show={slideOver.showAddCustomerPaymentPage || false}
			as={Fragment}
		>
			<Dialog as="div" className="relative z-10" onClose={setSlideOver}>
				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-500"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-500"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto w-screen max-w-lg">
									<div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
										<div className="px-4 sm:px-6">
											<div className="flex items-start justify-between">
												<Dialog.Title className="text-xl font-medium text-gray-900">
													New Payment
												</Dialog.Title>
												<div className="ml-3 flex h-7 items-center">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
														onClick={() =>
															setSlideOver(
																(prevState) => {
																	return {
																		...prevState,
																		showAddCustomerPaymentPage: false,
																	};
																}
															)
														}
													>
														<span className="sr-only">
															Close panel
														</span>
														<XMarkIcon
															className="h-6 w-6"
															aria-hidden="true"
														/>
													</button>
												</div>
											</div>
										</div>
										<div className="relative mt-6 flex-1 px-4 sm:px-6">
											<form className="mt-5">
												<div className="grid grid-cols-2 gap-3 w-full">
													<div>
														<Comboboxes
															list={customers}
															title={
																"Select Customer *"
															}
															selectedItem={
																customer
															}
															setSelectedItem={
																setCustomer
															}
														/>
													</div>
													<div className="mb-4">
														<label
															htmlFor="amount"
															className="block text-sm font-medium text-gray-700"
														>
															Amount Received{" "}
															<span className="text-red-700">
																*
															</span>
														</label>
														<div className="mt-1 mb-2 ">
															<input
																type="number"
																name="amount"
																value={
																	form.amount
																}
																onChange={
																	updateForm
																}
																id="invoice_number"
																className="block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
															/>
														</div>
													</div>

													<div>
														<label
															htmlFor="payment_date"
															className="block text-sm font-medium text-gray-700"
														>
															Payment Date
															<span className="text-red-700">
																*
															</span>
														</label>
														<div className="mt-1 mb-2 ">
															<input
																type="date"
																value={
																	form.payment_date
																}
																onChange={
																	updateForm
																}
																name="payment_date"
																id="payment_date"
																className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
																	errors,
																	"payment_date"
																)}`}
															/>
														</div>
													</div>
													<div>
														<label
															htmlFor="payment_mode"
															className="block text-sm font-medium text-gray-700"
														>
															Payment Mode
															<span className="text-red-700">
																*
															</span>
														</label>
														<select
															id="payment_mode"
															name="payment_mode"
															className="border bg-white mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
															defaultValue={
																form.payment_mode
															}
														>
															<option
																value={"Cash"}
															>
																Cash
															</option>
															<option
																value={
																	"Bank Transfer"
																}
															>
																Bank transfer
															</option>
															<option
																value={"Cheque"}
															>
																Cheque
															</option>
														</select>
													</div>
												</div>
												<div className="mt-5 flex items-center">
													<span className="block text-sm font-medium text-gray-700 pr-4">
														Payment for an existing
														credit.
													</span>
													<Toggle
														enabled={creditPayment}
														setEnabled={
															setCreditPayment
														}
													/>
												</div>
												<div className="mt-6">
													{!creditPayment && (
														<>
															<Comboboxes
																list={invoices}
																title={
																	"Enter invoice number *"
																}
																selectedItem={
																	product
																}
																setSelectedItem={
																	setProduct
																}
															/>
															<p className="text-sm text-gray-500 mt-2">
																Type the invoice
																number you want
																to make a
																payment for.
															</p>
															{product?._id ? (
																<div className="mt-2">
																	<table className="min-w-full divide-y divide-gray-200">
																		<thead className="bg-gray-50">
																			<tr>
																				<th
																					scope="col"
																					className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
																				>
																					ID
																				</th>
																				<th
																					scope="col"
																					className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
																				>
																					Invoice
																					Number
																				</th>
																			</tr>
																		</thead>
																		<tbody className="bg-white divide-y divide-gray-200">
																			<tr>
																				<td className="px-6 py-4 whitespace-nowrap">
																					<div className="text-sm text-gray-900">
																						1
																					</div>
																				</td>
																				<td className="px-6 py-4 whitespace-nowrap">
																					<div className="text-sm font-bold text-gray-900">
																						{
																							product.invoice_number
																						}
																					</div>
																				</td>
																				<td className="py-4 whitespace-nowrap">
																					<button>
																						<XCircleIcon
																							width={
																								20
																							}
																							height={
																								20
																							}
																							className="text-red-500"
																						/>
																					</button>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</div>
															) : (
																<>
																	<div className="text-center mt-12">
																		<svg
																			className="mx-auto h-12 w-12 text-gray-400"
																			fill="none"
																			viewBox="0 0 24 24"
																			stroke="currentColor"
																			aria-hidden="true"
																		>
																			<path
																				vectorEffect={
																					"non-scaling-stroke"
																				}
																				strokeLinecap={
																					"round"
																				}
																				strokeLinejoin={
																					"round"
																				}
																				strokeWidth={
																					2
																				}
																				d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
																			/>
																		</svg>
																		<h3 className="mt-2 text-sm font-medium text-gray-900">
																			No
																			invoices
																		</h3>
																		<p className="mt-1 text-sm text-gray-500">
																			Click
																			on
																			the
																			select
																			menu
																			to
																			add
																			an
																			invoice
																		</p>
																	</div>
																</>
															)}
														</>
													)}
													<div className="flex gap-4 mt-8">
														<button
															type="button"
															className="w-32 inline-flex items-center rounded-md justify-center border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-1/3"
														>
															Cancel
														</button>
														<button
															onClick={addPayment}
															type="button"
															className="inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm bg-[#2046cf] w-2/3"
														>
															Add payment
															{loading && (
																<ArrowPathIcon
																	height={23}
																	width={23}
																	className="animate-spin"
																/>
															)}
														</button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
