import apiClient from "./fetch";

export async function login(form, setErrors, setLoading) {
    let success = false;
	setLoading(true);
	try {
		await apiClient.post("/users/login", form);
        success = true;
	} catch (error) {
        if(error.response.status < 500) {
            if(typeof error.response.data.error === 'string') {
		        setErrors({ type: "credentials", info: error.response.data.error });
            } else {
		        setErrors({ type: "validation", info: error.response.data.error.details });
            }
        }
        success = false;
	}
	setLoading(false);

	return success;
}

export async function getUser() {
    try {
        const { data } = await apiClient.get('/users/user');
        return data;
    } catch (error) {
        return false;
    }
}

export async function logout() {
    try {
        const { data } = await apiClient.post('/users/logout');
        return data;
    } catch(error) {
        return false;
    }
}

export async function changePassword(payload, setLoading, setErrors) {
    let success = false;
	setLoading(true);
	try {
		await apiClient.post("/users/user/change_password", payload);
        success = true;
	} catch (error) {
        if(error.response.status < 500) {
            if(typeof error.response.data.error === 'string') {
		        setErrors({ type: "credentials", info: error.response.data.error });
            } else {
		        setErrors({ type: "validation", info: error.response.data.error.details });
            }
        }
        success = false;
	}
	setLoading(false);

	return success;
}

export async function resetPassword(id, setLoading) {
    let success = false;
    let status = 200;
	setLoading(true);
	try {
		await apiClient.post(`/users/user/reset_password/${id}`);
        success = true;
	} catch (error) {
        status = error.response.status
        success = false;
	}
	setLoading(false);

	return [success, status];
}
