import { Fragment, useState } from "react";
import { useQueryClient } from "react-query";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

import { create } from "../../utils/employee";
import { toggleErrorBorder } from "../../utils/errors";

export default function NewEmployee({ open, setOpen }) {
    const queryClient = useQueryClient();
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [form, setForm] = useState({
		name: "",
		email: "",
		address: "",
		phone: "",
		role: "",
	});

	const updateForm = (e) => {
		setForm((prevForm) => {
			return {
				...prevForm,
				[e.target.name]: e.target.value,
			};
		});
	};

	const addEmployee = async () => {
		const payload = {
			...form,
		};
        console.log(payload)
		const [success, employee] = await create(
			payload,
			setErrors,
			setLoading
		);
		console.log(errors);
		if (success) {
            queryClient.invalidateQueries('employees')
			setOpen(false);
		}
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
								<div className="mt-5 sm:mt-6 flex items-center gap-4">
									<div className="w-full">
										<label
											htmlFor="name"
											className="block text-sm font-medium text-gray-700"
										>
											Name{" "}
											<span className="text-red-700">
												*
											</span>
										</label>
										<div className="mt-2 mb-2 ">
											<input
												value={form.name}
												onChange={updateForm}
												type="text"
												name="name"
												id="name"
												placeholder="Name of employee"
												className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
													errors,
													"name"
												)}`}
											/>
										</div>
									</div>
									<div className="w-full">
										<label
											htmlFor="email"
											className="block text-sm font-medium text-gray-700"
										>
											Email{" "}
											<span className="text-red-700">
												*
											</span>
										</label>
										<div className="mt-2 mb-2 ">
											<input
												type="email"
												value={form.email}
												onChange={updateForm}
												name="email"
												id="email"
												placeholder="Email address of employee"
												className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
													errors,
													"email"
												)}`}
											/>
										</div>
									</div>
								</div>
								<div className="mt-5 sm:mt-6 flex items-center gap-4">
									<div className="w-full">
										<label
											htmlFor="address"
											className="block text-sm font-medium text-gray-700"
										>
											Address{" "}
											<span className="text-red-700">
												*
											</span>
										</label>
										<div className="mt-2 mb-2 ">
											<input
												value={form.address}
												type="text"
												onChange={updateForm}
												name="address"
												id="address"
												placeholder="Address of employee"
												className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
													errors,
													"address"
												)}`}
											/>
										</div>
									</div>
									<div className="w-full">
										<label
											htmlFor="phone"
											className="block text-sm font-medium text-gray-700"
										>
											Phone{" "}
											<span className="text-red-700">
												*
											</span>
										</label>
										<div className="mt-2 mb-2 ">
											<input
												value={form.phone}
												onChange={updateForm}
												type="text"
												name="phone"
												id="phone"
												placeholder="Phone number of employee"
												className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
													errors,
													"phone"
												)}`}
											/>
										</div>
									</div>
								</div>
								<div className="mt-5 sm:mt-6">
									<label
										htmlFor="branch"
										className="block text-sm font-medium text-gray-700 pb-1"
									>
										User Role
									</label>
									<select
										id="role"
										value={form.role}
										onChange={updateForm}
										name="role"
										className={`mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm bg-white border ${toggleErrorBorder(
											errors,
											"role"
										)}`}
									>
										<option>Select a role</option>
										<option value={"store_manager"}>
											Store Manager
										</option>
										<option value={"shop_manager"}>
											Shop Manager
										</option>
										<option value={"None"}>General employee</option>
									</select>
								</div>
								<button
									type="button"
									className="text-sm select flex items-center text-white p-2 px-3 mt-6 rounded-md border border-[#2046cf] bg-[#2046cf] w-28 justify-center"
									onClick={addEmployee}
								>
									<span className="font-medium">Save</span>
									{loading && (
										<div className="pl-1">
											<ArrowPathIcon
												height={20}
												width={20}
												className="animate-spin"
											/>
										</div>
									)}
								</button>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
