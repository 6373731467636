export default function PreviewUsers() {
	return (
		<>
			<h3 className="text-lg underline">User Management</h3>
			<div className="mt-5">
				<div className="mb-4 shadow border border-gray-200 p-3 rounded-lg">
					<span class="material-symbols-outlined text-3xl">
						admin_panel_settings
					</span>
					<h4 className="my-2 font-semibold">Business Admin</h4>
					<p>
						Best for a business partner, family member, or trusted
						accountant.
					</p>
					<h5>Full access to the entire platform.</h5>
				</div>
				<div className="mb-4 shadow border border-gray-200 p-3 rounded-lg">
					<span class="material-symbols-outlined text-3xl">person</span>
					<h4 className="my-2 font-semibold">Shop Manager</h4>
					<p>
						Best for a business partner, family member, or trusted
						accountant.
					</p>
					<h5>
						Has access to every operation on the shop they are
						assigned to. Cannot alter shop information.
					</h5>
				</div>
				<div className="mb-4 shadow border border-gray-200 p-3 rounded-lg">
					<span class="material-symbols-outlined text-3xl">person</span>
					<h4 className="my-2 font-semibold">Store Manager</h4>
					<p>
						Best for a business partner, family member, or trusted
						accountant.
					</p>
					<h5>
						<h5>
							Has access to every operation on the store they are
							assigned to. Cannot alter store information.
						</h5>
					</h5>
				</div>
			</div>
		</>
	);
}
