import { Fragment, useContext, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon, ArrowPathIcon } from "@heroicons/react/24/outline";

import { UIContext } from "../../context/UIContext";
import { toggleErrorBorder } from "../../utils/errors";
import Comboboxes from "../utils/Combobox";

export default function NewTransfer() {
	const { slideOver, setSlideOver } = useContext(UIContext);
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [toAccount, setToAccount] = useState({});
	const [fromAccount, setFromAccount] = useState({});
	const [form, setForm] = useState({
		name: "",
		description: "",
        amount: 0,
        date: ''
	});

    const cancel = () => {
        setSlideOver(prevState => {
            return {
                ...prevState, 
                showAddTransferPage: false
            }
        })
    }

	const updateForm = (e) => {
		setForm((prevForm) => {
			return {
				...prevForm,
				[e.target.name]: e.target.value,
			};
		});
	};

	return (
		<Transition.Root
			show={slideOver.showAddTransferPage || false}
			as={Fragment}
		>
			<Dialog as="div" className="relative z-10" onClose={setSlideOver}>
				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-500"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-500"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto w-screen max-w-lg">
									<div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
										<div className="px-4 sm:px-6">
											<div className="flex items-start justify-between">
												<Dialog.Title className="text-xl font-medium text-gray-900">
													New Account Transfer
												</Dialog.Title>
												<div className="ml-3 flex h-7 items-center">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
														onClick={() =>
															setSlideOver(
																(prevState) => {
																	return {
																		...prevState,
																		showAddTransferPage: false,
																	};
																}
															)
														}
													>
														<span className="sr-only">
															Close panel
														</span>
														<XMarkIcon
															className="h-6 w-6"
															aria-hidden="true"
														/>
													</button>
												</div>
											</div>
										</div>
										<div className="relative mt-6 flex-1 px-4 sm:px-6">
											<form>
												<div className="grid grid-cols-2 gap-3">
													<div className="mb-4">
														<Comboboxes
															title={
																"From Account"
															}
															list={[]}
															selectedItem={
																fromAccount
															}
															setSelectedItem={
																setFromAccount
															}
														/>
													</div>
													<div className="mb-4">
														<Comboboxes
															title={"To Account"}
															list={[]}
															selectedItem={
																toAccount
															}
															setSelectedItem={
																setToAccount
															}
														/>
													</div>
												</div>
												<div className="grid grid-cols-2 gap-3">
													<div className="mb-4">
														<label
															htmlFor="date"
															className="block text-sm font-medium text-gray-700"
														>
															Date
															<span className="text-red-700">
																*
															</span>
														</label>
														<div className="mt-2 mb-2 ">
															<input
																type="date"
                                                                value={form.date}
																name="date"
																id="date"
																className="block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
															/>
														</div>
													</div>
													<div className="mb-4">
														<label
															htmlFor="amount"
															className="block text-sm font-medium text-gray-700"
														>
															Amount{" "}
															<span className="text-red-700">
																*
															</span>
														</label>
														<div className="mt-2 mb-2 ">
															<input
																type="number"
																value={
																	form.amount
																}
																onChange={
																	updateForm
																}
																name="amount"
																id="amount"
																className="block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
															/>
														</div>
													</div>
												</div>
												<div className="mb-8">
													<label
														htmlFor="description"
														className="block text-sm font-medium text-gray-700"
													>
														Description
														<span className="text-red-700">
															*
														</span>
													</label>
													<div className="mt-2 mb-2">
														<textarea
															type="text"
															rows={5}
															name="description"
															value={
																form.description
															}
															onChange={
																updateForm
															}
															id="description"
															className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
																errors,
																"description"
															)}`}
														/>
													</div>
													<p className="text-sm text-gray-500">
														Give your transfer a
														short and clear
														description.
													</p>
												</div>
												<div className="flex gap-4 mt-8">
													<button
														type="button"
														className="inline-flex items-center rounded-md justify-center border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-1/3"
                                                        onClick={cancel}
													>
														Cancel
													</button>
													<button
														type="button"
														className="inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm bg-[#2046cf] w-2/3"
													>
														Add transfer
														{loading && (
															<ArrowPathIcon
																height={23}
																width={23}
																className="animate-spin"
															/>
														)}
													</button>
												</div>
											</form>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
