export default function PreviewBilling() {
	return (
		<>
			<h3 className="text-lg underline">Billing</h3>
            <h2 className="text-3xl mt-3">D0.00</h2>
			<div className="flex justify-between mt-6">
				<div>
					<h3 className="text-sm">Payment due</h3>
					<h1 className="py-2 text-md font-bold">16th June, 2023</h1>
					<span className="text-sm">invoices that have been paid</span>
				</div>
				<div>
					<h3 className="text-sm">Prepayments</h3>
					<h1 className="py-2 text-md font-bold">D0.00</h1>
					<span className="text-sm">invoices that haven't been paid</span>
				</div>

				<div>
					<h3 className="text-sm">Total usage</h3>
					<h1 className="py-2 text-md font-bold">D0.00</h1>
					<span className="text-sm">invoices that are pending</span>
				</div>
			</div>
		</>
	);
}
