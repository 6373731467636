import General from "../components/reports//general/General";

export default function Reports() {
	return (
		<section>
			<div className="mt-6">
				<h1 className="greeting text-3xl">Reports</h1>
			</div>
            <General />
		</section>
	);
}
