import { formatPrice, moneyize } from "../../utils/misc";

export default function ReceiptTemplate({ sale }) {
    const totalSales = () => {
        let total = 0;
        sale.products.forEach(product => {
            total += product.selling_price * product.quantity;
        });

        return total;
    }
	return (
		<div className="w-full">
			<div className="py-6 mb-6 border-b border-gray-300">
				<h3 className="text-lg font-medium">{sale.business.business_name}</h3>
				<span className="block text-sm">{sale.business.business_address}</span>
				<span className="block text-sm">{sale.business.country}</span>
			</div>
			<div>
				<h1 className="text-2xl pb-1">Sales Receipt</h1>
				<p className="text-sm">
					Sales Receipt{" "}
					<span className="font-bold"># SLR-000001 {sale.isCredit && '(as Credit)'}</span>
				</p>
			</div>
			<div className="flex justify-between mt-3">
				<div>
					<p className="font-bold text-sm">Bill To</p>
					<p className="font-bold text-sm">{sale.customer_id.name}</p>
				</div>
				<div className="flex items-center gap-6">
					<span className="text-sm">Receipt Date</span>
					<span className="font-bold text-sm">
						{new Date(sale.createdAt).toDateString()}
					</span>
				</div>
			</div>
			<table className="min-w-full divide-y divide-gray-200 mt-4">
				<thead className="bg-gray-50">
					<tr>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							#
						</th>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Name
						</th>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Quantity
						</th>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Price
						</th>
					</tr>
				</thead>
				<tbody className="bg-white divide-y divide-gray-200">
					{sale.products.map((product, index) => {
						return (
							<tr key={index}>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										{index + 1}
									</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">{product.name}</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										{product.quantity}
									</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm font-bold text-gray-900">
										D{formatPrice(moneyize(product.selling_price))}
									</div>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="flex flex-col items-end mt-2">
				<div className="flex justify-between gap-6 border-t border-b border-gray-300 p-2 mb-2">
					<h2>Total</h2>
					<p>D{formatPrice(moneyize(totalSales()))}</p>
				</div>
			</div>
		</div>
	);
}
