import { makeIcon } from "../utils/misc";

export const timeframes = [
	{
		name: "All time",
	},
    {
        name: 'Today'
    },
	{
		name: "Last 24 hours",
	},
	{
		name: "Last 7 days",
	},
	{
		name: "Last 30 days",
	},
];

export const morePages = [
	{
		id: 0,
		name: "sales",
	},
	{
		id: 1,
		name: "purchases",
	},
	{
		id: 2,
		name: "people",
	},
	{
		id: 3,
		name: "accounting",
	},
];

export const settingsNavigation = [
	{
		name: "General",
		icon: makeIcon("settings_suggest"),
		current: true,
	},
	{
		name: "Password",
		icon: makeIcon("manage_accounts"),
		current: false,
	},
	{
		name: "Billing & Plan",
		icon: makeIcon("credit_card"),
		current: false,
	},
];

export const navigation = [
	{ id: 'dashboard', name: "Dashboard", href: "/", icon: makeIcon("home"), current: true },
	{
        id: 'products',
		name: "Products",
		icon: makeIcon("summarize"),
		current: false,
		href: "/products",
	},
	{
        id: 'sales/receipt,invoices,customer/payments,sales/orders',
		name: "Sales",
		icon: makeIcon("point_of_sale"),
		current: false,
		children: [
			{ name: "Sales Receipt", href: "/sales/receipt" },
			{ name: "Invoices", href: "/invoices" },
			{ name: "Orders", href: "/sales/orders" },
			{ name: "Payments Received", href: "/customer/payments" },
		],
	},
	{
        id:'stocks',
		name: "Stock",
		href: "/stocks",
		icon: makeIcon("inventory"),
		current: false,
	},
	{
        id: 'stores',
		name: "Stores & Shops",
		href: "/stores",
		icon: makeIcon("store"),
		current: false,
	},
	{
        id: 'bills,expenses',
		name: "Purchases",
		icon: makeIcon("shopping_basket"),
		current: false,
		children: [
			{ name: "Bills", href: "/bills" },
			{ name: "Expenses", href: "/expenses" },
		],
	},
	{
        id: 'customers,vendors,employees',
		name: "People",
		icon: makeIcon("groups"),
		current: false,
		children: [
			{ name: "Customers", href: "/customers" },
			{ name: "Vendors", href: "/vendors" },
			{ name: "Employees", href: "/employees" },
		],
	},
	{
        id: 'transactions',
		name: "Transactions",
		icon: makeIcon("account_balance"),
        href: "/transactions",
		current: false,
	},
	{
        id:'reports',
		name: "Reports",
		href: "/reports",
		icon: makeIcon("pie_chart"),
		current: false,
	},
	{
        id: 'settings',
		name: "Settings",
		href: "/settings",
		icon: makeIcon("settings"),
		current: false,
	},
];

export const categories = {
  'Home Appliances': {
    subcategories: [
      'Light Bulbs',
      'Sockets',
      'Extensions',
      'Ceiling Lights',
      'Others'
    ]
  },
  'Solar Equipment': {
    subcategories: [
      'Solar Panels',
      'Solar Batteries',
      'Inverters',
      'Charging Control',
      'Solar Fans',
      'Solar Bulbs',
      'Others'
    ]
  },
  Electronics: {
    subcategories: [
      'TVs',
      'Routers',
      'Access Points',
      'WiFi Extenders',
      'POE Switches',
      'Remotes',
      'Others'
    ]
  },
  'Electrical Equipment': {
    subcategories: [
      'Cables',
      'PVC Pipes',
      'Circuit Breakers',
      'Distribution Boxes',
      'Electric Fans',
      'Lights',
      'Air Conditioners',
      'Charge Over Switch',
      'Others'
    ]
  },
  CCTV: {
    subcategories: [
      'DVR',
      'NVR',
      'IP Cameras',
      'AHD Cameras',
      'Digital Cameras',
      'Others'
    ]
  },
  'Satellite Equipment': {
    subcategories: [
      'Receivers',
      'LNBs',
      'TV Cables',
      'Receptors',
      'Amplifiers',
      'Others'
    ]
  }
};

