import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { MagnifyingGlassIcon, FunnelIcon } from "@heroicons/react/24/outline";

import * as Payment from "../utils/payments";
import * as Customer from "../utils/customers";
import NewCustomerPayment from "../components/forms/NewCustomerPayment";
import { formatPrice, moneyize } from "../utils/misc";
import PaymentContextProvider from "../context/PaymentContext";
import SuccessAlert from "../components/utils/SuccessAlert";

export default function CustomerPayment() {
	const { data: payments } = useQuery("payments", Payment.get);
	const { data: customers } = useQuery("customers", Customer.get);
	const [showFilter, setShowFilter] = useState(false);
	const [success, setSuccess] = useState(false);
	const [filteredPayments, setFilteredPayments] = useState([]);

	const filterPayments = (event, type) => {
		const value = event.target.value;
		if (type === "customer") {
			if (value === "All") {
				setFilteredPayments([...payments]);
			} else {
				const newPayments = payments.filter((payment) =>
					payment.customer_id?.name
						.toLowerCase()
						.includes(value.toLowerCase())
				);
				setFilteredPayments(newPayments);
			}
		} else if (type === "type") {
			const value = event.target.value;
			const newPayments = payments.filter((payment) => {
				if (value === "Customer Payments")
					return payment.invoices && payment.invoices.length > 0;
				else if (value === "Vendor Payments")
					return payment.bills.length > 0;
				else return true;
			});
			setFilteredPayments(newPayments);
		} else if (type === "date") {
			const newPayments = payments.filter((payment) => {
				const createdAt = Date.parse(payment.createdAt.split("T")[0]);
				const valueAsDate = Date.parse(value);

				return valueAsDate === createdAt;
			});
			setFilteredPayments(newPayments);
		} else {
			const newPayments = [...payments];
			setFilteredPayments(newPayments);
		}
	};

    const resetFilter = event => {
        event.preventDefault();

        setFilteredPayments([...payments])
    }

	useEffect(() => {
		if (payments) {
			setFilteredPayments(payments);
		}
	}, [payments]);

	return (
		<PaymentContextProvider>
			<section>
				<SuccessAlert
					title={"Success"}
					message={"Payment has been added successfully."}
					open={success}
					setOpen={setSuccess}
					fallbackPath={"/customer/payments"}
					fallbackName={"payments"}
				/>
				<div className="flex justify-between items-center mt-6">
					<div>
						<h1 className="greeting text-3xl">
							Payments Made/Received
						</h1>
						<p className="pt-3">
							A list of all payments made/received from customers
							& vendors.
						</p>
					</div>
					<form className="flex items-center">
                        <button onClick={resetFilter} className={`text-blue-500 text-sm ${showFilter ? 'block' : 'hidden'}`}>Reset Filter</button>
						<button
							className="flex items-center justify-center pl-3"
							onClick={(e) => {
								e.preventDefault();
								setShowFilter(!showFilter);
							}}
						>
							<FunnelIcon
								className="h-6 w-6"
								aria-hidden="true"
							/>
						</button>
					</form>
				</div>
				<div
					className={`grid-cols-3 mt-3 gap-4 ${
						showFilter ? "grid" : "hidden"
					}`}
				>
					<div>
						<label
							htmlFor="payment"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by type
						</label>
						<select
							id="payment"
							name="payment"
							className="border mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm bg-white"
							onChange={(e) => filterPayments(e, "type")}
						>
							<option>All</option>
							<option>Customer Payments</option>
							<option>Vendor Payments</option>
						</select>
					</div>
					<div>
						<label
							htmlFor="customer"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by customer
						</label>
						<select
							id="customer"
							name="customer"
							className="border mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm bg-white"
							onChange={(e) => filterPayments(e, "customer")}
						>
							<option>All</option>
							{customers?.map((customer, index) => {
								return (
									<option key={index}>{customer.name}</option>
								);
							})}
						</select>
					</div>
					<div>
						<label
							htmlFor="date"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by date
						</label>
						<input
							id="date"
							onChange={(e) => filterPayments(e, "date")}
							type={"date"}
							name="date"
							className="border mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						/>
					</div>
				</div>
			</section>
			<section className="mt-4">
				<table className="min-w-full divide-y divide-gray-200">
					<thead className="bg-gray-50">
						<tr>
							<th
								scope="col"
								className="
                      pr-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Date
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Customer Name
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Payment For
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Amount
							</th>
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						{filteredPayments?.map((payment, index) => {
							return (
								<tr key={payment._id}>
									<td className="pr-6 py-4 whitespace-nowrap">
										<div className="text-sm text-gray-900">
											{new Date(
												payment.createdAt
											).toDateString()}
										</div>
									</td>
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="text-sm text-gray-900">
											{payment.customer_id?.name ||
												payment.vendor_id?.name}
										</div>
									</td>

									<td className="px-6 py-4 whitespace-nowrap">
										<div className="text-sm text-gray-900">{payment.payment_for}</div>
									</td>

									<td className="px-6 py-4 w-20 whitespace-nowrap">
										<div className="text-sm font-bold text-gray-900">
											D
											{formatPrice(
												moneyize(payment.amount)
											)}
										</div>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</section>
			<NewCustomerPayment setSuccess={setSuccess} />
		</PaymentContextProvider>
	);
}
