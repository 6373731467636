import { Fragment, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";

import { classNames } from "../utils/tailwindcss";
import { UIContext } from "../context/UIContext";
import { timeframes } from "../lib/constants";
import DateRange from "./utils/DateRange";
import { MainContext } from "../context/MainContext";

export default function MainHeader() {
	const { setSlideOver } = useContext(UIContext);
    const { dateRange, setDateRange } = useContext(MainContext)
	const navigate = useNavigate();

	const handleNavigate = (e, dest, state) => {
		e.preventDefault();
		setSlideOver((prevState) => {
			return {
				...prevState,
				[state]: true,
			};
		});
        navigate(`/${dest}`)
	};
	return (
		<section className="top">
			<nav>
				<li>
					<a href="https://google.com">Homebase</a>
				</li>
				<li>
					<a href="https://google.com">Focus</a>
				</li>
				<li>
					<a href="https://google.com">Calendar</a>
				</li>
				<li>
					<a href="https://google.com">Insights</a>
				</li>
			</nav>
			<div className="icons">
                <DateRange list={timeframes} setSelectedItem={setDateRange} selectedItem={dateRange}/>
				<Menu as="section" className="relative inline-block text-left">
					<Menu.Button className="select flex items-center text-white p-2 px-4 rounded-3xl">
						<span className="text-sm">Create new</span>
						<span className="material-symbols-outlined">
							arrow_drop_down
						</span>
					</Menu.Button>

					<Transition
						as={Fragment}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
					>
						<Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
							<div className="py-1">
								<Menu.Item>
									{({ active }) => (
										<Link
											to="/invoices/new"
											className={classNames(
												active
													? "bg-gray-100 text-gray-900"
													: "text-gray-700",
												"block px-4 py-2 text-sm"
											)}
										>
											Invoice
										</Link>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<Link
											to="/bills/new"
											className={classNames(
												active
													? "bg-gray-100 text-gray-900"
													: "text-gray-700",
												"block px-4 py-2 text-sm"
											)}
										>
											Bill
										</Link>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<button
                                            onClick={(e) => handleNavigate(e,'stocks', 'showAddStockTransferPage')}
											className={classNames(
												active
													? "bg-gray-100 text-gray-900"
													: "text-gray-700",
												"block px-4 py-2 text-sm text-left w-full"
											)}
										>
											Stock transfer
										</button>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<button
                                            onClick={(e) => handleNavigate(e,'customers', 'showAddCustomerPage')}
											className={classNames(
												active
													? "bg-gray-100 text-gray-900"
													: "text-gray-700",
												"block px-4 py-2 text-sm text-left w-full"
											)}
										>
											Customer
										</button>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<button
                                            onClick={(e) => handleNavigate(e,'expenses', 'showAddExpensePage')}
											className={classNames(
												active
													? "bg-gray-100 text-gray-900"
													: "text-gray-700",
												"block px-4 py-2 text-sm text-left w-full"
											)}
										>
											Expense
										</button>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<button
                                            onClick={(e) => handleNavigate(e,'customer/payments', 'showAddCustomerPaymentPage')}
											className={classNames(
												active
													? "bg-gray-100 text-gray-900"
													: "text-gray-700",
												"block px-4 py-2 text-sm text-left w-full"
											)}
										>
											Customer Payment
										</button>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<button
                                            onClick={(e) => handleNavigate(e,'vendors', 'showAddVendorPage')}
											className={classNames(
												active
													? "bg-gray-100 text-gray-900"
													: "text-gray-700",
												"block px-4 py-2 text-sm text-left w-full"
											)}
										>
											Vendor
										</button>
									)}
								</Menu.Item>
								<Menu.Item>
									{({ active }) => (
										<button
                                            onClick={(e) => handleNavigate(e,'products', 'showAddProductPage')}
											className={classNames(
												active
													? "bg-gray-100 text-gray-900"
													: "text-gray-700",
												"block w-full px-4 py-2 text-left text-sm"
											)}
										>
											Product
										</button>
									)}
								</Menu.Item>
							</div>
						</Menu.Items>
					</Transition>
				</Menu>
			</div>
		</section>
	);
}
