import { useState } from "react";
import { Link } from "react-router-dom";
import StepOne from "./RegisterStepOne";
import StepTwo from "./RegisterStepTwo";

export default function Register() {
    const [ step, setStep ] = useState(1);
    const handleSubmit = e => {
        e.preventDefault();
        if(step === 1) {
            setStep(2);
        } else {
            alert('Registration for business is manual')
        } 
    }
	return (
		<section className="h-screen w-full flex justify-center items-center">
			<div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
				<div className="font-medium self-center text-xl sm:text-2xl uppercase text-gray-800">
					Create A New Account
				</div>
				<div className="relative mt-10 h-px bg-gray-300">
					<div className="absolute left-0 top-0 flex justify-center w-full -mt-2">
						<span className="bg-white px-4 text-xs text-gray-500 uppercase">
							Enter Details to Sign Up
						</span>
					</div>
				</div>
				<div className="mt-10">
					<form action="#">
						
                        {step === 1 ? <StepOne /> : <StepTwo />}						
						<div className="flex w-full">
							<button
                                onClick={handleSubmit}
								type="submit"
								className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-[#2046cf] rounded py-2 w-full transition duration-150 ease-in"
							>
								<span className="mr-2 uppercase">{step === 1 ? 'Next' : 'Register'}</span>
								<span>
									<svg
										className="h-6 w-6"
										fill="none"
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
									</svg>
								</span>
							</button>
						</div>
					</form>
				</div>
				<div className="flex justify-center items-center mt-6">
					<Link
						to={"/login"}
						className="inline-flex items-center font-bold text-[#2046cf] text-xs text-center"
					>
						<span>
							<svg
								className="h-6 w-6"
								fill="none"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
							</svg>
						</span>
						<span className="ml-2">You already have an account?</span>
					</Link>
				</div>
			</div>
		</section>
	);
}
