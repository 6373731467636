import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUser } from "../utils/auth";

export const AuthContext = createContext(null);

export default function AuthContextProvider({children}) {
    const navigate = useNavigate();
    const [ user, setUser ] = useState(null);

    const fetchUser = async () => {
        const user = await getUser();
        if(!user) {
            navigate('/login');
        } else {
            setUser(user);
        }
    }

    useEffect(() => {
        fetchUser();
    }, [])

    return (
        <AuthContext.Provider value={{user,setUser}}>
            {children}
        </AuthContext.Provider>
    )
}
