export default function ProductTransactions() {
	return (
		<div>
			<div className="grid grid-cols-3 mt-3 gap-8 mt-6">
				<div>
					<label
						htmlFor="branch"
						className="block text-sm font-medium text-gray-700"
					>
						Filter by category
					</label>
					<select
						id="branch"
						name="branch"
						className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm bg-white border"
					>
						<option>Sales Order</option>
						<option>Canada</option>
						<option>Mexico</option>
					</select>
				</div>
				<div>
					<label
						htmlFor="status"
						className="block text-sm font-medium text-gray-700"
					>
						Filter by status
					</label>
					<select
						id="status"
						name="status"
						className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm border bg-white"
					>
						<option>All</option>
						<option>Canada</option>
						<option>Mexico</option>
					</select>
				</div>
			</div>
			<table className="min-w-full divide-y divide-gray-200 mt-6">
				<thead className="bg-gray-50">
					<tr>
						<th
							scope="col"
							className="
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Date
						</th>
						<th
							scope="col"
							className="
                      py-3
                            pl-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Sales Order
						</th>
						<th
							scope="col"
							className="
                      py-3
                      text-left text-xs
                            pl-3
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Customer Name
						</th>

						<th
							scope="col"
							className="
                      py-3
                            pl-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Qty Sold
						</th>
						<th
							scope="col"
							className="
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                            pl-3
                    "
						>
							Price
						</th>
						<th
							scope="col"
							className="
                      py-3
                      text-left text-xs
                            pl-3
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>Total</th>
						<th
							scope="col"
							className="
                      py-3
                      text-left text-xs
                            pl-3
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Status
						</th>
					</tr>
				</thead>
				<tbody className="bg-white divide-y divide-gray-200">
					<tr>
						<td className="py-4 whitespace-nowrap">
							<div className="text-sm text-gray-900">15th May, 2023</div>
						</td>
						<td className="pl-3 py-4 whitespace-nowrap">
							<div className="text-sm text-gray-900">SO-00001</div>
						</td>
						<td className="pl-3 py-4 whitespace-nowrap">
							<div className="text-sm text-gray-900">
								Saul Sawaneh
							</div>
						</td>

						<td className="pl-3 py-4 whitespace-nowrap">
							<div className="text-sm font-bold text-gray-900">1.00</div>
						</td>
						<td className="py-4 pl-3 whitespace-nowrap">
							<div className="text-sm font-bold text-gray-900">
								GMD100.00
							</div>
						</td>

						<td className="py-4 w-20 pl-3 whitespace-nowrap">
							<div className="text-sm font-bold text-gray-900">GMD100.00</div>
						</td>
						<td className="py-4 whitespace-nowrap pl-3">
							<span
								className="
                        px-2
                        inline-flex
                        text-xs
                        leading-5
                        font-semibold
                        rounded-full
                        bg-green-100
                        text-green-800
                      "
							>
								Closed
							</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}
