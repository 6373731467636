import { useState } from "react";
import { balanceSheet } from "../../../utils/reports";
import { formatPrice } from "../../../utils/misc";

export default function BalanceSheet() {
	const [startDate, setStartDate] = useState("");
	const [balanceSheetData, setBalanceSheetData] = useState({
		totalCash: 0,
		totalCurrentAssets: 0,
		totalLongTermAssets: 0,
		totalCurrentLiabilities: 0,
		totalLongTermLiabilities: 0,
		totalOtherEquity: 0,
		totalRetainedEarnings: 0,
	});

	const fetchReport = async (e) => {
		e.preventDefault();
		if (startDate !== "") {
			try {
				const { data } = await balanceSheet(startDate);
                if(data.length === 0) return
				setBalanceSheetData((prevState) => {
					return {
						...prevState,
						totalCash: data[0].totalAssets,
						totalCurrentAssets: data[0].otherCurrentAssets,
						totalRetainedEarnings: data[0].totalAssets,
					};
				});
				console.log(data);
			} catch (error) {
				console.log("Some error occured");
			} finally {
			}
		}
	};

	return (
		<>
			<form className="flex items-center gap-6">
				<div className="flex items-center gap-5">
					<input
						type={"date"}
						className="border border-gray-400 p-2 rounded w-48"
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
					/>
				</div>
				<button
					className="select flex items-center text-white p-3 px-4 rounded-3xl bg-[#2046cf]"
					onClick={fetchReport}
				>
					<span className="text-sm">Update report</span>
				</button>
			</form>
			<div className="mt-12">
				<div className="flex items-end justify-between border-b border-gray-400 pb-2 px-2">
					<span className="uppercase font-bold text-sm">Assets</span>
					<span className="uppercase font-bold text-sm">
						{new Date(startDate).toDateString()}
					</span>
				</div>
				<div>
					<div className="flex items-center justify-between border-b border-gray-300 p-2">
						<span>Total Cash</span>
						<span>D{formatPrice(balanceSheetData.totalCash)}</span>
					</div>
					<div className="flex items-center justify-between border-b border-gray-300 p-2">
						<span>Total Other Current Assets</span>
						<span>
							D{formatPrice(balanceSheetData.totalCurrentAssets)}
						</span>
					</div>
					<div className="flex items-center justify-between border-b border-gray-300 p-2">
						<span>Total Long-term Assets</span>
						<span>
							D{formatPrice(balanceSheetData.totalLongTermAssets)}
						</span>
					</div>
					<div className="flex items-center justify-between border-b border-gray-300 py-2 bg-gray-100 px-2">
						<div className="flex flex-col">
							<span className="font-bold">Total</span>
						</div>
						<div className="flex flex-col">
							<span className="font-bold">
								D
								{formatPrice(
									balanceSheetData.totalCash +
										balanceSheetData.totalCurrentAssets +
										balanceSheetData.totalLongTermAssets
								)}
							</span>
						</div>
					</div>
				</div>
				<div className="flex items-end justify-between border-b border-gray-400 pb-2 px-2 mt-12">
					<span className="uppercase font-bold text-sm">
						Liabilities
					</span>
					<span className="uppercase font-bold text-sm">
						{new Date(startDate).toDateString()}
					</span>
				</div>
				<div>
					<div className="flex items-center justify-between border-b border-gray-300 p-2">
						<span>Total Current Liabilities</span>
						<span>
							D
							{formatPrice(
								balanceSheetData.totalCurrentLiabilities
							)}
						</span>
					</div>
					<div className="flex items-center justify-between border-b border-gray-300 p-2">
						<span>Total Long-term Liabilities</span>
						<span>
							D
							{formatPrice(
								balanceSheetData.totalLongTermLiabilities
							)}
						</span>
					</div>
					<div className="flex items-center justify-between border-b border-gray-300 py-2 bg-gray-100 px-2">
						<div className="flex flex-col">
							<span className="font-bold">Total Liabilities</span>
						</div>
						<div className="flex flex-col">
							<span className="font-bold">
								D
								{formatPrice(
									balanceSheetData.totalCurrentLiabilities +
										balanceSheetData.totalLongTermLiabilities
								)}
							</span>
						</div>
					</div>
				</div>
				<div className="flex items-end justify-between border-b border-gray-400 pb-2 px-2 mt-12">
					<span className="uppercase font-bold text-sm">Equity</span>
					<span className="uppercase font-bold text-sm">
						{new Date(startDate).toDateString()}
					</span>
				</div>
				<div>
					<div className="flex items-center justify-between border-b border-gray-300 p-2">
						<span>Total Other Equity</span>
						<span>
							D{formatPrice(balanceSheetData.totalOtherEquity)}
						</span>
					</div>
					<div className="flex items-center justify-between border-b border-gray-300 p-2">
						<span>Total Retained Earnings</span>
						<span>
							D
							{formatPrice(
								balanceSheetData.totalRetainedEarnings
							)}
						</span>
					</div>
					<div className="flex items-center justify-between border-b border-gray-300 py-2 bg-gray-100 px-2">
						<div className="flex flex-col">
							<span className="font-bold">Total Equity</span>
						</div>
						<div className="flex flex-col">
							<span className="font-bold">
								D
								{formatPrice(
									balanceSheetData.totalOtherEquity +
										balanceSheetData.totalRetainedEarnings
								)}
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
