import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
	PencilSquareIcon,
	TrashIcon,
	XMarkIcon,
} from "@heroicons/react/24/outline";
import { formatPrice } from "../../utils/misc";

export default function PreviewExpense({ open, setOpen, expense }) {
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-500"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-500"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto w-screen max-w-3xl">
									<div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
										<div className="px-4 sm:px-6">
											<div className="flex items-start justify-between">
												<Dialog.Title className="text-xl font-medium text-gray-900">
													Expense Details
												</Dialog.Title>
												<div className="ml-3 flex h-7 items-center">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
														onClick={() =>
															setOpen(!open)
														}
													>
														<span className="sr-only">
															Close panel
														</span>
														<XMarkIcon
															className="h-6 w-6"
															aria-hidden="true"
														/>
													</button>
												</div>
											</div>
										</div>
										<div className="relative mt-2 flex-1 px-4 sm:px-6">
											<div className="mb-4 flex items-center gap-6">
												<button className="flex items-center hidden">
													<PencilSquareIcon
														width={13}
														height={13}
													/>
													<span className="text-xs pl-1">
														Edit
													</span>
												</button>
												<button className="flex items-center hidden">
													<TrashIcon
														width={13}
														height={13}
													/>
													<span className="text-xs pl-1">
														Delete
													</span>
												</button>
											</div>
											<div className="flex justify-between">
												<div className="overflow-hidden bg-white sm:rounded-lg">
													<div className="border-t border-gray-200">
														<dl>
															<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
																<dt className="text-sm font-medium text-gray-500">
																	Number
																</dt>
																<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
																	{
																		expense.expense_number
																	}
																</dd>
															</div>
															<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
																<dt className="text-sm font-medium text-gray-500">
																	Expense For
																</dt>
																<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 capitalize">
																	{
																		expense.expense_for
																	}
																</dd>
															</div>
															{expense.store_id && (
																<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
																	<dt className="text-sm font-medium text-gray-500">
																		Store
																	</dt>
																	<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
																		{
																			expense
																				.store_id
																				.name
																		}
																	</dd>
																</div>
															)}
															{expense.shop_id && (
																<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
																	<dt className="text-sm font-medium text-gray-500">
																		Shop
																	</dt>
																	<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
																		{
																			expense
																				.shop_id
																				.name
																		}
																	</dd>
																</div>
															)}
															<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
																<dt className="text-sm font-medium text-gray-500">
																	Amount
																</dt>
																<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
																	D
																	{formatPrice(
																		expense.amount
																	)}
																</dd>
															</div>
															<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
																<dt className="text-sm font-medium text-gray-500">
																	Date
																</dt>
																<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
																	{new Date(
																		expense.createdAt
																	).toDateString()}
																</dd>
															</div>
															<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
																<dt className="text-sm font-medium text-gray-500">
																	Description
																</dt>
																<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
																	{
																		expense.description
																	}
																</dd>
															</div>
														</dl>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
