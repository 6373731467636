export default function LoadingPulse({ itemCount }) {
	const renderItems = () => {
		const items = Array(itemCount).fill(0);
		return items.map((_, index) => {
			return (
				<td className="px-6 py-4" key={index}>
					<div className="bg-gray-200 rounded-lg py-1 animate-pulse text-gray-200">
						loading
					</div>
				</td>
			);
		});
	};
	return <tr>{renderItems()}</tr>;
}
