import { useState } from "react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

import SuccessAlert from "../../utils/SuccessAlert";
import ErrorAlert from "../../utils/ErrorAlert";
import { toggleErrorBorder } from "../../../utils/errors";
import { changePassword } from "../../../utils/auth";

export default function PreviewResetPassword() {
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});
	const [success, setSuccess] = useState(false);
	const [failed, setFailure] = useState(false);
	const [form, setForm] = useState({
		currentPassword: "",
		newPassword: "",
		confirmNewPassword: "",
	});

	const submit = async () => {
		const changed = await changePassword(form, setLoading, setErrors);
		if (changed) {
			setSuccess(true);
			setForm({
				currentPassword: "",
				newPassword: "",
				confirmNewPassword: "",
			});
		} else {
			setFailure(true);
		}
	};

	const updateForm = (event) => {
		setForm((prevForm) => {
			return {
				...prevForm,
				[event.target.name]: event.target.value,
			};
		});
	};

	return (
		<>
			<SuccessAlert
				title={"Password Changed"}
				message={"Password has been successfully changed"}
				open={success}
				setOpen={setSuccess}
				fallbackName={"login"}
				fallbackPath={"/login"}
			/>
			<ErrorAlert
				title={"Error"}
				message={
					"Your current password is wrong, or your new password does not match"
				}
				open={failed}
				setOpen={setFailure}
				fallbackName={"settings"}
				fallbackPath={"/settings"}
			/>
			<h3 className="text-lg underline">Change Password</h3>
			<form className="mt-6 w-1/2">
				<div className="mb-8">
					<label
						htmlFor="currentPassword"
						className="block text-sm font-medium text-gray-700"
					>
						Current Password
						<span className="text-red-700">*</span>
					</label>
					<div className="mt-2 mb-2">
						<input
							type="password"
							value={form.currentPassword}
							onChange={updateForm}
							name="currentPassword"
							id="currentPassword"
							className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
								errors,
								"currentPassword"
							)}`}
						/>
					</div>
				</div>
				<div className="mb-8">
					<label
						htmlFor="newPassword"
						className="block text-sm font-medium text-gray-700"
					>
						New Password
						<span className="text-red-700">*</span>
					</label>
					<div className="mt-2 mb-2">
						<input
							type="password"
							value={form.newPassword}
							onChange={updateForm}
							name="newPassword"
							id="newPassword"
							className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
								errors,
								"newPassword"
							)}`}
						/>
					</div>
				</div>
				<div className="mb-8">
					<label
						htmlFor="confirmNewPassword"
						className="block text-sm font-medium text-gray-700"
					>
						Confirm New Password
						<span className="text-red-700">*</span>
					</label>
					<div className="mt-2 mb-2">
						<input
							type="password"
							value={form.confirmNewPassword}
							onChange={updateForm}
							name="confirmNewPassword"
							id="confirmNewPassword"
							className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
								errors,
								"confirmNewPassword"
							)}`}
						/>
					</div>
				</div>
				<button
					onClick={submit}
					type="button"
					className="inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm bg-[#2046cf] w-2/3"
				>
					Change password
					{loading && (
						<ArrowPathIcon
							height={23}
							width={23}
							className="animate-spin"
						/>
					)}
				</button>
			</form>
		</>
	);
}
