export function toggleErrorBorder(errors, owner, reset = false) {
    if(reset) {
        return '';
    }
    if(errors.type === 'validation'){
        let border = '';
        errors.info.forEach(error => {
            if(error.message.includes(owner)) {
                border = 'border-red-500'
            }
        })
        return border;
    }
}
