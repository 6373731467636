import { Outlet } from "react-router-dom";

import SideNavigation from "./SideNavigation";
import MainHeader from "./MainHeader";
import { useUser } from "../hooks/auth";
import ActivityIndicator from "./utils/ActivityIndicator";

export default function MainContainer() {
	const user = useUser();
	return (
		<main className="dark:bg-[#202029]">
			{user ? (
				<>
					<div style={{ width: "16%", position:'fixed' }}>
						<SideNavigation />
					</div>
					<section className="main-section" style={{marginLeft: '16%'}}>
						<div className="main-area">
							<MainHeader />
							<Outlet />
						</div>
					</section>
				</>
			) : (
				<ActivityIndicator />
			)}
		</main>
	);
}
