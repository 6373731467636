import { useContext } from "react";

import { UIContext } from "../../context/UIContext";
import { handleNavigate } from "../../utils/misc";
import { useNavigate } from "react-router-dom";

export default function EmptyState({ title, stateKey }) {
	const navigate = useNavigate();
	const { setSlideOver } = useContext(UIContext);
	const action = (e) => {
		const link = stateKey.split("-")[1];
		if (link) {
			navigate(`/${link}/new`);
		} else {
			handleNavigate(e, stateKey, setSlideOver);
		}
	};
	return (
		<>
			<div className="text-center mt-12">
				<svg
					className="mx-auto h-12 w-12 text-gray-400"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
					aria-hidden="true"
				>
					<path
						vectorEffect={"non-scaling-stroke"}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
					/>
				</svg>
				<h3 className="mt-2 text-sm font-medium text-gray-900">
					No {title}
				</h3>
				<p className="mt-1 text-sm text-gray-500">
					Click on the select menu to add a {title}
				</p>
			</div>
			<div className="flex items-center justify-center mt-2">
				<button
					onClick={action}
					className="select flex items-center text-[#2046cf] p-2 px-4 rounded-3xl border border-[#2046cf] hover:bg-[#2046cf] hover:text-white"
				>
					<span className="text-md font-medium">New {title}</span>
					<span className="material-symbols-outlined">add</span>
				</button>
			</div>
		</>
	);
}

{
	/*
		<div className="flex items-center justify-center flex-col mt-20">
			<ArchiveBoxXMarkIcon width={200} height={200} />
			<p className="text-lg mb-5">You do not have any existing {title}</p>
			<button
				onClick={action}
				className="select flex items-center text-[#2046cf] p-2 px-4 rounded-3xl border border-[#2046cf] hover:bg-[#2046cf] hover:text-white"
			>
				<span className="text-md font-medium">New {title}</span>
				<span className="material-symbols-outlined">add</span>
			</button>
		</div>
            */
}
