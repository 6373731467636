import { useContext, useEffect, useState } from "react";
import {
	MagnifyingGlassIcon,
	FunnelIcon,
	ArrowPathIcon,
} from "@heroicons/react/24/outline";

import NewProduct from "../components/forms/NewProduct";
import PreviewProduct from "../components/previews/PreviewProduct";
import EditProduct from "../components/forms/EditProduct";

import ProductContextProvider from "../context/ProductContext";
import { UIContext } from "../context/UIContext";

import { formatPrice, handleNavigate } from "../utils/misc";
import { useQuery } from "react-query";
import { get } from "../utils/products";
import * as Store from "../utils/store";
import * as Shop from "../utils/shop";
import LoadingPulse from "../components/utils/LoadingPulse";
import EmptyState from "../components/utils/EmptyState";
import SuccessAlert from "../components/utils/SuccessAlert";
import { matchDate, matchName, matchStore, matchUnit } from "../lib/productFilter";

export default function Products() {
	const { isLoading, data: products } = useQuery("products", get);
	const { data: stores } = useQuery("stores", Store.get);
	const { data: shops } = useQuery("shops", Shop.get);

	const { setSlideOver } = useContext(UIContext);
	const [showFilter, setShowFilter] = useState(false);
	const [preview, setPreview] = useState(false);
	const [success, setSuccess] = useState(false);
	const [currentProduct, setCurrentProduct] = useState({});
	const [filteredProducts, setFilteredProducts] = useState([]);
    const [filterParams, setFilterParams] = useState({
        store_id: "all",
        unit: "all",
        createdAt: null,
        name: ""
    })

	const showProduct = (product) => {
		setCurrentProduct(product);
		setPreview(!preview);
	};

	const showAddProduct = (e) => {
		handleNavigate(e, "showAddProductPage", setSlideOver);
	};

	const filterProducts = (event) => {
		const value = event.target.value;
		const name = event.target.name;
        setFilterParams({...filterParams, [name]: value});
	};

    const applyFilters = () => {
        const {store_id, unit, createdAt, name} = filterParams;
        let newProducts = matchStore(matchDate(matchUnit(matchName(products, name), unit), createdAt), store_id);

        setFilteredProducts(newProducts);
    }


    useEffect(() => {
        applyFilters();
    }, [filterParams])

	useEffect(() => {
		if (products) {
			setFilteredProducts(products);
		}
	}, [products]);

	return (
		<ProductContextProvider>
			<section>
				<SuccessAlert
					title={"Success"}
					message={"Product has been added successfully."}
					open={success}
					setOpen={setSuccess}
					fallbackPath={"/products"}
					fallbackName={"products"}
				/>
				<div className="flex justify-between items-center mt-6">
					<div>
						<h1 className="greeting text-3xl">Products</h1>
						<p className="pt-3">A list of all products.</p>
					</div>
					<div className="flex items-center gap-6">
						<form className="flex items-center">
							<button
								className={`text-[#2046cf] ${
									showFilter ? "block" : "hidden"
								}`}
								onClick={(e) => {
									e.preventDefault();
									setFilterParams({
                                        store_id: "all",
                                        unit: "all",
                                        createdAt: null,
                                        name: ""
                                    });
								}}
							>
								Restore products
							</button>
							<div className="relative mt-1 rounded-md shadow-sm w-72">
								<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
									<MagnifyingGlassIcon
										className="h-5 w-5 text-gray-400"
										aria-hidden="true"
									/>
								</div>
								<input
									type="text"
									name="name"
									onChange={(e) => filterProducts(e)}
									id="name"
									className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm outline-none"
									placeholder="Search"
								/>
							</div>
							<button
								className="flex items-center justify-center pl-3"
								onClick={(e) => {
									e.preventDefault();
									setShowFilter(!showFilter);
								}}
							>
								<FunnelIcon
									className="h-6 w-6"
									aria-hidden="true"
								/>
							</button>
						</form>
						<button
							onClick={showAddProduct}
							className="select flex items-center text-[#2046cf] p-2 px-4 rounded-3xl border border-[#2046cf] hover:bg-[#2046cf] hover:text-white"
						>
							<span className="text-md font-medium">
								Add product
							</span>
							<span className="material-symbols-outlined">
								add
							</span>
						</button>
					</div>
				</div>
				<div
					className={`grid-cols-3 mt-3 gap-4 ${
						showFilter ? "grid" : "hidden"
					}`}
				>
					<div>
						<label
							htmlFor="unit"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by unit
						</label>
						<select
							id="unit"
							onChange={(e) => filterProducts(e)}
							name="unit"
							className="bg-white border mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						>
							<option>Select unit</option>
							<option value={"all"}>All</option>
							<option value={"pieces"}>pcs</option>
							<option value={"bags"}>bag</option>
							<option value={"packets"}>pkt</option>
							<option value={"rolls"}>rolls</option>
							<option value={"bundles"}>bundle</option>
							<option value={"cartons"}>cartons</option>
							<option value={"boxes"}>box</option>
							<option value={"centimetres"}>cm</option>
							<option value={"dozens"}>dz</option>
							<option value={"feet"}>ft</option>
							<option value={"grams"}>g</option>
							<option value={"kilograms"}>kg</option>
							<option value={"inches"}>in</option>
							<option value={"metres"}>m</option>
							<option value={"milligrams"}>mg</option>
							<option value={"pounds"}>lb</option>
							<option value={"millilitres"}>ml</option>
							<option value={"litres"}>l</option>
						</select>
					</div>
					<div>
						<label
							htmlFor="store"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by store or shop
						</label>
						<select
							id="store"
							name="store_id"
							className="bg-white border mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
							onChange={(e) => filterProducts(e)}
						>
							<option value={"all"}>All stores & shops</option>
							{stores?.data.map((store, index) => {
								return (
									<option value={store._id} key={index}>
										{store.name}
									</option>
								);
							})}
							{shops?.data.map((shop, index) => {
								return (
									<option value={shop._id} key={index}>
										{shop.name}
									</option>
								);
							})}
						</select>
					</div>
					<div>
						<label
							htmlFor="date"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by date
						</label>
						<input
							id="createdAt"
							onChange={(e) => filterProducts(e)}
							type={"date"}
							name="createdAt"
							className="border mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						/>
					</div>
				</div>
			</section>
			<section className="mt-2">
				{!filteredProducts || filteredProducts?.length === 0 ? (
					<EmptyState
						title={"product"}
						stateKey={"showAddProductPage"}
					/>
				) : (
					<table className="min-w-full divide-y divide-gray-200">
						<thead className="bg-gray-50">
							<tr>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Name
								</th>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Quantity
								</th>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Available For Sale
								</th>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Unit
								</th>

								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Cost Price
								</th>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Sold at
								</th>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Date
								</th>
							</tr>
						</thead>
						<tbody className="bg-white divide-y divide-gray-200">
							{filteredProducts?.map((product, index) => {
								return (
									<tr key={product._id}>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												{product.name}
											</div>
										</td>
										{/*
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="text-sm text-gray-900">
											PRD-000001
										</div>
									</td>
                                    */}
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												{product.quantity}
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900 flex items-center gap-2">
												{product.available_for_Sale}
												{product.reorder_point >=
													product.available_for_Sale && (
													<span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
														running out of stock
													</span>
												)}
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												{product.unit}
											</div>
										</td>

										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												D
												{formatPrice(
													product.cost_price
												)}
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												D
												{formatPrice(
													product.selling_price
												)}
											</div>
										</td>

										<td className="px-6 py-4 w-20 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												{new Date(
													product.createdAt
												).toDateString()}
											</div>
										</td>
										{/*
									<td className="px-6 py-4 whitespace-nowrap">
										<span
											className="
                        px-2
                        inline-flex
                        text-xs
                        leading-5
                        font-semibold
                        rounded-full
                        bg-green-100
                        text-green-800
                      "
										>
											True
										</span>
									</td>
                                    */}
										<td
											className="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
										>
											<button
												className="text-indigo-600 hover:text-indigo-900"
												onClick={() =>
													showProduct(product)
												}
											>
												View
											</button>
										</td>
									</tr>
								);
							})}
							{isLoading && <LoadingPulse itemCount={7} />}
						</tbody>
					</table>
				)}
			</section>
			<NewProduct setSuccess={setSuccess} />
			<EditProduct setSuccess={setSuccess} />
			<PreviewProduct
				open={preview}
				setOpen={setPreview}
				product={currentProduct}
			/>
		</ProductContextProvider>
	);
}
