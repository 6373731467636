import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

import Error from "../../components/utils/Error";
import { login } from "../../utils/auth";
import { toggleErrorBorder } from "../../utils/errors";

export default function Login() {
    const navigate = useNavigate();

	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({
		email: "",
		password: "",
	});

	const handleLogin = async (e) => {
		e.preventDefault();
		const success = await login(form, setErrors, setLoading);
        if(success) {
            navigate('/')
        }
	};

	const handleChange = (e) => {
		if (errors.type) {
			setErrors({});
			toggleErrorBorder(errors, "", true);
		}
		const { value, name } = e.target;
		setForm((prevForm) => {
			return {
				...prevForm,
				[name]: value,
			};
		});
	};

	return (
		<section className="h-screen w-full flex justify-center items-center">
			<div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
				<div className="font-medium self-center text-xl sm:text-2xl uppercase text-gray-800">
					Login To Your Account
				</div>
				<div className="relative mt-10 h-px bg-gray-300">
					<div className="absolute left-0 top-0 flex justify-center w-full -mt-2">
						<span className="bg-white px-4 text-xs text-gray-500 uppercase">
							Login With Email
						</span>
					</div>
				</div>
				<div className="mt-10">
					<form>
                        {errors.type === 'credentials' && <Error message={errors.info}/>} 
						<div className="flex flex-col mb-6">
							<label
								htmlFor="email"
								className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
							>
								Email Address:
							</label>
							<div className="relative">
								<div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
									<svg
										className="h-6 w-6"
										fill="none"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
									</svg>
								</div>

								<input
									id="email"
									type="email"
									value={form.email}
									name="email"
									onChange={handleChange}
									className={`text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-300 w-full py-2 focus:outline-none focus:border-[#2046cf] ${toggleErrorBorder(
										errors,
										"email"
									)}`}
									placeholder="Email Address"
								/>
							</div>
						</div>
						<div className="flex flex-col mb-6">
							<label
								htmlFor="password"
								className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
							>
								Password:
							</label>
							<div className="relative">
								<div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
									<span>
										<svg
											className="h-6 w-6"
											fill="none"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth={2}
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
										</svg>
									</span>
								</div>

								<input
									id="password"
									type="password"
									name="password"
									value={form.password}
									onChange={handleChange}
									className={`text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-300 w-full py-2 focus:outline-none focus:border-[#2046cf] ${toggleErrorBorder(
										errors,
										"password"
									)}`}
									placeholder="Password"
								/>
							</div>
						</div>

						<div className="flex items-center mb-6 -mt-4">
							<div className="flex ml-auto">
								<a
									href="#"
									className="inline-flex text-xs sm:text-sm text-[#2046cf]"
								>
									Forgot Your Password?
								</a>
							</div>
						</div>

						<div className="flex w-full">
							<button
								onClick={handleLogin}
								type="submit"
								className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-[#2046cf] rounded py-2 w-full transition duration-150 ease-in"
							>
								<span className="mr-2 uppercase">Login</span>
								{loading ? (
									<ArrowPathIcon height={23} width={23} className='animate-spin'/>
								) : (
									<span>
										<svg
											className="h-6 w-6"
											fill="none"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth={2}
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
										</svg>
									</span>
								)}
							</button>
						</div>
					</form>
				</div>
				<div className="flex justify-center items-center mt-6">
					<Link
						to={"/register"}
						className="inline-flex items-center font-bold text-[#2046cf] text-xs text-center"
					>
						<span>
							<svg
								className="h-6 w-6"
								fill="none"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
							</svg>
						</span>
						<span className="ml-2">You don't have an account?</span>
					</Link>
				</div>
			</div>
		</section>
	);
}
