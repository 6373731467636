import apiClient from "./fetch";

export async function create(form, setErrors, setLoading) {
	setLoading(true);
	let success = false;
	try {
		const invoice = await apiClient.post("/invoices/add", form);
		success = true;
		setLoading(false);
		return [success, invoice];
	} catch (error) {
		if (error.response.status < 500) {
			if (typeof error.response.data.error === "string") {
				setErrors({
					type: "credentials",
					info: error.response.data.error,
				});
			} else {
				setErrors({
					type: "validation",
					info: error.response.data.error.details,
				});
			}
		}
		success = false;
		setLoading(false);
		return [success, error];
	}
}

export async function update(form, setErrors, setLoading, id) {
	setLoading(true);
	let success = false;
	try {
		const invoice = await apiClient.patch(`/invoices/invoice/${id}`, form);
		success = true;
		setLoading(false);
		return [success, invoice];
	} catch (error) {
		if (error.response.status < 500) {
			if (typeof error.response.data.error === "string") {
				setErrors({
					type: "credentials",
					info: error.response.data.error,
				});
			} else {
				setErrors({
					type: "validation",
					info: error.response.data.error.details,
				});
			}
		}
		success = false;
		setLoading(false);
		return [success, error];
	}
}

export async function get() {
	try {
		const { data: invoices } = await apiClient.get("/invoices");
		return invoices.data;
	} catch (error) {
		throw error;
	}
}

export async function getOverdue() {
	try {
		const { data: invoices } = await apiClient.get("/invoices/overdue");
		return invoices.data;
	} catch (error) {
		throw error;
	}
}

export async function getStats() {
	try {
		const { data: invoices } = await apiClient.get("/invoices/stats");
		return invoices.data;
	} catch (error) {
		throw error;
	}
}
