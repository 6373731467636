import { createContext, useState } from "react";

const initialState = {
	dateRange: {
		name: "All time",
	},
};

export const MainContext = createContext(initialState);
export default function MainContextProvider({ children }) {
	const [dateRange, setDateRange] = useState({ name: "All time" });
	return (
		<MainContext.Provider value={{ dateRange, setDateRange }}>
			{children}
		</MainContext.Provider>
	);
}
