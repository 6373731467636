import { useState } from "react";
import ProfitLoss from "./ProfitLoss";
import SubSection from "../SubSection";
import BalanceSheet from "./BalanceSheet";
import CashFlow from "./CashFlow";
import InventoryValuation from "./InventoryValuation";
import CustomerAccounts from "./CustomerAccounts";
import RefundHistory from "./RefundHistory";
import VendorAccounts from "./VendorAccounts";
import CustomerBalances from "./CustomerBalances";

export default function General() {
	const [active, setActive] = useState();
	return (
		<>
			{active && (
				<div
					className="pt-6 flex items-center text-[#2046cf] cursor-pointer"
					onClick={() => setActive(null)}
				>
					<span className="material-symbols-outlined">
						arrow_back
					</span>
					<p className="pl-2">Go back</p>
				</div>
			)}
			<div className="mt-6 flex flex-col gap-6">
				<SubSection
					title={"Profit & Loss"}
					subtitle={"Quarterly profit and loss by category"}
					icon={"monetization_on"}
					setActive={setActive}
					active={active}
				/>
				<SubSection
					title={"Balance Sheet"}
					subtitle={
						"Snapshot of your assets, liabilities and equities"
					}
					icon={"balance"}
					setActive={setActive}
					active={active}
				/>
				<SubSection
					title={"Cash Flow"}
					subtitle={
						"Shows how much money is entering and leaving your business."
					}
					icon={"chart_data"}
					setActive={setActive}
					active={active}
				/>
                {/*
				<SubSection
					title={"Inventory Valuation"}
					subtitle={
						"Breakdown the cost of a single item and how the system generated its cost"
					}
					icon={"price_change"}
					setActive={setActive}
					active={active}
				/>
                */}
				<SubSection
					title={"Customer Balances"}
					subtitle={"List all customers balances broken down"}
					icon={"price_change"}
					setActive={setActive}
					active={active}
				/>
				<SubSection
					title={"Vendor Account Payables"}
					subtitle={
						"List all late bills you owe to your vendors, broken down by their due date"
					}
					icon={"balance"}
					setActive={setActive}
					active={active}
				/>
			</div>
			<div className="mt-6 ml-11">
				{active === "Profit & Loss" && <ProfitLoss />}
				{active === "Balance Sheet" && <BalanceSheet />}
				{active === "Cash Flow" && <CashFlow />}
				{active === "Inventory Valuation" && <InventoryValuation />}
				{active === "Customer Accounts Receivables" && (
					<CustomerAccounts />
				)}
				{active === "Refund History" && <RefundHistory />}
				{active === "Vendor Account Payables" && <VendorAccounts />}
				{active === "Customer Balances" && <CustomerBalances />}
			</div>
		</>
	);
}
