export default function SubSection({
	title,
	subtitle,
	icon,
	setActive,
	active,
}) {
	const handleClick = () => setActive(title);
	return (
		<div
			className={`flex cursor-pointer ${
				active != null && active != title ? "hidden" : ""
			}`}
			onClick={handleClick}
		>
			<span
				className="material-symbols-outlined"
				style={{ fontSize: "40px" }}
			>
				{icon}
			</span>
			<div className="pl-1">
				<h3 className="text-lg">{title}</h3>
				<p className="pt-1 text-sm">{subtitle}</p>
			</div>
		</div>
	);
}
