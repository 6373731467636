import { useUser } from "../../../hooks/auth";

export default function PreviewGeneral() {
	const user = useUser();
	return (
		<>
			<h3 className="text-lg underline">General Settings</h3>
			<div className="flex items-center justify-between mt-5 mb-5">
				<div>
					<h3 className="text-sm font-medium">Business Name</h3>
					<p className="text-sm">
						Will appear on receipts, invoices, and other
						communication{" "}
					</p>
				</div>
				<input
					placeholder="Your business name"
					value={user.business_id.business_name}
					className="border border-gray-400 p-2 text-sm rounded w-60"
				/>
			</div>
			<div className="flex items-center justify-between mt-5 mb-5">
				<div>
					<h3 className="text-sm font-medium">Business Type</h3>
					<p className="text-sm">
						Will appear on receipts, invoices, and other
						communication{" "}
					
					</p>
				</div>
				<select
					id="business_type"
					name="business_type"
					className="bg-white border mt-1 block w-60 rounded-md border-gray-300 py-[10px] pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
				>
					<option value={"unspecified"}>Unspecified</option>
					<option value={"Corporation"}>Corporation</option>
					<option value={"S Corporation"}>S Corporation</option>
					<option value={"Partnership"}>Partnership</option>
					<option value={"Sole Proprietorship"}>
						Sole Proprietorship
					</option>
					<option value={"Single Member LLC"}>
						Single Member LLC
					</option>
				</select>
			</div>
			<div className="flex items-center justify-between mt-5 mb-5">
				<div>
					<h3 className="text-sm font-medium">Business Nature</h3>
					<p className="text-sm">
						Will appear on receipts, invoices, and other
						communication{" "}
					</p>
				</div>
				<input
					placeholder="Your business name"
					value={user.business_id.nature}
					className="border border-gray-400 p-2 text-sm rounded w-60"
				/>
			</div>
			<div className="flex items-center justify-between mt-5 mb-5">
				<div>
					<h3 className="text-sm font-medium">Business Email</h3>
					<p className="text-sm">
						Used to sign in, for email receipts and product updates.
					</p>
				</div>
				<input
					placeholder="Your business email address"
					value={user.business_id.email}
					className="border border-gray-400 p-2 text-sm rounded w-60"
				/>
			</div>
			<div className="flex items-center justify-between mt-5 mb-5">
				<div>
					<h3 className="text-sm font-medium">
						Business Phone Number
					</h3>
					<p className="text-sm">
						Will appear on receipts, invoices, and other
						communication
					</p>
				</div>
				<input
					placeholder="Your business phone number"
					value={""}
					className="border border-gray-400 p-2 text-sm rounded w-60"
				/>
			</div>
			<div className="flex items-center justify-between mt-5 mb-5">
				<div>
					<h3 className="text-sm font-medium">Country</h3>
					<p className="text-sm">
						Will appear on receipts, invoices, and other
						communication
					</p>
				</div>
				<div className="flex items-center gap-2 w-1/4">
					<span className="text-2xl">🇬🇲</span>
					<span>{user.business_id.country}</span>
				</div>
			</div>
			<button className="select flex items-center text-white p-3 rounded-3xl bg-[#2046cf] px-12 mt-12">
				<span className="text-sm">Save</span>
			</button>
		</>
	);
}
