import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import PreviewBill from "../components/previews/PreviewBill";
import * as Vendor from "../utils/vendors";
import { get } from "../utils/bills";
import { calculateDue, formatPrice, toggleStatusColor } from "../utils/misc";
import PaymentContextProvider from "../context/PaymentContext";
import EmptyState from "../components/utils/EmptyState";

export default function Bills() {
	const { data: bills } = useQuery("bills", get);
	const { data: vendors } = useQuery("vendors", Vendor.get);

	const [preview, setPreview] = useState(false);
	const [currentBill, setCurrentBill] = useState({});
	const [filteredBills, setFilteredBills] = useState([]);

	const showBill = (bill) => {
		setCurrentBill(bill);
		setPreview(true);
	};

	const filterBills = (event, type) => {
		const value = event.target.value;
		let newBills = [];
		switch (type) {
			case "vendor":
				if (value === "All") {
					setFilteredBills([...bills]);
				} else {
					newBills = bills.filter(
						(bill) => bill.customer_id.name === value
					);
					setFilteredBills(newBills);
				}

				break;
			case "status":
				if (value === "All") {
					setFilteredBills([...bills]);
				} else {
					newBills = bills.filter(
						(bill) => bill.status === value.toLowerCase()
					);
					setFilteredBills(newBills);
				}
				break;

			case "shipped_date":
				newBills = bills.filter((bill) => {
					const shippedDate = Date.parse(
						bill.shipped_date.split("T")[0]
					);
					const valueAsDate = Date.parse(value);

					return valueAsDate === shippedDate;
				});
				setFilteredBills(newBills);
				break;
			case "due_date":
				newBills = bills.filter((bill) => {
					const dueDate = Date.parse(bill.due_date.split("T")[0]);
					const valueAsDate = Date.parse(value);

					return valueAsDate === dueDate;
				});
				setFilteredBills(newBills);
				break;
		}
	};

	const calculateBalance = (bill) => {
		let total = 0;
		bill.products.forEach((product) => {
			total += product.selling_price * product.quantity;
		});

		return formatPrice(total - bill.totalPayments);
	};

	const calculateBillPrice = (products) => {
		let total = 0;
		products.forEach((product) => {
			total += product.selling_price * product.quantity;
		});
		return formatPrice(total);
	};

	useEffect(() => {
		if (bills) {
			setFilteredBills(bills);
		}
	}, [bills]);

	return (
		<PaymentContextProvider>
			<section className="main">
				<div className="mb-5">
					<h1 className="greeting text-3xl">Bills</h1>
					<p>A list of all bills.</p>
				</div>
				<div className="grid-cols-4 mt-3 gap-4 mt-10 grid">
					<div>
						<label
							htmlFor="vendor"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by vendor
						</label>
						<select
							id="vendor"
							name="vendor"
							onChange={(e) => filterBills(e, "vendor")}
							className="mt-1 block w-full rounded-md border bg-transparent border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						>
							<option>All</option>
							{vendors?.map((vendor, index) => {
								return (
									<option key={index}>{vendor.name}</option>
								);
							})}
						</select>
					</div>
					<div>
						<label
							htmlFor="status"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by status
						</label>
						<select
							id="status"
							name="status"
							className="mt-1 block w-full rounded-md border bg-transparent border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
							onChange={(e) => filterBills(e, "status")}
						>
							<option>All</option>
							<option>Pending</option>
							<option>Paid</option>
							<option>Overdue</option>
						</select>
					</div>
					<div>
						<label
							htmlFor="date"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by shipped date
						</label>
						<input
							id="date"
							onChange={(e) => filterBills(e, "shipped_date")}
							type={"date"}
							name="date"
							className="mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						/>
					</div>
					<div>
						<label
							htmlFor="date"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by due date
						</label>
						<input
							id="date"
							type={"date"}
							onChange={(e) => filterBills(e, "due_date")}
							name="date"
							className="mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						/>
					</div>
				</div>
				{!filteredBills || filteredBills?.length === 0 ? (
					<EmptyState title={"bill"} stateKey={"link-bills"} />
				) : (
					<table className="min-w-full divide-y divide-gray-200 mt-6">
						<thead className="bg-gray-50">
							<tr>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Status
								</th>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Due
								</th>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Number
								</th>

								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Vendor
								</th>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Amount
								</th>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Balance due
								</th>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Date
								</th>
								<th scope="col" className="relative px-6 py-3">
									<span className="sr-only">Edit</span>
								</th>
							</tr>
						</thead>
						<tbody className="bg-white divide-y divide-gray-200">
							{filteredBills?.map((bill, index) => {
								return (
									<tr key={index}>
										<td className="px-6 py-4 whitespace-nowrap">
											<span
												className="
                        px-2
                        inline-flex
                        text-xs
                        leading-5
                        font-semibold
                        rounded-full
                        bg-green-100
                        text-green-800
                      "
											>
												{toggleStatusColor(bill.status)}
											</span>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												{calculateDue(bill.due_date)}
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												BIL-00000{index + 1}
											</div>
										</td>

										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												{bill.vendor.name}
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												D{" "}
												{calculateBillPrice(
													bill.products
												)}
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-red-500 font-bold">
												D {calculateBalance(bill)}
											</div>
										</td>

										<td className="px-6 py-4 w-20 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												{new Date(
													bill.createdAt
												).toDateString()}
											</div>
										</td>

										<td
											className="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
										>
											<button
												className="text-indigo-600 hover:text-indigo-900"
												onClick={() => showBill(bill)}
											>
												View
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
			</section>
			<PreviewBill
				open={preview}
				setOpen={setPreview}
				bill={currentBill}
			/>
		</PaymentContextProvider>
	);
}
