import apiClient from './fetch';

export async function get() {
	try {
		const {data: transactions} = await apiClient.get("/transactions");
        return transactions.data;
	} catch (error) {
        throw error;
	}
}
