import { createContext, useState } from "react";

export const PaymentContext = createContext(null);

export default function PaymentContextProvider({children}) {
    const [ payment, setPayment ] = useState({});

    return (
        <PaymentContext.Provider value={{payment, setPayment}}>
            {children}
        </PaymentContext.Provider>
    )
}
