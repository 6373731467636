import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { get } from "../../utils/activities";

export default function PreviewEmployee({ open, setOpen, employee }) {
	const [role, setRole] = useState('none');
	const [activities, setActivities] = useState([]);

	const getLastLogin = async (id) => {
		const lastlogin = await get(id);
		setActivities(lastlogin);
	};

	const getDate = (activity) => {
		return new Date(activity.createdAt).toString();
	};

	useEffect(() => {
		if (employee) {
			getLastLogin(employee._id);
            if(employee.role) {
                setRole(employee.role.name)
            } else {
                setRole('none')
            }
		}
	}, [employee]);

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-500"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-500"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto w-screen max-w-xl">
									<div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
										<div className="px-4 sm:px-6">
											<div className="flex items-start justify-between">
												<Dialog.Title className="text-xl font-medium text-gray-900">
													{employee?.name}
												</Dialog.Title>
												<div className="ml-3 flex h-7 items-center">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
														onClick={() =>
															setOpen(!open)
														}
													>
														<span className="sr-only">
															Close panel
														</span>
														<XMarkIcon
															className="h-6 w-6"
															aria-hidden="true"
														/>
													</button>
												</div>
											</div>
										</div>
										<div className="overflow-hidden bg-white sm:rounded-lg">
											<div className="pl-6 pb-2 pt-6">
												<h3 className="text-md font-semibold">
													Employee Information
												</h3>
												<p className="text-sm ">
													Personal details and info.
												</p>
											</div>
											<div className="border-t border-gray-200">
												<dl>
													<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
														<dt className="text-sm font-medium text-gray-500">
															Address
														</dt>
														<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
															{employee?.address}
														</dd>
													</div>
													<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
														<dt className="text-sm font-medium text-gray-500">
															Phone
														</dt>
														<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
															{employee?.phone}
														</dd>
													</div>
													<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
														<dt className="text-sm font-medium text-gray-500">
															Email Address
														</dt>
														<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
															{employee?.email}
														</dd>
													</div>
													<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
														<dt className="text-sm font-medium text-gray-500">
															Role
														</dt>
														<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
															<select
																id="role"
																value={role}
																onChange={(e) =>
																	setRole(
																		e.target
																			.value
																	)
																}
																name="role"
																className={`block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm bg-white border`}
															>
																<option>
																	Select a
																	role
																</option>
																<option
																	value={
																		"store_manager"
																	}
																>
																	Store
																	Manager
																</option>
																<option
																	value={
																		"shop_manager"
																	}
																>
																	Shop Manager
																</option>
																<option
																	value={
																		"none"
																	}
																>
																	General
																	employee
																</option>
															</select>
														</dd>
													</div>
													{activities.map(
														(activity, index) => {
															return (
																<div
																	key={index}
																	className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
																>
																	<dt className="text-sm font-medium text-gray-500">
																		Last{" "}
																		{
																			activity.activityType
																		}
																	</dt>
																	<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
																		{getDate(
																			activity
																		)}
																	</dd>
																</div>
															);
														}
													)}
													{activities.length ===
														0 && (
														<>
															<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
																<dt className="text-sm font-medium text-gray-500">
																	Last Login
																</dt>
																<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
																	Never
																</dd>
															</div>
															<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
																<dt className="text-sm font-medium text-gray-500">
																	Last Logout
																</dt>
																<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
																	Never
																</dd>
															</div>
														</>
													)}
												</dl>
											</div>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
