import { Fragment, useContext, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Dialog, Transition } from "@headlessui/react";
import {
	XCircleIcon,
	XMarkIcon,
	ArrowPathIcon,
} from "@heroicons/react/24/outline";

import { UIContext } from "../../context/UIContext";
import Combobox from "../utils/Combobox";
import * as Products from "../../utils/products";
import * as Stores from "../../utils/store";
import * as Shops from "../../utils/shop";
import * as Transfer from "../../utils/transfers";
import Toggle from "../utils/Toggle";

export default function NewStocksComponent() {
	const queryClient = useQueryClient();
	const { data: allProducts } = useQuery("products", Products.get);
	const { data: stores } = useQuery("stores", Stores.get);
	const { data: shops } = useQuery("shops", Shops.get);
	const { slideOver, setSlideOver } = useContext(UIContext);
	const [mainProductsList, setMainProductsList] = useState([]);
	const [products, setProducts] = useState([]);
	const [product, setProduct] = useState({});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [storeMode, setStoreMode] = useState(false);
	const [toStore, setToStore] = useState(false);
	const [form, setForm] = useState({
		shop: "",
		store: "",
		source_shop: "",
		source_store: "",
	});

	useEffect(() => {
		if (product._id && !products.includes(product)) {
			setProducts((prevState) => {
				return [...prevState, product];
			});
		}
	}, [product]);

	useEffect(() => {
		if (allProducts) {
			setMainProductsList(allProducts);
		}
	}, [allProducts]);

	useEffect(() => {
		if (!storeMode) {
			console.log("Products from shop");
			const newProducts = allProducts?.filter(
				(p) => p.shop_id?._id === form.source_shop
			);
			setMainProductsList(newProducts);
		} else if (storeMode) {
			console.log("Products from store");
			const newProducts = allProducts?.filter(
				(p) => p.store_id?._id === form.source_store
			);
			setMainProductsList(newProducts);
		}
	}, [storeMode, form]);

	const handleQuantityChange = (e, id) => {
		const value = e.target.value;
		const newProducts = products.map((p) => {
			if (p._id === id) {
				p.quantity = value;
			}
			return p;
		});

		setProducts(newProducts);
	};

	const removeProduct = (e, id) => {
		e.preventDefault();
		const newProducts = products.filter((p) => p._id !== id);
		if (newProducts.length === 0) setProduct({});
		setProducts(newProducts);
	};

	const updateForm = (e) => {
		setForm((prevForm) => {
			return {
				...prevForm,
				[e.target.name]: e.target.value,
			};
		});
	};

	const makeTransfer = async (e) => {
		e.preventDefault();

		const payload = {
			products,
		};
		if (toStore) {
			payload.store_destination_id = form.store;
		} else {
			payload.shop_destination_id = form.shop;
		}

		const [success, _] = await Transfer.create(
			payload,
			setErrors,
			setLoading
		);
		if (success) {
			queryClient.invalidateQueries("transfers");
			setForm({
				shop: "",
				store: "",
				source_shop: "",
				source_store: "",
			});
			setProducts([]);
			setProduct({});
			setSlideOver((prevState) => {
				return {
					...prevState,
					showAddStockTransferPage: false,
				};
			});
		}
	};

	return (
		<Transition.Root
			show={slideOver.showAddStockTransferPage || false}
			as={Fragment}
		>
			<Dialog as="div" className="relative z-10" onClose={setSlideOver}>
				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-500"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-500"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto w-screen max-w-lg">
									<div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
										<div className="px-4 sm:px-6">
											<div className="flex items-start justify-between">
												<Dialog.Title className="text-xl font-medium text-gray-900">
													New Stock Transfer
												</Dialog.Title>
												<div className="ml-3 flex h-7 items-center">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
														onClick={() =>
															setSlideOver(
																(prevState) => {
																	return {
																		...prevState,
																		showAddStockTransferPage: false,
																	};
																}
															)
														}
													>
														<span className="sr-only">
															Close panel
														</span>
														<XMarkIcon
															className="h-6 w-6"
															aria-hidden="true"
														/>
													</button>
												</div>
											</div>
										</div>
										<div className="relative mt-6 flex-1 px-4 sm:px-6">
											<p className="text-sm">
												To start, all you need is a
												name, price, and file.
											</p>
											<div className="mt-6">
												<p className="block text-sm font-medium text-gray-700 pb-1">
													I am transfering from a
													store.
												</p>
												<Toggle
													enabled={storeMode}
													setEnabled={setStoreMode}
												/>
											</div>
											{storeMode ? (
												<>
													<div className="mt-5 sm:mt-6">
														<label
															htmlFor="store"
															className="block text-sm font-medium text-gray-700 pb-1"
														>
															Source Store
														</label>
														<select
															id="store"
															name="source_store"
															value={
																form.source_store
															}
															onChange={
																updateForm
															}
															className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm bg-white border"
														>
															<option>
																Select a store
															</option>
															{stores?.data.map(
																(
																	store,
																	index
																) => {
																	return (
																		<option
																			value={
																				store._id
																			}
																			key={
																				index
																			}
																		>
																			{
																				store.name
																			}
																		</option>
																	);
																}
															)}
														</select>
													</div>
												</>
											) : (
												<>
													<div className="mt-5 sm:mt-6">
														<label
															htmlFor="shop"
															className="block text-sm font-medium text-gray-700 pb-1"
														>
															Source Shop
														</label>
														<select
															id="shop"
															name="source_shop"
															value={
																form.source_shop
															}
															onChange={
																updateForm
															}
															className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm bg-white border"
														>
															<option>
																Select a shop
															</option>
															{shops?.data.map(
																(
																	shop,
																	index
																) => {
																	return (
																		<option
																			value={
																				shop._id
																			}
																			key={
																				index
																			}
																		>
																			{
																				shop.name
																			}
																		</option>
																	);
																}
															)}
														</select>
													</div>
												</>
											)}
											<div className="mt-6">
												<p className="block text-sm font-medium text-gray-700 pb-1">
													I am transfering to a store.
												</p>
												<Toggle
													enabled={toStore}
													setEnabled={setToStore}
												/>
											</div>

											{toStore ? (
												<div className="mt-5 sm:mt-6">
													<label
														htmlFor="store"
														className="block text-sm font-medium text-gray-700 pb-1"
													>
														Destination Store
													</label>
													<select
														id="store"
														name="store"
														value={form.store}
														onChange={updateForm}
														className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm bg-white border"
													>
														<option>
															Select a store
														</option>
														{stores?.data.map(
															(store, index) => {
																return (
																	<option
																		value={
																			store._id
																		}
																		key={
																			index
																		}
																	>
																		{
																			store.name
																		}
																	</option>
																);
															}
														)}
													</select>
												</div>
											) : (
												<div className="mt-5 sm:mt-6">
													<label
														htmlFor="shop"
														className="block text-sm font-medium text-gray-700 pb-1"
													>
														Destination Shop
													</label>
													<select
														id="shop"
														name="shop"
														value={form.shop}
														onChange={updateForm}
														className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm bg-white border"
													>
														<option>
															Select a shop
														</option>
														{shops?.data.map(
															(shop, index) => {
																return (
																	<option
																		value={
																			shop._id
																		}
																		key={
																			index
																		}
																	>
																		{
																			shop.name
																		}
																	</option>
																);
															}
														)}
													</select>
												</div>
											)}

											<form className="mt-5">
												<Combobox
													list={mainProductsList}
													title={"Enter product name"}
													selectedItem={product}
													setSelectedItem={setProduct}
												/>
												<p className="text-sm text-gray-500 mt-2">
													Type the product name you
													want to transfer and select.
												</p>
												{products.length > 0 ? (
													products.map((p) => {
														return (
															<div
																key={p._id}
																className="flex items-center justify-between mt-4 w-full"
															>
																<div className="text-sm w-2/3">
																	{p.name}{" "}
																	<b>
																		(GMD
																		{
																			p.selling_price
																		}
																	</b>
																	)
																</div>
																<div className="w-1/3 flex items-center">
																	<input
																		value={
																			p.quantity
																		}
																		className="border border-gray-300 p-1 rounded-md w-full"
																		name={
																			p._id
																		}
																		onChange={(
																			e
																		) =>
																			handleQuantityChange(
																				e,
																				p._id
																			)
																		}
																	/>
																	<button
																		onClick={(
																			e
																		) =>
																			removeProduct(
																				e,
																				p._id
																			)
																		}
																	>
																		<XCircleIcon
																			width={
																				20
																			}
																			height={
																				20
																			}
																			className="text-red-500 ml-2"
																		/>
																	</button>
																</div>
															</div>
														);
													})
												) : (
													<>
														<div className="text-center mt-12">
															<svg
																className="mx-auto h-12 w-12 text-gray-400"
																fill="none"
																viewBox="0 0 24 24"
																stroke="currentColor"
																aria-hidden="true"
															>
																<path
																	vectorEffect={
																		"non-scaling-stroke"
																	}
																	strokeLinecap={
																		"round"
																	}
																	strokeLinejoin={
																		"round"
																	}
																	strokeWidth={
																		2
																	}
																	d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
																/>
															</svg>
															<h3 className="mt-2 text-sm font-medium text-gray-900">
																No products
															</h3>
															<p className="mt-1 text-sm text-gray-500">
																Click on the
																button to add a
																product
															</p>
														</div>
													</>
												)}
												<div className="flex gap-4 mt-8">
													<button
														type="button"
														className="inline-flex items-center rounded-md justify-center border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-1/3"
													>
														Cancel
													</button>

													<button
														onClick={makeTransfer}
														type="button"
														className="inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm bg-[#2046cf] w-2/3"
													>
														Make transfer
														{loading && (
															<ArrowPathIcon
																height={23}
																width={23}
																className="animate-spin"
															/>
														)}
													</button>
												</div>
											</form>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
