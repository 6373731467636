import { formatPrice } from "../../utils/misc";

export default function InvoiceTemplate({ invoice }) {
	const calculateTotal = () => {
		let total = 0;
		invoice.products.forEach((product) => {
			total += product.selling_price * product.quantity;
		});

		return formatPrice(total);
	};
	const calculateBalance = () => {
		let total = 0;
		invoice.products.forEach((product) => {
			total += product.selling_price * product.quantity;
		});

		return formatPrice(total - invoice.totalPayments);
	};
	return (
		<div className="w-full">
			<div className="py-6 mb-6 border-b border-gray-300">
				<h3 className="text-lg font-medium">{invoice.business.business_name}</h3>
				<span className="block text-sm">{invoice.business.business_address}</span>
				<span className="block text-sm">{invoice.business.country}</span>
			</div>
			<div>
				<h1 className="text-2xl pb-1">Invoice</h1>
				<p className="text-sm">
					Invoice
					<span className="pl-2 font-bold">
						# {invoice.invoice_number}
					</span>
				</p>
			</div>
			<div className="flex justify-between mt-3">
				<div>
					<p className="font-bold text-sm">Bill To</p>
					<p className="font-bold text-sm">{invoice.customer.name}</p>
				</div>
				<div>
					<div className="flex items-center gap-4 mb-2 text-left w-58 justify-between">
						<span className="text-sm">Balance Due:</span>
						<span className="text-sm font-bold">
							D{calculateBalance()}
						</span>
					</div>
					<div className="flex items-center gap-4 mb-2 w-58 justify-between">
						<span className="text-sm">Shipped Date:</span>
						<span className="text-sm">
							{new Date(invoice.shipped_date).toDateString()}
						</span>
					</div>
					<div className="flex items-center gap-4 w-58 justify-between">
						<span className="text-sm">Due Date:</span>
						<span className="text-sm">
							{new Date(invoice.due_date).toDateString()}
						</span>
					</div>
				</div>
			</div>
			<table className="min-w-full divide-y divide-gray-200 mt-4">
				<thead className="bg-gray-50">
					<tr>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							#
						</th>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Name
						</th>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Quantity
						</th>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Price
						</th>
					</tr>
				</thead>
				<tbody className="bg-white divide-y divide-gray-200">
					{invoice.products.map((product, index) => {
						return (
							<tr key={index}>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										{index + 1}
									</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										{product.name}
									</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										{product.quantity}
									</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm font-bold text-gray-900">
										D{formatPrice(product.selling_price)}
									</div>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<div className="flex flex-col items-end mt-2">
				<div className="flex justify-between gap-6 border-t border-b border-gray-300 p-2 mb-2">
					<h2>Total</h2>
					<p>D{calculateTotal()}</p>
				</div>
			</div>
		</div>
	);
}
