import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";

import { classNames } from "../../utils/tailwindcss";
import { logout } from "../../utils/auth";
import { useUser } from "../../hooks/auth";

export default function AvatarContainer() {
    const navigate = useNavigate();
    const user = useUser();

    const logoutUser = async (e) => {
        e.preventDefault();
        const logoutStatus = await logout();
        if(logoutStatus) {
            navigate('/login');
        }
    }

	return (
		<Menu as="div" className="relative inline-block text-left w-5/6">
			<div>
				<Menu.Button className="top bg-[#e3ebfd] dark:bg-[#353542]">
					<div className="logo text-[#2f7fe2] dark:text-gray-400">
						<span className="material-symbols-outlined">
							urology
						</span>
						<p className="text-[#002b52] dark:text-gray-400">{user?.business_id?.business_name}</p>
					</div>
					<span className="material-symbols-outlined dark:text-gray-400">
						chevron_right
					</span>
				</Menu.Button>
			</div>

			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div className="py-1">
						<Menu.Item>
							{({ active }) => (
								<Link
									to={"/settings"}
									className={classNames(
										active
											? "bg-gray-100 text-gray-900"
											: "text-gray-700",
										"flex gap-1 items-center w-full text-left px-4 py-2 text-sm"
									)}
								>
									<span className="material-symbols-outlined">
										account_circle
									</span>
									Account settings
								</Link>
							)}
						</Menu.Item>
						<form method="POST" action="#">
							<Menu.Item>
								{({ active }) => (
									<button
                                        onClick={logoutUser}
										type="submit"
										className={classNames(
											active
												? "bg-gray-100 text-gray-900"
												: "text-gray-700",
											"flex items-center gap-1 w-full px-4 py-2 text-left text-sm"
										)}
									>
										<span className="material-symbols-outlined">
											logout
										</span>
										Sign out
									</button>
								)}
							</Menu.Item>
						</form>
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	);
}
