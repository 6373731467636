import { Fragment, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
	PrinterIcon,
	EnvelopeIcon,
	CurrencyDollarIcon,
	PencilSquareIcon,
	XMarkIcon,
} from "@heroicons/react/24/outline";

import ReceiptTemplate from "../templates/ReceiptTemplate";

export default function PreviewReceipt({ open, setOpen, sale }) {
	const receiptRef = useRef();

	const print = () => {
		receiptRef.current.classList.remove("shadow-xl");
		receiptRef.current.style.position = "absolute";
		receiptRef.current.style.left = 0;
		receiptRef.current.style.width = "100vw";

		window.print();
	};

	useEffect(() => {
		window.addEventListener("afterprint", () => {
			receiptRef.current.classList.add("shadow-xl");
			receiptRef.current.style.position = "relative";
			receiptRef.current.style.width = "100%";
		});

        return () => {
            window.removeEventListener('afterprint', () => console.log('Event listener removed'))
        }
	}, []);

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-500"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-500"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto w-screen max-w-3xl">
									<div
										className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl"
										ref={receiptRef}
									>
										<div className="px-4 sm:px-6">
											<div className="flex items-start justify-between">
												<Dialog.Title className="text-xl font-medium text-gray-900">
													SLR-000001
												</Dialog.Title>
												<div className="ml-3 flex h-7 items-center">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
														onClick={() =>
															setOpen(!open)
														}
													>
														<span className="sr-only">
															Close panel
														</span>
														<XMarkIcon
															className="h-6 w-6"
															aria-hidden="true"
														/>
													</button>
												</div>
											</div>
										</div>
										<div className="relative mt-2 flex-1 px-4 sm:px-6">
											<div className="mb-4 flex items-center gap-6">
												<button className="flex items-center">
													<EnvelopeIcon
														width={13}
														height={13}
													/>
													<span className="text-xs pl-1">
														Send Mail
													</span>
												</button>
												<button
													className="flex items-center"
													onClick={print}
												>
													<PrinterIcon
														width={13}
														height={13}
													/>
													<span className="text-xs pl-1">
														PDF/Print
													</span>
												</button>
											</div>
											<div className="flex justify-between">
                                                {/* Receipt Template */}
                                                <ReceiptTemplate sale={sale}/>
											</div>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
