import { useQuery } from "react-query";

import { get } from "../utils/orders";
import { formatPrice } from "../utils/misc";
import LoadingPulse from "../components/utils/LoadingPulse";
import { useState } from "react";
import PreviewOrder from "../components/previews/PreviewOrder";

export default function Orders() {
	const { isLoading, data: orders } = useQuery("orders", get);
	const [currentOrder, setCurrentOrder] = useState({});
	const [showOrder, setShowOrder] = useState(false);

	const calculateTotal = (products) => {
		let total = 0;
		products.forEach((product) => {
			total += product.selling_price * product.quantity;
		});

		return formatPrice(total);
	};

	const toggleOrder = (order) => {
		setCurrentOrder(order);
		setShowOrder(true);
	};

	const getStatus = (status) => {
		switch (status) {
			case "pending":
				return (
					<span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 capitalize">
						{status}
					</span>
				);
			case "canceled":
				return (
					<span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 capitalize">
						{status}
					</span>
				);
			case "completed":
				return (
					<span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 capitalize">
						{status}
					</span>
				);
		}
	};

	return (
		<>
			<section>
				<div className="mt-6 mb-3">
					<h1 className="greeting text-3xl">Orders</h1>
					<p className="pt-3">A list of all orders.</p>
				</div>
				<section>
					<table className="min-w-full divide-y divide-gray-200">
						<thead className="bg-gray-50">
							<tr>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Created Date
								</th>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Customer Name
								</th>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Product Count
								</th>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Total Price
								</th>

								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Status
								</th>
							</tr>
						</thead>
						<tbody className="bg-white divide-y divide-gray-200">
							{orders?.data.map((order, index) => {
								return (
									<tr key={index}>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												{new Date(
													order.createdAt
												).toDateString()}
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												{order.customer.name}
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900 flex items-center gap-2">
												{order.products.length}
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												D
												{calculateTotal(order.products)}
											</div>
										</td>
										<td className="px-6 py-4 w-20 whitespace-nowrap">
											{getStatus(order.status)}
										</td>
										<td
											className="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
										>
											<button
												className="text-indigo-600 hover:text-indigo-900"
												onClick={() =>
													toggleOrder(order)
												}
											>
												View
											</button>
										</td>
									</tr>
								);
							})}
							{isLoading && <LoadingPulse itemCount={7} />}
						</tbody>
					</table>
				</section>
			</section>
			<PreviewOrder
				open={showOrder}
				setOpen={setShowOrder}
				order={currentOrder}
			/>
		</>
	);
}
