export default function CustomerAccounts() {
	return (
		<>
			<form className="flex items-center gap-6">
				<div className="flex items-center gap-5">
					<input
						type={"date"}
						className="border border-gray-400 p-2 rounded w-48"
						defaultValue={"2023-03-03"}
					/>
					<input
						type={"date"}
						className="border border-gray-400 p-2 rounded w-48"
						defaultValue={"2023-03-03"}
					/>
				</div>
				<button className="select flex items-center text-white p-3 px-4 rounded-3xl bg-[#2046cf]">
					<span className="text-sm">Update report</span>
				</button>
			</form>
			<table className="min-w-full divide-y divide-gray-200 mt-8">
				<thead className="bg-gray-50">
					<tr>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Status
						</th>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Invoice Date
						</th>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Due Date
						</th>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Invoice #
						</th>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Customer Name
						</th>

						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Total
						</th>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Balance
						</th>
					</tr>
				</thead>
				<tbody className="bg-white divide-y divide-gray-200">
					{[].map((product, index) => {
						return (
							<tr key={index}>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										{product.name}
									</div>
								</td>
								{/*
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="text-sm text-gray-900">
											PRD-000001
										</div>
									</td>
                                    */}
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										{product.quantity}
									</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										{product.available_for_Sale}
									</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										{product.unit}
									</div>
								</td>

								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										D{product.cost_price}
									</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										D{product.selling_price}
									</div>
								</td>

								<td className="px-6 py-4 w-20 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										{new Date(
											product.createdAt
										).toDateString()}
									</div>
								</td>
								{/*
									<td className="px-6 py-4 whitespace-nowrap">
										<span
											className="
                        px-2
                        inline-flex
                        text-xs
                        leading-5
                        font-semibold
                        rounded-full
                        bg-green-100
                        text-green-800
                      "
										>
											True
										</span>
									</td>
                                    */}
								<td
									className="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
								>
									<button className="text-indigo-600 hover:text-indigo-900">
										View
									</button>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
}
