import { Fragment, useContext, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon, ArrowPathIcon } from "@heroicons/react/24/outline";

import { UIContext } from "../../context/UIContext";
import { toggleErrorBorder } from "../../utils/errors";
import { create } from "../../utils/customers";
import { useQueryClient } from "react-query";

export default function NewCustomer() {
    const queryClient = useQueryClient();
	const { slideOver, setSlideOver } = useContext(UIContext);
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({
		name: "",
		phone: "",
		email: "",
		location: "",
	});

	const updateForm = (e) => {
		setForm((prevForm) => {
			return {
				...prevForm,
				[e.target.name]: e.target.value,
			};
		});
	};

	const addCustomer = async (e) => {
		e.preventDefault();

		const [success, data] = await create(form, setErrors, setLoading);
		if (success) {
            queryClient.invalidateQueries('customers');
            console.log(data)
			setSlideOver((prevState) => {
				return {
					...prevState,
					showAddCustomerPage: false,
				};
			});
		}
	};

	return (
		<Transition.Root
			show={slideOver.showAddCustomerPage || false}
			as={Fragment}
		>
			<Dialog as="div" className="relative z-10" onClose={setSlideOver}>
				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-500"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-500"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-20" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden z-20">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto w-screen max-w-lg z-20">
									<div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
										<div className="px-4 sm:px-6">
											<div className="flex items-start justify-between">
												<Dialog.Title className="text-xl font-medium text-gray-900">
													New Customer
												</Dialog.Title>
												<div className="ml-3 flex h-7 items-center">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
														onClick={() =>
															setSlideOver(
																(prevState) => {
																	return {
																		...prevState,
																		showAddCustomerPage: false,
																	};
																}
															)
														}
													>
														<span className="sr-only">
															Close panel
														</span>
														<XMarkIcon
															className="h-6 w-6"
															aria-hidden="true"
														/>
													</button>
												</div>
											</div>
										</div>
										<div className="relative mt-6 flex-1 px-4 sm:px-6">
											<p className="text-sm">
												To start, all you need is a
												name, phone/email, and customer
												type.
											</p>
											<form className="mt-5">
												<div className="mb-8">
													<label
														htmlFor="name"
														className="block text-sm font-medium text-gray-700"
													>
														Name
														<span className="text-red-700">
															*
														</span>
													</label>
													<div className="mt-2 mb-2">
														<input
															type="text"
															name="name"
															value={form.name}
															onChange={
																updateForm
															}
															id="name"
															className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
																errors,
																"name"
															)}`}
														/>
													</div>
												</div>
												<div className="mb-8">
													<label className="text-sm font-medium text-gray-700">
														Customer type
														<span className="text-red-700">
															*
														</span>
													</label>
													<fieldset className="mt-1">
														<legend className="sr-only">
															Customer type
														</legend>
														<div className="sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
															<div className="flex items-center">
																<input
																	name="individual"
																	type="radio"
																	defaultChecked={
																		true
																	}
																	className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
																/>
																<label
																	htmlFor={
																		"individual"
																	}
																	className="ml-3 block text-sm font-medium leading-6 text-gray-900"
																>
																	Individual
																</label>
															</div>
															<div className="flex items-center">
																<input
																	name="company"
																	type="radio"
																	className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
																/>
																<label
																	htmlFor={
																		"company"
																	}
																	className="ml-3 block text-sm font-medium leading-6 text-gray-900"
																>
																	Company
																</label>
															</div>
														</div>
													</fieldset>
												</div>
												<div className="mb-8">
													<label
														htmlFor="email"
														className="block text-sm font-medium text-gray-700"
													>
														Email
													</label>
													<div className="mt-2 mb-2">
														<input
															type="email"
															value={form.email}
															onChange={
																updateForm
															}
															name="email"
															id="email"
															className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
																errors,
																"email"
															)}`}
														/>
													</div>
												</div>
												<div className="mb-8">
													<label
														htmlFor="phone"
														className="block text-sm font-medium text-gray-700"
													>
														Phone
													</label>
													<div className="mt-2 mb-2">
														<input
															type="text"
															name="phone"
															value={form.phone}
															onChange={
																updateForm
															}
															id="phone"
															className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
																errors,
																"phone"
															)}`}
														/>
													</div>
												</div>
												<div className="mb-8">
													<label
														htmlFor="location"
														className="block text-sm font-medium text-gray-700"
													>
														Address
													</label>
													<div className="mt-2 mb-2">
														<input
															type="text"
                                                            value={form.location}
                                                            onChange={updateForm}
															name="location"
															id="location"
															className={`block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${toggleErrorBorder(
																errors,
																"location"
															)}`}
														/>
													</div>
													<p className="text-sm text-gray-500">
														Say a few words about
														this customer.
														(optional)
													</p>
												</div>
												<div className="flex gap-4 mt-8">
													<button
														type="button"
														className="inline-flex items-center rounded-md justify-center border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-1/3"
													>
														Cancel
													</button>
													<button
														onClick={addCustomer}
														type="button"
														className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 w-2/3"
													>
														Add customer
														{loading && (
															<ArrowPathIcon
																height={23}
																width={23}
																className="animate-spin"
															/>
														)}
													</button>
												</div>
											</form>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
