import apiClient from "./fetch";

export async function get() {
	try {
		const { data: products } = await apiClient.get("/products");
		return products.data;
	} catch (error) {
		throw error;
	}
}

export async function add(form, setErrors, setLoading) {
	setLoading(true);
	let success = false;

	try {
		const product = await apiClient.post("/products/add", form, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		success = true;
		setLoading(false);

		return [success, product];
	} catch (error) {
		if (error.response.status < 500) {
			console.log(error.response.data);
			if (typeof error.response.data.error === "string") {
				setErrors({
					type: "credentials",
					info: error.response.data.error,
				});
			} else {
				setErrors({
					type: "validation",
					info: error.response.data.error.details,
				});
			}
		}

		success = false;
		setLoading(false);

		return [success, error];
	}
}

export async function edit(form, id, setErrors, setLoading) {
	setLoading(true);
	let success = false;

	try {
		const { data: product } = await apiClient.patch(
			`/products/product/${id}`,
			form,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}
		);
		success = true;
		setLoading(false);

		return [success, product.data];
	} catch (error) {
		console.log(error);
		success = false;
		setLoading(false);

		return [success, error];
	}
}

export async function remove(id, setLoading) {
	setLoading(true);
	let success = false;

	try {
		const { data } = await apiClient.delete(`/products/product/${id}`);

		success = true;
		setLoading(false);

		return [success, data];
	} catch (error) {
		success = false;
		setLoading(false);

		return [success, error];
	}
}
