import { createContext, useState } from "react";

export const InvoiceContext = createContext(null);

export default function InvoiceContextProvider({ children }) {
	const [invoice, setInvoice] = useState({});

	return (
		<InvoiceContext.Provider value={{ invoice, setInvoice }}>
			{children}
		</InvoiceContext.Provider>
	);
}
