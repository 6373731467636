const months = [
	`Jan`,
	`Feb`,
	`Mar`,
	`Apr`,
	`May`,
	`Jun`,
	`Jul`,
	`Aug`,
	`Sep`,
	`Oct`,
	`Nov`,
	`Dec`,
];
export const formatCashflow = (rawCashflow) => {
	let income = [];
	let outgoing = [];
	let netchange = [];
    let categories = [];
	if (rawCashflow[0]) {
		const rawCashflowArray = [...rawCashflow]
		const currentMonth = new Date().getMonth() + 1;

		const orderedCashflowArray = [
			...rawCashflowArray.slice(0, currentMonth).reverse(),
			...rawCashflowArray.slice(currentMonth),
		].reverse();

		orderedCashflowArray.forEach((rawCashflowItem) => {
			income.push(rawCashflowItem.income);
			outgoing.push(rawCashflowItem.outgoing);
			netchange.push(rawCashflowItem.income - rawCashflowItem.outgoing);
            categories.push(months[rawCashflowItem.month - 1] + ` ${new Date().getDate()}`);

		});
	} else {
		income = new Array(12).fill(0);
		outgoing = new Array(12).fill(0);
		netchange = new Array(12).fill(0);
	}

	return { income, outgoing, netchange, categories };
};

export const getMonthArrangement = () => {
	const today = new Date().getDate();
	return [];
};
