import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import AvatarContainer from "./utils/AvatarContainer";
import { Link, useHref } from "react-router-dom";

import { navigation } from "../lib/constants";
import { classNames } from "../utils/tailwindcss";

export default function SideNavigation() {
	const href = useHref();

	const toggleActive = (item) => {
		if (item.id === "dashboard" && href === "/") {
			return "bg-[#e3ebfd] dark:bg-[#353542]";
		} else if (
			item.id !== "dashboard" && href !== '/' &&
			item.id.includes(href.substring(1))
		) {
			return "bg-[#e3ebfd] dark:bg-[#353542]";
		} else {
			return "hover:bg-gray-50";
		}
	};

	return (
		<div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 dark:border-gray-700 bg-white px-3 h-screen w-full bg-[#f0fcff] dark:bg-[#2a2a33]">
			<div className="side-navigation pt-5">
				<AvatarContainer />
			</div>
			<nav className="flex flex-1 flex-col">
				<ul className="flex flex-1 flex-col gap-y-7">
					<li>
						<ul role="list" className="-mx-2 space-y-1">
							{navigation.map((item) => (
								<li key={item.name}>
									{!item.children ? (
										<Link
											to={item.href}
											className={classNames(
												toggleActive(item),
												"group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-700 dark:text-gray-400 dark:font-normal"
											)}
										>
											{item.icon}
											{item.name}
										</Link>
									) : (
										<Disclosure as="div">
											{({ open }) => (
												<>
													<Disclosure.Button
														className={classNames(
															item.current
																? "bg-gray-50"
																: "hover:bg-gray-50",
															"flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700 dark:text-gray-400 dark:font-normal"
														)}
													>
														{item.icon}
														{item.name}
														<ChevronRightIcon
															className={classNames(
																open
																	? "rotate-90 text-gray-500"
																	: "text-gray-400",
																"ml-auto h-5 w-5 shrink-0"
															)}
															aria-hidden="true"
														/>
													</Disclosure.Button>
													<Disclosure.Panel
														as="ul"
														className="mt-1 px-2"
													>
														{item.children.map(
															(subItem) => (
																<li
																	key={
																		subItem.name
																	}
																>
																	{/* 44px */}
																	<Disclosure.Button>
																		<Link
																			to={
																				subItem.href
																			}
																			className={classNames(
																				subItem.current
																					? "bg-gray-50"
																					: "hover:bg-gray-50",
																				"block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700"
																			)}
																		>
																			{
																				subItem.name
																			}
																		</Link>
																	</Disclosure.Button>
																</li>
															)
														)}
													</Disclosure.Panel>
												</>
											)}
										</Disclosure>
									)}
								</li>
							))}
						</ul>
					</li>
				</ul>
			</nav>
		</div>
	);
}
