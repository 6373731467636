import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { formatPrice } from "../../utils/misc";

export default function PreviewOrder({ open, setOpen, order }) {
	const getStatus = (status) => {
		switch (status) {
			case "pending":
				return (
					<span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 capitalize">
						{status}
					</span>
				);
			case "canceled":
				return (
					<span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 capitalize">
						{status}
					</span>
				);
			case "completed":
				return (
					<span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 capitalize">
						{status}
					</span>
				);
		}
	};

	const totalPrice = (products) => {
		let total = 0;
		if (products) {
			products.forEach((product) => {
				total += product.quantity * product.selling_price;
			});
		}

		return formatPrice(total);
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-500"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-500"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto w-screen max-w-3xl">
									<div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
										<div className="px-4 sm:px-6">
											<div className="flex items-start justify-between">
												<Dialog.Title className="text-xl font-medium text-gray-900">
													Customer Order
												</Dialog.Title>
												<div className="ml-3 flex h-7 items-center">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
														onClick={() =>
															setOpen(false)
														}
													>
														<span className="sr-only">
															Close panel
														</span>
														<XMarkIcon
															className="h-6 w-6"
															aria-hidden="true"
														/>
													</button>
												</div>
											</div>
										</div>
										<div className="relative mt-2 flex-1 px-4 sm:px-6">
											<div className="mb-4 flex items-center gap-6 hidden">
												<button className="flex items-center">
													<TrashIcon
														width={13}
														height={13}
													/>
													<span className="text-xs pl-1">
														Delete
													</span>
												</button>
											</div>
											<div className="flex justify-between">
												<div className="flex justify-between">
													<div className="overflow-hidden bg-white sm:rounded-lg">
														<div className="border-t border-gray-200">
															<dl>
																<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
																	<dt className="text-sm font-medium text-gray-500">
																		Customer
																	</dt>
																	<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
																		{
																			order
																				?.customer
																				?.name
																		}
																	</dd>
																</div>
																<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
																	<dt className="text-sm font-medium text-gray-500">
																		Customer
																		Email
																	</dt>
																	<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
																		{
																			order
																				?.customer
																				?.email
																		}
																	</dd>
																</div>
																<div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
																	<dt className="text-sm font-medium text-gray-500">
																		Status
																	</dt>
																	<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 capitalise">
																		{getStatus(
																			order.status
																		)}
																	</dd>
																</div>
																<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
																	<dt className="text-sm font-medium text-gray-500">
																		Total
																		products
																	</dt>
																	<dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
																		{
																			order
																				?.products
																				?.length
																		}
																	</dd>
																</div>
																<table className="min-w-full divide-y divide-gray-200 w-full">
																	<thead className="bg-gray-50">
																		<tr>
																			<th
																				scope="col"
																				className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
																			>
																				Name
																			</th>
																			<th
																				scope="col"
																				className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
																			>
																				Quantity
																			</th>
																			<th
																				scope="col"
																				className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
																			>
																				Price
																			</th>
																		</tr>
																	</thead>
																	<tbody className="bg-white divide-y divide-gray-200">
																		{order?.products?.map(
																			(
																				p
																			) => {
																				return (
																					<tr
																						key={
																							p._id
																						}
																					>
																						<td className="px-6 py-4 whitespace-nowrap">
																							<div className="text-sm text-gray-900">
																								{
																									p.name
																								}
																							</div>
																						</td>
																						<td className="px-6 py-4 whitespace-nowrap">
																							<div className="text-sm text-gray-900">
																								<input
																									disabled
																									value={
																										p.quantity
																									}
																									className="rounded-md w-16"
																								/>
																							</div>
																						</td>
																						<td className="px-6 py-4 whitespace-nowrap">
																							<div className="text-sm font-bold text-gray-900">
																								GMD{" "}
																								<input
																									value={
																										p.selling_price
																									}
																									disabled
																									className="rounded-md w-16"
																								/>
																							</div>
																						</td>
																					</tr>
																				);
																			}
																		)}
																	</tbody>
																</table>
																<div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 mt-6">
																	<dt className="text-sm font-bold text-gray-500">
																		Total
																		Price
																	</dt>
																	<dd className="mt-1 text-sm text-gray-900 font-bold sm:col-span-2 sm:mt-0">
																		{totalPrice(
																			order?.products
																		)}
																	</dd>
																</div>
															</dl>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
