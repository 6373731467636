import apiClient from "./fetch";

export async function create(storeMode, form, setErrors, setLoading) {
	setLoading(true);
    let success = false;
	try {
        const endpoint = storeMode ? '/stores/create' : '/shops/create';
		const store = await apiClient.post(endpoint, form);
        success = true;
		setLoading(false);
		return store;
	} catch (error) {
		if (error.response.status < 400) {
			console.log(error.response.data);
			setErrors(error.response.data.error);
		}
		setLoading(false);
		return success;
	}
}

export async function get() {
	try {
		const { data: stores } = await apiClient.get('/stores');
        return stores
	} catch (error) {
		console.log(error);
	}
}

export async function deleteStore(id) {
    let success = false;

    try {
        const { data } = await apiClient.delete(`/stores/${id}`);
        return data;
    } catch(error) {
        return success;
    }
}
