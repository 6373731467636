import { useState } from "react";
import { useQuery } from "react-query";
import { MagnifyingGlassIcon, FunnelIcon } from "@heroicons/react/24/outline";

import { get } from "../../utils/transactions";
import { formatPrice, getTransactionType } from "../../utils/misc";

export default function Transactions() {
	const { data: transactions } = useQuery("transactions", get);
	const [showFilter, setShowFilter] = useState(false);

	const getID = (transaction) => {
		if (transaction.invoice_id) {
			return transaction.invoice_id.invoice_number;
		} else if (transaction.sales_id) {
			return transaction.sales_id.number;
		} else {
			return "N/A";
		}
	};

	return (
		<>
			<section>
				<div className="flex justify-between items-center mt-6">
					<div>
						<h1 className="greeting text-3xl">Transactions</h1>
						<p className="pt-3">A list of all transactions.</p>
					</div>
					<div className="flex items-center gap-4">
						<form className="flex items-center">
							<div className="relative mt-1 rounded-md shadow-sm w-72">
								<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
									<MagnifyingGlassIcon
										className="h-5 w-5 text-gray-400"
										aria-hidden="true"
									/>
								</div>
								<input
									type="text"
									name="filter"
									id="filter"
									className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm outline-none"
									placeholder="Search or filter results"
								/>
							</div>
							<button
								className="flex items-center justify-center pl-3"
								onClick={(e) => {
									e.preventDefault();
									setShowFilter(!showFilter);
								}}
							>
								<FunnelIcon
									className="h-6 w-6"
									aria-hidden="true"
								/>
							</button>
						</form>
					</div>
				</div>
				<div
					className={`grid-cols-3 mt-3 gap-4 ${
						showFilter ? "grid" : "hidden"
					}`}
				>
					<div>
						<label
							htmlFor="branch"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by type
						</label>
						<select
							id="branch"
							name="branch"
							className="bg-white border mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						>
							<option>Invoice</option>
							<option>Customer Payment</option>
							<option>Bill</option>
							<option>Vendor Payment</option>
							<option>Sale</option>
							<option>Expense</option>
						</select>
					</div>
					<div>
						<label
							htmlFor="date"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by date
						</label>
						<input
							id="date"
							type={"date"}
							name="date"
							className="border mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						/>
					</div>
				</div>
			</section>
			<section className="mt-2">
				<table className="min-w-full divide-y divide-gray-200">
					<thead className="bg-gray-50">
						<tr>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Date
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Type
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Account
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Type ID
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Amount
							</th>
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						{transactions?.map((transaction, index) => {
							return (
								<tr key={index}>
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="flex items-center">
											<div className="ml-4">
												<div className="text-sm font-medium text-gray-900">
													{new Date(
														transaction.createdAt
													).toDateString()}
												</div>
												<div className="text-sm text-gray-500">
													TRA-00000{index + 1}
												</div>
											</div>
										</div>
									</td>
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="flex items-center">
											<div>
												{getTransactionType(
													transaction.transactionType
												)}
											</div>
										</div>
									</td>
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="text-sm text-gray-900">
											{transaction.account_id?.name ||
												"C$SH"}
										</div>
									</td>
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="flex items-center">
											<div className="ml-4">
												<div className="text-sm text-gray-500">
													{getID(transaction)}
												</div>
											</div>
										</div>
									</td>
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="text-sm text-gray-900">
											D{formatPrice(transaction.amount)}
										</div>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</section>
		</>
	);
}
