import { useState } from "react";
import { profitLoss } from "../../../utils/reports";
import { formatPrice } from "../../../utils/misc";

export default function ProfitLoss() {
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({
		startDate: "",
		endDate: "",
	});
	const [profitLossData, setProfitLossData] = useState({
		income: 0,
		costOfGoods: 0,
		grossProfit: 0,
		operatingCost: 0,
		netProfit: 0,
	});
	const fetchReport = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (Object.keys(form).length === 2) {
			try {
				const { data } = await profitLoss({ ...form });
                if(data.length === 0) return
				setProfitLossData((prevState) => {
					return {
						...prevState,
						income: data[0].income,
						operatingCost: data[0].expenses,
					};
				});
			} catch (error) {
				console.log("some error occured");
			} finally {
				setLoading(false);
			}
		}
	};

	const updateForm = (event) => {
		setForm((prevState) => {
			return {
				...prevState,
				[event.target.name]: event.target.value,
			};
		});
	};

	return (
		<>
			<form className="flex items-center gap-6">
				<div className="flex items-center gap-5">
					<input
						type={"date"}
						className="border border-gray-400 p-2 rounded w-48"
						value={form.startDate}
						onChange={updateForm}
						name="startDate"
					/>
					<input
						type={"date"}
						className="border border-gray-400 p-2 rounded w-48"
						value={form.endDate}
						onChange={updateForm}
						name="endDate"
					/>
				</div>
				<button
					className="select flex items-center text-white p-3 px-4 rounded-3xl bg-[#2046cf]"
					disabled={loading}
					onClick={fetchReport}
				>
					<span className="text-sm">Update report</span>
				</button>
			</form>
			<div className="mt-12">
				<div className="flex items-end justify-between border-b border-gray-400 pb-2 px-2">
					<span className="uppercase font-bold text-sm">
						Accounts
					</span>
					<span className="font-bold text-sm w-32">
						{new Date(form.startDate).toDateString()}
						<br />
						to <br />
						{new Date(form.endDate).toDateString()}
					</span>
				</div>
				<div>
					<div className="flex items-center justify-between border-b border-gray-300 p-2">
						<span>Income</span>
						<span>D{formatPrice(profitLossData.income)}</span>
					</div>
					<div className="flex items-center justify-between border-b border-gray-300 p-2">
						<span>Cost of Goods Sold</span>
						<span>D0.00</span>
					</div>
					<div className="flex items-center justify-between border-b border-gray-300 py-2 bg-gray-100 px-2">
						<div className="flex flex-col">
							<span className="font-bold">Gross Profit</span>
							<span className="text-xs text-gray-500">
								As a percentage of Total Income
							</span>
						</div>
						<div className="flex flex-col">
							<span className="font-bold">
								D
								{formatPrice(
									profitLossData.income -
										profitLossData.costOfGoods
								)}
							</span>
							<span className="text-xs text-gray-500">
								{formatPrice(
									((profitLossData.income -
										profitLossData.costOfGoods) /
										profitLossData.income) *
										100
								)}
								%
							</span>
						</div>
					</div>
					<div className="flex items-center justify-between border-b border-gray-300 p-2">
						<span>Operating Expenses</span>
						<span>
							D{formatPrice(profitLossData.operatingCost)}
						</span>
					</div>
					<div className="flex items-center justify-between border-b border-gray-300 py-2 bg-gray-100 px-2">
						<div className="flex flex-col">
							<span className="font-bold">Net Profit</span>
							<span className="text-xs text-gray-500">
								As a percentage of Total Income
							</span>
						</div>
						<div className="flex flex-col">
							<span className="font-bold">
								D
								{formatPrice(
									profitLossData.income -
										profitLossData.operatingCost
								)}
							</span>
							<span className="text-xs text-gray-500">
								{formatPrice(
									((profitLossData.income -
										profitLossData.operatingCost) /
										profitLossData.income) *
										100
								)}
								%
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
