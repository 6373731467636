import apiClient from "./fetch";

export async function create(form, setErrors, setLoading) {
	setLoading(true);
	let success = false;
	try {
		const vendor = await apiClient.post("/vendors/add", form);
		success = true;
		setLoading(false);
		return [success, vendor];
	} catch (error) {
		if (error.response.status < 500) {
			if (typeof error.response.data.error === "string") {
				setErrors({
					type: "credentials",
					info: error.response.data.error,
				});
			} else {
				setErrors({
					type: "validation",
					info: error.response.data.error.details,
				});
			}
		}
		success = false;
		setLoading(false);
		return [success, error];
	}
}

export async function get() {
	try {
		const { data: vendors } = await apiClient.get("/vendors");
		return vendors.data;
	} catch (error) {
		throw error;
	}
}

export async function deleteVendor(id) {
	let success = false;
	try {
		const { data } = await apiClient.delete(`/vendors/vendor/${id}`);
		success = true;
		return [success, data];
	} catch (error) {
		return [success, error];
	}
}
