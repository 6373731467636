import { useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";

import { classNames } from "../../utils/tailwindcss";

export default function DateRange({
	list = [],
	selectedItem,
	setSelectedItem,
}) {
	const [query, setQuery] = useState("");

	const filteredList =
		query === ""
			? list
			: list.filter((item) => {
					return item.name
						.toLowerCase()
						.includes(query.toLowerCase());
			  });

	return (
		<Combobox as="div" value={selectedItem} onChange={setSelectedItem}>
			<div className="relative mt-1 mr-6">
				<Combobox.Input
					className="w-full rounded-full border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
					onChange={(event) => setQuery(event.target.value)}
					displayValue={(item) => item?.name}
				/>
				<Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
					<ChevronUpDownIcon
						className="h-5 w-5 text-gray-400"
						aria-hidden="true"
					/>
				</Combobox.Button>

				{filteredList.length > 0 && (
					<Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
						{filteredList.map((item, index) => (
							<Combobox.Option
								key={index}
								value={item}
								className={({ active }) =>
									classNames(
										"relative cursor-default select-none py-2 pl-8 pr-4",
										active
											? "bg-indigo-600 text-white"
											: "text-gray-900"
									)
								}
							>
								{({ active, selected }) => (
									<>
										<span
											className={classNames(
												"block truncate",
												selected && "font-semibold"
											)}
										>
											{item.name || item.invoice_number}
										</span>

										{selected && (
											<span
												className={classNames(
													"absolute inset-y-0 left-0 flex items-center pl-1.5",
													active
														? "text-white"
														: "text-indigo-600"
												)}
											>
												<CheckIcon
													className="h-5 w-5"
													aria-hidden="true"
												/>
											</span>
										)}
									</>
								)}
							</Combobox.Option>
						))}
					</Combobox.Options>
				)}
			</div>
		</Combobox>
	);
}
