import { useContext } from "react";
import { useQuery } from "react-query";

import Pie from "../components/charts/Pie";
import Cashflow from "../components/charts/Cashflow";
import ProfitLoss from "../components/charts/ProfitLoss";

import * as Dashboard from "../utils/dashboard";
import { formatPrice, moneyize } from "../utils/misc";
import { MainContext } from "../context/MainContext";

export default function Main({ user }) {
	const { data: totalSales } = useQuery(
		"totalsales",
		Dashboard.getTotalSales
	);
	const { data: cashflow } = useQuery("cashflow", Dashboard.cashflow);
	const { data: profitloss } = useQuery("profitloss", Dashboard.profitLoss);
	const { dateRange } = useContext(MainContext);
	return (
		<section className="main">
			<h1 className="greeting">Good Evening, {user.name}!</h1>
			<p className="subtitle">
				Here’s what's happening with your account today
			</p>
			<div className="top-dash justify-between">
				<div className="sales">
					<div className="sales-in">
						<div>
							<span className="material-symbols-outlined">
								monetization_on
							</span>
							<div className="sales-top">
								<h3>Revenue</h3>
								<span className="material-symbols-outlined">
									help
								</span>
							</div>
							<span>{dateRange.name}</span>
						</div>
						<div className="sales-nums">
							<h1>
								D
								{formatPrice(
									moneyize(
										totalSales &&
											totalSales.length > 0 &&
											totalSales[0]?.totalSales +
												totalSales[0]?.totalPayments
									)
								)}
							</h1>
							<p className="line-through">
								up 0% vs 6 months before
							</p>
						</div>
					</div>
				</div>
				<div className="sales">
					<div className="sales-in">
						<div>
							<span className="material-symbols-outlined">
								point_of_sale
							</span>
							<div className="sales-top">
								<h3>Total Sales</h3>
								<span className="material-symbols-outlined">
									help
								</span>
							</div>
							<span>{dateRange.name}</span>
						</div>
						<div className="sales-nums">
							<h1>
								D
								{formatPrice(
									moneyize(
										totalSales &&
											totalSales.length > 0 &&
											totalSales[0]?.totalSales
									)
								)}
							</h1>
							<p className="line-through">
								up 0% vs 6 months before
							</p>
						</div>
					</div>
				</div>
				<div className="sales">
					<div className="sales-in">
						<div>
							<span className="material-symbols-outlined">
								feed
							</span>
							<div className="sales-top">
								<h3>Invoices</h3>
								<span className="material-symbols-outlined">
									help
								</span>
							</div>
							<span>{dateRange.name}</span>
						</div>
						<div className="sales-nums">
							<h1>
								D
								{formatPrice(
									moneyize(
										totalSales &&
											totalSales.length > 0 &&
											totalSales[0].totalInvoices
									)
								)}
							</h1>
							<p className="line-through">
								up 0% vs 6 months before
							</p>
						</div>
					</div>
				</div>
				<div className="sales">
					<div className="sales-in">
						<div>
							<span className="material-symbols-outlined">
								payments
							</span>
							<div className="sales-top">
								<h3>Bills</h3>
								<span className="material-symbols-outlined">
									help
								</span>
							</div>
							<span>{dateRange.name}</span>
						</div>
						<div className="sales-nums">
							<h1>
								D
								{formatPrice(
									moneyize(
										totalSales &&
											totalSales.length > 0 &&
											totalSales[0]?.totalBills
									)
								)}
							</h1>
							<p className="line-through">
								up 0% vs 6 months before
							</p>
						</div>
					</div>
				</div>
			</div>
			<section className="charts">
				<div className="top-charts">
					<div>
						<h3>Cashflow analytics</h3>
						<p>Cash coming in and going out of your business.</p>
					</div>
					<span>View Report</span>
				</div>
				<Cashflow cashflow={cashflow} />
				<div className="top-charts-profit">
					<div>
						<h3>Profit & Loss</h3>
						<p>
							Income and expenses including unpaid invoices and
							bills.
						</p>
					</div>
					<span>View Report</span>
				</div>
				<div className="sub-charts">
					<ProfitLoss profitloss={profitloss} />
					<Pie />
				</div>
			</section>
		</section>
	);
}
