export const handleNavigate = (e, state, setSlideOver) => {
	e.preventDefault();
	setSlideOver((prevState) => {
		return {
			...prevState,
			[state]: true,
		};
	});
};

export const toggleStatusColor = (status) => {
	let background = "bg-green-100";
	let color = "text-green-800";
	if (status === "pending") {
		background = "bg-yellow-100";
		color = "text-yellow-800";
		// do something
	} else if (status === "due") {
		background = "bg-red-100";
		color = "text-red-800";
		// do something
	}

	return (
		<span
			className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${background} ${color} capitalize`}
		>
			{status}
		</span>
	);
};

export const notAdmin = (user) => {
	return user?.role?.name !== "business_admin";
};

export const makeIcon = (name) => {
	return <span className="material-symbols-outlined">{name}</span>;
};

export const moneyize = (amount) => {
	return amount ? amount.toFixed(2) : "0.00";
};

export function formatPrice(value) {
	let val = (value / 1).toFixed(2).replace(",", ".");
	return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function calculateDue(date) {
	const today = new Date();
	const dateAsDate = new Date(date);
	const daysPassed = Math.round((today - dateAsDate) / (1000 * 60 * 60 * 24));

	return daysPassed <= 0 ? "0 days ago" : `${daysPassed} days ago`;
}

export const getTransactionType = (transactionType) => {
	if (transactionType.toLowerCase() === "payment") {
		return (
			<>
				<div className="text-sm font-medium text-gray-900">Income</div>
				<div className="text-sm text-gray-500 flex items-center pt-1 gap-1">
					<div className="w-3 h-3 rounded-full bg-green-400" />
					Customer Payment
				</div>
			</>
		);
	} else if (transactionType.toLowerCase() === "bill") {
		return (
			<>
				<div className="text-sm font-medium text-gray-900">Outcome</div>
				<div className="text-sm text-gray-500 flex items-center pt-1 gap-1">
					<div className="w-3 h-3 rounded-full bg-yellow-400" />
					Bill
				</div>
			</>
		);
	} else if (transactionType.toLowerCase() === "invoice") {
		return (
			<>
				<div className="text-sm font-medium text-gray-900">Outcome</div>
				<div className="text-sm text-gray-500 flex items-center pt-1 gap-1">
					<div className="w-3 h-3 rounded-full bg-yellow-400" />
					Invoice
				</div>
			</>
		);
	} else if (transactionType.toLowerCase() === "sales") {
		return (
			<>
				<div className="text-sm font-medium text-gray-900">Income</div>
				<div className="text-sm text-gray-500 flex items-center pt-1 gap-1">
					<div className="w-3 h-3 rounded-full bg-green-400" />
					Sale
				</div>
			</>
		);
	} else if (transactionType.toLowerCase() === "expense") {
		return (
			<>
				<div className="text-sm font-medium text-gray-900">Outcome</div>
				<div className="text-sm text-gray-500 flex items-center pt-1 gap-1">
					<div className="w-3 h-3 rounded-full bg-yellow-400" />
					Expense
				</div>
			</>
		);
	} else if (transactionType.toLowerCase() === "vendor payment") {
		return (
			<>
				<div className="text-sm font-medium text-gray-900">Outcome</div>
				<div className="text-sm text-gray-500 flex items-center pt-1 gap-1">
					<div className="w-3 h-3 rounded-full bg-yellow-400" />
					Vendor Payment
				</div>
			</>
		);
	} else {
		return (
			<>
				<div className="text-sm font-medium text-gray-900">Outcome</div>
				<div className="text-sm text-gray-500 flex items-center pt-1 gap-1">
					<div className="w-3 h-3 rounded-full bg-yellow-400" />
					Other
				</div>
			</>
		);
	}
};
