import { useContext, useEffect, useState } from "react";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { useQuery } from "react-query";

import { UIContext } from "../context/UIContext";
import PreviewReceipt from "../components/previews/PreviewReceipt";
import NewReceipt from "../components/forms/NewReceipt";
import * as Sales from "../utils/sales";
import { formatPrice } from "../utils/misc";
import LoadingPulse from "../components/utils/LoadingPulse";
import EmptyState from "../components/utils/EmptyState";
import SuccessAlert from "../components/utils/SuccessAlert";
import ErrorAlert from "../components/utils/ErrorAlert";

export default function SalesReceipt() {
	const { isLoading, data: sales } = useQuery("sales", Sales.get);
	const { setSlideOver } = useContext(UIContext);
	const [showFilter, setShowFilter] = useState(false);
	const [preview, setPreview] = useState(false);
	const [success, setSuccess] = useState(false);
	const [failure, setFailure] = useState(false);
	const [currentSale, setCurrentSale] = useState({});
	const [filteredSales, setFilteredSales] = useState([]);

	const totalSales = (products) => {
		let total = 0;
		products.forEach((prod) => {
			total += prod.selling_price * prod.quantity;
		});

		return formatPrice(total);
	};

	const setSale = (sale) => {
		setCurrentSale(sale);
		setPreview(!preview);
	};

	const toggleNewReceipt = () => {
		setSlideOver((prevState) => {
			return {
				...prevState,
				showAddReceiptPage: true,
			};
		});
	};

	const filterSales = (event) => {
		const value = event.target.value;
		let newSales = sales.filter((sale) => {
			const valueAsDate = Date.parse(value);
			const createdAt = Date.parse(sale.createdAt.split("T")[0]);

			return createdAt === valueAsDate;
		});
		setFilteredSales(newSales);
	};

	useEffect(() => {
		if (sales) {
			setFilteredSales(sales);
		}
	}, [sales]);

	return (
		<>
			<section>
				<SuccessAlert
					title={"Success"}
					message={"Receipt has been generated successfully."}
					open={success}
					setOpen={setSuccess}
					fallbackPath={"/sales/receipt"}
					fallbackName={"receipts"}
				/>
				<ErrorAlert
					message={
						"Product stock amount is less than the quantity you want to sell."
					}
					title={"Error!"}
					setOpen={setFailure}
					open={failure}
					fallbackName={"receipts"}
					fallbackPath={"#"}
				/>
				<div className="flex justify-between items-center mt-6">
					<div>
						<h1 className="greeting text-3xl">Sales Receipts</h1>
						<p className="pt-3">A list of all sales receipts.</p>
					</div>
					<div className="flex items-center gap-6">
						<button
							className={`text-[#2046cf] ${
								showFilter ? "block" : "hidden"
							}`}
							onClick={() => setFilteredSales(sales)}
						>
							Restore sales
						</button>
						<div className="flex items-center">
							<button
								className="flex items-center justify-center pl-3"
								onClick={(e) => {
									e.preventDefault();
									setShowFilter(!showFilter);
								}}
							>
								<FunnelIcon
									className="h-6 w-6"
									aria-hidden="true"
								/>
							</button>
						</div>
						<button
							className="select flex items-center text-[#2046cf] p-2 px-4 rounded-3xl border border-[#2046cf] hover:bg-[#2046cf] hover:text-white"
							onClick={toggleNewReceipt}
						>
							<span className="text-md font-medium">
								New receipt
							</span>
							<span className="material-symbols-outlined">
								add
							</span>
						</button>
					</div>
				</div>
				<div className={`mt-3 gap-4 ${showFilter ? "flex" : "hidden"}`}>
					<div>
						<label
							htmlFor="date"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by date
						</label>
						<input
							id="date"
							onChange={(e) => filterSales(e)}
							type={"date"}
							name="date"
							className="border mt-1 block w-64 rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						/>
					</div>
				</div>
			</section>
			<section className="mt-2">
				{!filteredSales || filteredSales?.length === 0 ? (
					<EmptyState
						title={"sales receipt"}
						stateKey={"showAddReceiptPage"}
					/>
				) : (
					<table className="min-w-full divide-y divide-gray-200">
						<thead className="bg-gray-50">
							<tr>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Date
								</th>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Number
								</th>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Customer
								</th>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Payment Mode
								</th>
								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Amount
								</th>

								<th
									scope="col"
									className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
								>
									Created By
								</th>
							</tr>
						</thead>
						<tbody className="bg-white divide-y divide-gray-200">
							{filteredSales?.map((sale) => {
								return (
									<tr key={sale._id}>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												{new Date(
													sale.createdAt
												).toDateString()}
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												{sale.number}
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												{sale.customer_id.name}
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900 capitalize">
												{sale.payment_mode}
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												D{totalSales(sale.products)}
											</div>
										</td>

										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												{sale.generated_by?.name}
											</div>
										</td>
										<td
											className="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
										>
											<button
												className="text-indigo-600 hover:text-indigo-900"
												onClick={() => setSale(sale)}
											>
												View
											</button>
										</td>
									</tr>
								);
							})}
							{isLoading && <LoadingPulse itemCount={6} />}
						</tbody>
					</table>
				)}
			</section>
			<NewReceipt setSuccess={setSuccess} setFailure={setFailure}/>
			<PreviewReceipt
				open={preview}
				setOpen={setPreview}
				sale={currentSale}
			/>
		</>
	);
}
