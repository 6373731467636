import apiClient from './fetch';

export async function getTotalSales() {
    try {
        const { data: totalSales } = await apiClient.get('/dashboard/totalsales');
        return totalSales.data;
    } catch(error) {
        throw error;
    }
}

export async function cashflow() {
    try {
        const { data: cashflowData } = await apiClient.get('/dashboard/cashflow');
        return cashflowData.data
    } catch(error) {
        throw error;
    }
}

export async function profitLoss() {
    try {
        const { data: profitloss } = await apiClient.get('/dashboard/profitloss');
        return profitloss.data
    } catch(error) {
        throw error;
    }
}
