export default function StepOne() {
	return (
		<>
			<div className="flex flex-col mb-6">
				<label
					for="name"
					className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
				>
					Business Name
				</label>
				<div className="relative">
					<div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
						<span className="material-symbols-outlined">domain</span>
					</div>

					<input
						id="name"
						type="text"
						name="name"
						className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-300 w-full py-2 focus:outline-none focus:border-[#2046cf]"
						placeholder="Business Name"
					/>
				</div>
			</div>
			<div className="flex flex-col mb-6">
				<label
					for="email"
					className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
				>
					Email Address:
				</label>
				<div className="relative">
					<div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
						<span className="material-symbols-outlined">mail</span>
					</div>

					<input
						id="email"
						type="email"
						name="email"
						className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-300 w-full py-2 focus:outline-none focus:border-[#2046cf]"
						placeholder="Email Address"
					/>
				</div>
			</div>
			<div className="flex flex-col mb-6">
				<label
					for="password"
					className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
				>
					Password:
				</label>
				<div className="relative">
					<div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
						<span className="material-symbols-outlined">lock</span>
					</div>

					<input
						id="password"
						type="password"
						name="password"
						className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-300 w-full py-2 focus:outline-none focus:border-[#2046cf]"
						placeholder="Password"
					/>
				</div>
			</div>
		</>
	);
}
