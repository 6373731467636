export default function Tabs({active, setActive}) {
	const toggleActive = () => {
		active === "stores" ? setActive("shops") : setActive("stores");
	};

	return (
		<div className="my-2">
			<div className="sm:hidden">
				<label htmlFor="tabs" className="sr-only">
					Select a tab
				</label>
				<select
					id="tabs"
					name="tabs"
					className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    defaultValue={'stores'}
				>
					<option value={'stores'}>Stores</option>

					<option value={'shops'}>Shops</option>
				</select>
			</div>
			<div className="hidden sm:block">
				<div className="border-b border-gray-200">
					<nav className="-mb-px flex space-x-8" aria-label="Tabs">
						<a
							onClick={toggleActive}
							className={`${active === 'stores' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'} whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer`}
							aria-current="page"
						>
							Stores
						</a>

						<a
							onClick={toggleActive}
							className={`${active === 'shops' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'} whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer`}
						>
							Shops
						</a>
					</nav>
				</div>
			</div>
		</div>
	);
}
