import { createContext, useState } from "react";

const initialValue = {
    showAddProductPage: false,
    showAddStockTransferPage: false,
    showAddCustomerPage:false,
    showAddVendorPage:false,
    showAddCustomerPaymentPage: false,
    showAddExpensePage: false,
    showAddReceiptPage: false,
    showAddEditProductPage: false,
    showAddEditInvoicePage: false,
    showAddTransferPage: false,
    showAddVendorPaymentPage: false,
}

export const UIContext = createContext(initialValue);

export default function UIContextProvider({children}) {
    const [ slideOver, setSlideOver ] = useState({...initialValue})
    return (
        <UIContext.Provider value={{slideOver, setSlideOver}}>
            {children}
        </UIContext.Provider>
    )
}
