import { useState } from "react";
import { useQuery } from "react-query";

import * as Shops from "../../../utils/shop";
import { get } from "../../../utils/products";
import Comboboxes from "../../utils/Combobox";

export default function InventoryValuation() {
	const { data: shops } = useQuery("shops", Shops.get);
	const { data: allProducts } = useQuery("products", get);
	const [product, setProduct] = useState({});
	return (
		<>
			<form className="flex items-center gap-6">
				<div className="flex flex-col gap-6">
					<div className="flex items-center gap-5">
						<div>
							<label
								htmlFor="store"
								className="block text-sm font-medium text-gray-700 pb-1"
							>
								Select a shop
							</label>
							<select
								id="store"
								name="store"
								className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm bg-white border"
							>
								<option>Select a shop</option>
								{shops?.data.map((shop, index) => {
									return (
										<option value={shop._id} key={index}>
											{shop.name}
										</option>
									);
								})}
							</select>
						</div>
						<div>
							<Comboboxes
								list={allProducts}
								title={"Select a product"}
								selectedItem={product}
								setSelectedItem={setProduct}
							/>
						</div>
					</div>
					<div className="flex items-end gap-5">
						<div className="flex items-center gap-5">
							<div>
								<label
									htmlFor="date"
									className="block text-sm font-medium text-gray-700"
								>
									From <span className="text-red-700">*</span>
								</label>
								<div className="mt-2">
									<input
										type="date"
										name="date"
										id="date"
										className="block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
									/>
								</div>
							</div>
							<div>
								<label
									htmlFor="date"
									className="block text-sm font-medium text-gray-700"
								>
									To
									<span className="text-red-700">*</span>
								</label>
								<div className="mt-2">
									<input
										type="date"
										name="date"
										id="date"
										className="block w-full rounded-md border p-2 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
									/>
								</div>
							</div>
						</div>
						<button className="select flex items-center text-white p-3 px-4 rounded-3xl bg-[#2046cf]">
							<span className="text-sm">Update report</span>
						</button>
					</div>
				</div>
			</form>
			<table className="min-w-full divide-y divide-gray-200 mt-8">
				<thead className="bg-gray-50">
					<tr>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Date
						</th>
						{/*
                            <th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								SKU
							</th>
                    */}
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>
							Transaction Details
						</th>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>Quantity</th>
						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>Stock on Hand</th>

						<th
							scope="col"
							className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
						>Inventory Asset Value</th>
					</tr>
				</thead>
				<tbody className="bg-white divide-y divide-gray-200">
					{[].map((product, index) => {
						return (
							<tr key={index}>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										{product.name}
									</div>
								</td>
								{/*
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="text-sm text-gray-900">
											PRD-000001
										</div>
									</td>
                                    */}
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										{product.quantity}
									</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										{product.available_for_Sale}
									</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										{product.unit}
									</div>
								</td>

								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										D{product.cost_price}
									</div>
								</td>
								<td className="px-6 py-4 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										D{product.selling_price}
									</div>
								</td>

								<td className="px-6 py-4 w-20 whitespace-nowrap">
									<div className="text-sm text-gray-900">
										{new Date(
											product.createdAt
										).toDateString()}
									</div>
								</td>
								{/*
									<td className="px-6 py-4 whitespace-nowrap">
										<span
											className="
                        px-2
                        inline-flex
                        text-xs
                        leading-5
                        font-semibold
                        rounded-full
                        bg-green-100
                        text-green-800
                      "
										>
											True
										</span>
									</td>
                                    */}
								<td
									className="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
								>
									<button className="text-indigo-600 hover:text-indigo-900">
										View
									</button>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
}
