export const matchStore = (products, store) => {
	return store === "all"
		? products
		: products.filter((product) => {
				if (product.shop_id) {
					return product.shop_id._id === store;
				} else if(product.store_id) return product.store_id._id === store;
                else return false;
		  });
};

export const matchUnit = (products, unit) => {
	return unit === "all"
		? products
		: products.filter((product) => {
				return product.unit === unit;
		  });
};

export const matchDate = (products, date) => {
	return !date
		? products
		: products.filter((product) => {
				const createdAt = Date.parse(product.createdAt.split("T")[0]);
				const valueAsDate = Date.parse(date);
				return createdAt == valueAsDate;
		  });
};

export const matchName = (products, name) => {
    return name.length === 0
        ? products
        : products.filter((product) => {
                return product.name.toLowerCase().includes(name.toLowerCase());
          });
}
