import { useState } from "react";
import {
	MagnifyingGlassIcon,
	FunnelIcon,
	ArrowPathIcon,
} from "@heroicons/react/24/outline";
import NewEmployee from "../components/forms/NewEmployee";
import { useQuery, useQueryClient } from "react-query";
import { deleteEmployee, get } from "../utils/employee";
import PreviewEmployee from "../components/previews/PreviewEmployee";
import { resetPassword } from "../utils/auth";
import ErrorAlert from "../components/utils/ErrorAlert";
import SuccessAlert from "../components/utils/SuccessAlert";

export default function Employees() {
	const [showFilter, setShowFilter] = useState(false);
	const [showNewEmployee, setShowNewEmployee] = useState(false);
	const [currentEmployee, setCurrentEmployee] = useState(null);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [failed, setFailure] = useState(false);
	const [showPreview, setShowPreview] = useState(false);

    const queryClient = useQueryClient();
	const { isLoading, error, data: employees } = useQuery("employees", get);

	const displayRole = (role) => {
		if (role) {
			if (role === "store_manager") {
				return "Store manager";
			} else {
				return "Shop manager";
			}
		} else {
			return "General employee";
		}
	};

	const removeEmployee = async (employee) => {
		const status = await deleteEmployee(employee._id);
		if (status) {
			setSuccess(true);
            queryClient.invalidateQueries("employees");
		} else {
			setFailure(true);
		}

	};

	const preview = (employee) => {
		setCurrentEmployee(employee);
		setShowPreview(true);
	};

	const resetEmployeePassword = async (employee) => {
		setCurrentEmployee(employee);
		const [resetted, status] = await resetPassword(
			employee._id,
			setLoading
		);
		if (resetted) {
			setSuccess(true);
		} else {
			setFailure(true);
		}
	};

	return (
		<>
			<section>
				<SuccessAlert
					title={"Employee deleted"}
					message={"Employee deleted successfully"}
					open={success}
					setOpen={setSuccess}
					fallbackName={"employees"}
					fallbackPath={"/employees"}
				/>
				<ErrorAlert
					title={"Error"}
					message={
						"Unexpected error. You might not have authorization to remove this employee."
					}
					open={failed}
					setOpen={setFailure}
					fallbackName={"employees"}
					fallbackPath={"/employees"}
				/>
				<div className="flex justify-between items-center mt-6">
					<div>
						<h1 className="greeting text-3xl">Employees</h1>
						<p className="pt-3">
							A list of all employees and their roles as per the
							platform.
						</p>
					</div>
					<div className="flex items-center gap-4">
						<form className="flex items-center">
							<div className="relative mt-1 rounded-md shadow-sm w-72">
								<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
									<MagnifyingGlassIcon
										className="h-5 w-5 text-gray-400"
										aria-hidden="true"
									/>
								</div>
								<input
									type="text"
									name="filter"
									id="filter"
									className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm outline-none"
									placeholder="Search by customer or meter number."
								/>
							</div>
							<button
								className="flex items-center justify-center pl-3"
								onClick={(e) => {
									e.preventDefault();
									setShowFilter(!showFilter);
								}}
							>
								<FunnelIcon
									className="h-6 w-6"
									aria-hidden="true"
								/>
							</button>
						</form>
						<button
							className="select flex items-center text-[#2046cf] p-2 px-4 rounded-3xl border border-[#2046cf] hover:bg-[#2046cf] hover:text-white"
							onClick={() => setShowNewEmployee(!showNewEmployee)}
						>
							<span className="text-md font-medium">
								New employee
							</span>
							<span className="material-symbols-outlined">
								add
							</span>
						</button>
					</div>
				</div>
				<div
					className={`grid-cols-3 mt-3 gap-4 ${
						showFilter ? "grid" : "hidden"
					}`}
				>
					<div>
						<label
							htmlFor="branch"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by phone
						</label>
						<select
							id="branch"
							name="branch"
							className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm bg-white border"
						>
							<option>Brikama</option>
							<option>Canada</option>
							<option>Mexico</option>
						</select>
					</div>
					<div>
						<label
							htmlFor="status"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by phone
						</label>
						<select
							id="status"
							name="status"
							className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm bg-white border"
						>
							<option>All</option>
							<option>Canada</option>
							<option>Mexico</option>
						</select>
					</div>
				</div>
			</section>
			<section className="mt-2">
				<table className="min-w-full divide-y divide-gray-200">
					<thead className="bg-gray-50">
						<tr>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Name
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Email
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Phone
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								User Role
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Date Added
							</th>
							<th scope="col" className="relative px-6 py-3">
								<span className="sr-only">Edit</span>
							</th>
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						{employees &&
							employees.map((employee, index) => {
								return (
									<tr key={index}>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="flex items-center">
												<div className="ml-4">
													<div className="text-sm font-medium text-gray-900">
														{employee.name}
													</div>
													<div className="text-sm text-gray-500">
														EMP-00000{index + 1}
													</div>
												</div>
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												{employee.email}
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												{employee.phone}
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<span
												className="
                        px-2
                        inline-flex
                        text-xs
                        leading-5
                        font-semibold
                        rounded-full
                        bg-green-100
                        text-green-800
                      "
											>
												{displayRole(employee.role)}
											</span>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">
												{new Date(
													employee.createdAt
												).toDateString()}
											</div>
										</td>
										<td
											className="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
										>
											<button
												className="text-red-600 hover:text-red-900 flex items-center gap-2 hidden"
												onClick={() =>
													resetEmployeePassword(
														employee
													)
												}
											>
												Reset password
												{loading &&
													currentEmployee._id ===
														employee._id && (
														<ArrowPathIcon
															height={18}
															width={18}
															className="animate-spin"
														/>
													)}
											</button>
										</td>

										<td
											className="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
										>
											<button
												className="text-indigo-600 hover:text-indigo-900"
												onClick={() =>
													preview(employee)
												}
											>
												View
											</button>
											<button
												className="text-red-600 hover:text-indigo-900 ml-4"
												onClick={() =>
													removeEmployee(employee)
												}
											>
												Delete
											</button>
										</td>
									</tr>
								);
							})}
					</tbody>
				</table>
			</section>
			<NewEmployee open={showNewEmployee} setOpen={setShowNewEmployee} />
			<PreviewEmployee
				open={showPreview}
				setOpen={setShowPreview}
				employee={currentEmployee}
			/>
		</>
	);
}
