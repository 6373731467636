import apiClient from './fetch';

export async function get(userId) {
	try {
		const { data: activities } = await apiClient.get(`/activities/${userId}`);
        return activities.data;
	} catch (error) {
        throw error;
	}
}
