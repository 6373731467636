import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { FunnelIcon } from "@heroicons/react/24/outline";

import PreviewInvoice from "../components/previews/PreviewInvoice";
import { get, getStats } from "../utils/invoice";
import { calculateDue, formatPrice, toggleStatusColor } from "../utils/misc";
import * as Customer from "../utils/customers";
import PaymentContextProvider from "../context/PaymentContext";
import { Link } from "react-router-dom";
import EditInvoice from "../components/forms/EditInvoice";
import InvoiceContextProvider from "../context/InvoiceContext";
import EmptyState from "../components/utils/EmptyState";

export default function Invoices() {
	const { data: invoices } = useQuery("invoices", get);
	const { data: invoiceStats } = useQuery("invoicestats", getStats);
	console.log("invoiceStats", invoiceStats);
	const { data: customers } = useQuery("customers", Customer.get);
	const [showFilter, setShowFilter] = useState(false);
	const [previewInvoice, setPreviewInvoice] = useState(false);
	const [currentInvoice, setCurrentInvoice] = useState({});
	const [filteredInvoices, setFilteredInvoices] = useState([]);

	const showInvoice = (invoice) => {
		setCurrentInvoice(invoice);
		setPreviewInvoice(true);
	};

	const calculateBalance = (invoice) => {
		let total = 0;
		invoice.products.forEach((product) => {
			total += product.selling_price * product.quantity;
		});

		return formatPrice(total - invoice.totalPayments);
	};

	const calculateInvoicePrice = (products) => {
		let total = 0;
		products.forEach((product) => {
			total += product.selling_price * product.quantity;
		});

		return formatPrice(total);
	};

	const getInvoiceStat = (status) => {
		const stats = invoiceStats && invoiceStats[0];
		if (stats) {
			return formatPrice(stats[status]);
		} else {
			return formatPrice(0);
		}
	};

	const filterInvoices = (event, type) => {
		const value = event.target.value;
		let newInvoices = [];
		switch (type) {
			case "customer":
				if (value === "All") {
					setFilteredInvoices([...invoices]);
				} else {
					newInvoices = invoices.filter(
						(invoice) => invoice.customer.name === value
					);
					setFilteredInvoices(newInvoices);
				}

				break;
			case "status":
				if (value === "All") {
					setFilteredInvoices([...invoices]);
				} else {
					newInvoices = invoices.filter(
						(invoice) => invoice.status === value.toLowerCase()
					);
					setFilteredInvoices(newInvoices);
				}
				break;

			case "shipped_date":
				newInvoices = invoices.filter((invoice) => {
					const shippedDate = Date.parse(
						invoice.shipped_date.split("T")[0]
					);
					const valueAsDate = Date.parse(value);

					return valueAsDate === shippedDate;
				});
				setFilteredInvoices(newInvoices);
				break;
			case "due_date":
				newInvoices = invoices.filter((invoice) => {
					const dueDate = Date.parse(invoice.due_date.split("T")[0]);
					const valueAsDate = Date.parse(value);

					return valueAsDate === dueDate;
				});
				setFilteredInvoices(newInvoices);
				break;
		}
	};

	useEffect(() => {
		if (invoices) {
			setFilteredInvoices(invoices);
		}
	}, [invoices]);

	return (
		<InvoiceContextProvider>
			<PaymentContextProvider>
				<section className="main">
					<div className="mb-5 flex items-center justify-between">
						<h1 className="greeting text-3xl">Invoices</h1>
						<Link
							to={"/invoices/new"}
							className="select flex items-center text-[#2046cf] p-2 px-4 rounded-3xl border border-[#2046cf] hover:bg-[#2046cf] hover:text-white"
						>
							<span className="text-md font-medium">
								Add invoice
							</span>
							<span className="material-symbols-outlined">
								add
							</span>
						</Link>
					</div>
					<div className="top-dash">
						<div className="sales">
							<div className="sales-in">
								<div>
									<div className="sales-top">
										<h3>Paid</h3>
										<span className="material-symbols-outlined">
											help
										</span>
									</div>
									<span>
										invoices that have been paid completely
									</span>
								</div>
								<div className="sales-nums">
									<h1>D{getInvoiceStat("paid")}</h1>
									<p className="line-through">
										up 0% vs 6 months before
									</p>
								</div>
							</div>
						</div>
						<div className="sales">
							<div className="sales-in">
								<div>
									<div className="sales-top">
										<h3>Overdue</h3>
										<span className="material-symbols-outlined">
											help
										</span>
									</div>
									<span>invoices that haven't been paid</span>
								</div>
								<div className="sales-nums">
									<h1>D{getInvoiceStat("overdue")}</h1>
									<p className="line-through">
										up 0% vs 6 months before
									</p>
								</div>
							</div>
						</div>
						<div className="sales">
							<div className="sales-in">
								<div>
									<div className="sales-top">
										<h3>Pending</h3>
										<span className="material-symbols-outlined">
											help
										</span>
									</div>
									<span>invoices that are pending</span>
								</div>
								<div className="sales-nums">
									<h1>D{getInvoiceStat("pending")}</h1>
									<p className="line-through">
										up 0% vs 6 months before
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="flex my-6">
						<button
							className="flex items-center justify-center"
							onClick={() => setShowFilter(!showFilter)}
						>
							<FunnelIcon
								className="h-6 w-6"
								aria-hidden="true"
							/>
							<span className="pl-2">Filter invoices</span>
						</button>
					</div>
					<div
						className={`grid-cols-4 mt-3 gap-4 mt-10 ${
							showFilter ? "grid" : "hidden"
						}`}
					>
						<div>
							<label
								htmlFor="customer"
								className="block text-sm font-medium text-gray-700"
							>
								Filter by customer
							</label>
							<select
								id="custoemr"
								name="customer"
								className="mt-1 block w-full rounded-md border bg-transparent border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
								onChange={(e) => filterInvoices(e, "customer")}
							>
								<option>All</option>
								{customers?.map((customer, index) => {
									return (
										<option key={index}>
											{customer.name}
										</option>
									);
								})}
							</select>
						</div>
						<div>
							<label
								htmlFor="status"
								className="block text-sm font-medium text-gray-700"
							>
								Filter by status
							</label>
							<select
								id="status"
								name="status"
								className="mt-1 block w-full rounded-md border bg-transparent border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
								onChange={(e) => filterInvoices(e, "status")}
							>
								<option>All</option>
								<option>Pending</option>
								<option>Paid</option>
								<option>Overdue</option>
							</select>
						</div>
						<div>
							<label
								htmlFor="date"
								className="block text-sm font-medium text-gray-700"
							>
								Filter by shipped date
							</label>
							<input
								id="date"
								onChange={(e) =>
									filterInvoices(e, "shipped_date")
								}
								type={"date"}
								name="date"
								className="mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
							/>
						</div>
						<div>
							<label
								htmlFor="date"
								className="block text-sm font-medium text-gray-700"
							>
								Filter by due date
							</label>
							<input
								id="date"
								type={"date"}
								onChange={(e) => filterInvoices(e, "due_date")}
								name="date"
								className="mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
							/>
						</div>
					</div>
					{!filteredInvoices || filteredInvoices?.length === 0 ? (
						<EmptyState
							title={"invoice"}
							stateKey={"link-invoices"}
						/>
					) : (
						<table className="min-w-full divide-y divide-gray-200 mt-6">
							<thead className="bg-gray-50">
								<tr>
									<th
										scope="col"
										className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
									>
										Status
									</th>
									<th
										scope="col"
										className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
									>
										Due
									</th>
									<th
										scope="col"
										className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
									>
										Number
									</th>

									<th
										scope="col"
										className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
									>
										Customer
									</th>
									<th
										scope="col"
										className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
									>
										Amount
									</th>
									<th
										scope="col"
										className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
									>
										Balance due
									</th>
									<th
										scope="col"
										className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
									>
										Date
									</th>
									<th
										scope="col"
										className="relative px-6 py-3"
									>
										<span className="sr-only">Edit</span>
									</th>
								</tr>
							</thead>
							<tbody className="bg-white divide-y divide-gray-200">
								{filteredInvoices?.map((invoice, index) => {
									return (
										<tr key={index}>
											<td className="px-6 py-4 whitespace-nowrap">
												{toggleStatusColor(
													invoice.status
												)}
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{calculateDue(
														invoice.due_date
													)}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{invoice.invoice_number}
												</div>
											</td>

											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{invoice.customer.name}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													D
													{calculateInvoicePrice(
														invoice.products
													)}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm font-bold text-red-500">
													D{" "}
													{calculateBalance(invoice)}
												</div>
											</td>

											<td className="px-6 py-4 w-20 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{new Date(
														invoice.shipped_date
													).toDateString()}
												</div>
											</td>

											<td
												className="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
											>
												<button
													className="text-indigo-600 hover:text-indigo-900"
													onClick={() =>
														showInvoice(invoice)
													}
												>
													View
												</button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					)}

					<PreviewInvoice
						invoice={currentInvoice}
						open={previewInvoice}
						setOpen={setPreviewInvoice}
					/>
					<EditInvoice showPreview={setPreviewInvoice} />
				</section>
			</PaymentContextProvider>
		</InvoiceContextProvider>
	);
}
