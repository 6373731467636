import HighCharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { formatCashflow } from "../../lib/formatter";

const today = new Date().getDate();

const placeholder = {
	income: [
		5000, 20000, 12000, 7000, 23000, 7000, 5000, 7000, 9000, 0, 0, 0, 0,
	],
	outgoing: [
		6000, -5000, -2000, -1000, -13000, -3000, -5000, -10000, 0, 1000, 0, 0,
		0,
	],
	netchange: [
		1000, 15000, 1000, 6000, 10000, 5000, 0, -3000, -1000, 0, -1000, 0, 0,
	],
	categories: [],
};

const Cashflow = ({ cashflow }) => {
	const [data, setData] = useState({
		...placeholder,
	});
	const options = {
		colors: ["#23C770", "#8AA2B2", "#1C252C"],
		title: {
			text: "",
		},
		xAxis: {
			categories:
				data.categories.length === 0
					? [
							`Jan ${today}`,
							`Feb ${today}`,
							`Mar ${today}`,
							`Apr ${today}`,
							`May ${today}`,
							`Jun ${today}`,
							`Jul ${today}`,
							`Aug ${today}`,
							`Sep ${today}`,
							`Oct ${today}`,
							`Nov ${today}`,
							`Dec ${today}`,
					  ]
					: data.categories,
			labels: {
				autoRotation: undefined,
			},
		},
		yAxis: {
			title: {
				text: undefined,
			},
		},
		plotOptions: {
			series: {
				stacking: "normal",
			},
			column: {
				groupPadding: 0.1,
			},
		},
		series: [
			{
				name: "Inflow",
				type: "column",
				data: data.income,
				animation: true ? { duration: 500 } : false, // default is 1000ms, current chosen value is arbitrary
			},
			{
				name: "Outflow",
				type: "column",
				data: data.outgoing.map((outFlowAmount) => outFlowAmount * -1),
				animation: true ? { duration: 500 } : false, // default is 1000ms, current chosen value is arbitrary
			},
			{
				name: "Net Change",
				type: "line",
				data: data.netchange,
				animation: true ? { duration: 500 } : false, // default is 1000ms, current chosen value is arbitrary
				marker: {
					fillColor: "#fff",
					lineWidth: 2,
					lineColor: undefined,
				},
			},
		],
	};

	useEffect(() => {
		if (cashflow) {
			if (cashflow[0]) {
				const { income, outgoing, netchange, categories } =
					formatCashflow(cashflow[0].cashflow);
				setData({ income, outgoing, netchange, categories });
			}
		}
	}, [cashflow]);

	return <HighchartsReact highcharts={HighCharts} options={options} />;
};

export default Cashflow;
