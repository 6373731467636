import { RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import UIContextProvider from "./context/UIContext";
import router from "./router";

import "./App.css";

const queryClient = new QueryClient();

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<UIContextProvider>
				<RouterProvider router={router} />;
			</UIContextProvider>
		</QueryClientProvider>
	);
}

export default App;
