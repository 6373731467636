import { createBrowserRouter } from "react-router-dom";

/* Pages */
import Dashboard from "./pages/Dashboard";
import Products from "./pages/Products";

import Layout from "./components/Layout";
import Customers from "./pages/Customers";
import Vendors from "./pages/Vendors";
import Stores from "./pages/Stores";
import Stocks from "./pages/Stocks";
import Invoices from "./pages/Invoices";
import Bills from "./pages/Bills";
import NewInvoice from "./pages/NewInvoice";
import CustomerPayment from "./pages/CustomerPayment";
import NewBill from "./pages/NewBill";
import Expenses from "./pages/Expenses";
import SalesReceipt from "./pages/SalesReceipt";
import Reports from "./pages/Reports";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Verify from "./pages/auth/Verify";
import Employees from "./pages/Employees";
import Settings from "./pages/Settings";
import Transactions from "./pages/accounting/Transactions";
import Charts from "./pages/accounting/Charts";
import Reconciliations from "./pages/accounting/Reconciliations";
import Help from "./pages/Help";
import Accounts from "./pages/accounting/Accounts";
import Transfers from "./pages/accounting/Transfers";
import Orders from "./pages/Orders";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Layout />,
		children: [
			{
				path: "",
				element: <Dashboard />,
			},
			{
				path: "/products",
				element: <Products />,
			},
			{
				path: "/customers",
				element: <Customers />,
			},
			{
				path: "/employees",
				element: <Employees />,
			},
			{
				path: "/customer/payments",
				element: <CustomerPayment />,
			},
			{
				path: "/vendors",
				element: <Vendors />,
			},
			{
				path: "/stores",
				element: <Stores />,
			},
			{
				path: "/stocks",
				element: <Stocks />,
			},
			{
				path: "/expenses",
				element: <Expenses />,
			},
			{
				path: "/invoices",
				element: <Invoices />,
			},
			{
				path: "/invoices/new",
				element: <NewInvoice />,
			},
			{
				path: "/bills",
				element: <Bills />,
			},
			{
				path: "/bills/new",
				element: <NewBill />,
			},
			{
				path: "/sales/receipt",
				element: <SalesReceipt />,
			},
			{
				path: "/sales/orders",
				element: <Orders />,
			},
			{
				path: "/reports",
				element: <Reports />,
			},
			{
				path: "/settings",
				element: <Settings />,
			},
			{
				path: "/accounts",
				element: <Accounts />,
			},
			{
				path: "/transfers",
				element: <Transfers />,
			},
			{
				path: "/transactions",
				element: <Transactions />,
			},
			{
				path: "/charts",
				element: <Charts />,
			},
			{
				path: "/reconciliations",
				element: <Reconciliations />,
			},
			{
				path: "/help",
				element: <Help />,
			},
		],
	},
	{
		path: "login",
		element: <Login />,
	},
	{
		path: "register",
		element: <Register />,
	},
	{
		path: "verify",
		element: <Verify />,
	},
]);

export default router;
