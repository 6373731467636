import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const Pie = () => {
	// Data retrieved from https://netmarketshare.com
	const options = {
		chart: {
			plotBackgroundColor: null,
			plotBorderWidth: null,
			plotShadow: false,
			type: "pie",
			width: 500,
			height: 300,
		},
		title: {
			text: "",
			align: "left",
		},
		tooltip: {
			pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
		},
		accessibility: {
			point: {
				valueSuffix: "%",
			},
		},
		plotOptions: {
			pie: {
				allowPointSelect: true,
				cursor: "pointer",
				dataLabels: {
					enabled: true,
					format: "<b>{point.name}</b>: {point.percentage:.1f} %",
				},
			},
		},
		series: [
			{
				name: "Brands",
				colorByPoint: true,
				data: [
					{
						name: "DVD Compact 48G",
						y: 100,
						sliced: true,
						selected: true,
					},
				],
			},
		],
	};
	return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default Pie;
