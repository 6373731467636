export default function Verify() {
	return (
		<section className="h-screen w-full flex justify-center items-center">
			<div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
				<div className="font-medium text-lg text-gray-800">Verify Email</div>
				<div className="relative mt-2 h-px bg-gray-300">
					<div className="flex w-full">
						<span className="bg-white text-sm text-gray-500">Enter OTP sent to your email <span className="font-bold text-[#2046cf]">ndimz@gmail.com</span> </span>
					</div>
				</div>
				<div className="mt-10">
					<form action="#">
						<div className="flex flex-col mb-6">
							<label
								htmlFor="otp"
								className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
							>OTP: </label>
							<div className="relative">
								<div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
									<span className="material-symbols-outlined">
										lock
									</span>
								</div>

								<input
									id="otp"
									type="text"
									name="otp"
									className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-300 w-full py-2 focus:outline-none focus:border-[#2046cf]"
									placeholder="OTP"
								/>
							</div>
						</div>
						
						<div className="flex w-full">
							<button
								type="submit"
								className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-[#2046cf] rounded py-2 w-full transition duration-150 ease-in"
							>
								<span className="mr-2 uppercase">
                                    Confirm
								</span>
								<span>
									<svg
										className="h-6 w-6"
										fill="none"
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
									</svg>
								</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</section>
	);
}
