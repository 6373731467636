import { useContext, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { MagnifyingGlassIcon, FunnelIcon } from "@heroicons/react/24/outline";

import * as Vendor from "../utils/vendors";
import NewVendor from "../components/forms/NewVendor";
import AlertDanger from "../components/utils/AlertDanger";
import { UIContext } from "../context/UIContext";
import { handleNavigate } from "../utils/misc";
import SuccessAlert from "../components/utils/SuccessAlert";

export default function Vendors() {
    const queryClient = useQueryClient();
	const { data: vendors } = useQuery("vendors", Vendor.get);
	const { setSlideOver } = useContext(UIContext);
	const [success, setSuccess] = useState(false);
	const [currentVendor, setCurrentVendor] = useState({});
	const [showFilter, setShowFilter] = useState(false);
	const [showAlert, setShowAlert] = useState(false);

	const handleVendorNav = (e, type) => {
		if (type === "edit") {
			handleNavigate(e, "showAddVendorPage", setSlideOver);
		} else {
			handleNavigate(e, "showAddVendorPage", setSlideOver);
		}
	};

	const removeVendor = async () => {
        const [ completed, _] = await Vendor.deleteVendor(currentVendor._id); 
        if(completed) {
            setShowAlert(false);
            queryClient.invalidateQueries('vendors')
            setSuccess(true)
        }
    };

	return (
		<>
			<section>
				<SuccessAlert
					title={"Success"}
					message={"Vendor has been added/removed successfully."}
					open={success}
					setOpen={setSuccess}
					fallbackPath={"/vendors"}
					fallbackName={"vendors"}
				/>
				<div className="flex justify-between items-center mt-6">
					<div>
						<h1 className="greeting text-3xl">Vendors</h1>
						<p className="pt-3">A list of all vendors.</p>
					</div>
					<div className="flex items-center gap-4">
						<form className="flex items-center">
							<div className="relative mt-1 rounded-md shadow-sm w-72">
								<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
									<MagnifyingGlassIcon
										className="h-5 w-5 text-gray-400"
										aria-hidden="true"
									/>
								</div>
								<input
									type="text"
									name="filter"
									id="filter"
									className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm outline-none"
									placeholder="Search by customer or meter number."
								/>
							</div>
							<button
								className="flex items-center justify-center pl-3"
								onClick={(e) => {
									e.preventDefault();
									setShowFilter(!showFilter);
								}}
							>
								<FunnelIcon
									className="h-6 w-6"
									aria-hidden="true"
								/>
							</button>
						</form>
						<button
							onClick={(e) => handleVendorNav(e, "add")}
							className="select flex items-center text-[#2046cf] p-2 px-4 rounded-3xl border border-[#2046cf] hover:bg-[#2046cf] hover:text-white"
						>
							<span className="text-md font-medium">
								New vendor
							</span>
							<span className="material-symbols-outlined">
								add
							</span>
						</button>
					</div>
				</div>
				<div
					className={`grid-cols-3 mt-3 gap-4 ${
						showFilter ? "grid" : "hidden"
					}`}
				>
					<div>
						<label
							htmlFor="branch"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by phone
						</label>
						<select
							id="branch"
							name="branch"
							className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						>
							<option>Brikama</option>
							<option>Canada</option>
							<option>Mexico</option>
						</select>
					</div>
					<div>
						<label
							htmlFor="status"
							className="block text-sm font-medium text-gray-700"
						>
							Filter by phone
						</label>
						<select
							id="status"
							name="status"
							className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
						>
							<option>All</option>
							<option>Canada</option>
							<option>Mexico</option>
						</select>
					</div>
				</div>
			</section>
			<section className="mt-2">
				<table className="min-w-full divide-y divide-gray-200">
					<thead className="bg-gray-50">
						<tr>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Name
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Email
							</th>
							<th
								scope="col"
								className="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
							>
								Phone
							</th>
							<th scope="col" className="relative px-6 py-3">
								<span className="sr-only">Edit</span>
							</th>
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						{vendors?.map((vendor, index) => {
							return (
								<tr key={index}>
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="flex items-center">
											<div className="ml-4">
												<div className="text-sm font-medium text-gray-900">
													{vendor.name}
												</div>
												<div className="text-sm text-gray-500">
													VEN-00000{index}
												</div>
											</div>
										</div>
									</td>
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="text-sm text-gray-900">
											{vendor.email}
										</div>
									</td>
									<td className="px-6 py-4 whitespace-nowrap">
										<div className="text-sm text-gray-900">
											{vendor.phone}
										</div>
									</td>

									<td
										className="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
									>
										<button
											className="text-indigo-600 hover:text-indigo-900"
											onClick={(e) =>
												handleVendorNav(e, "edit")
											}
										>
											Edit
										</button>
										<button
											className="text-red-600 hover:text-indigo-900 pl-6"
											onClick={() => {
												setShowAlert(!showAlert);
												setCurrentVendor(vendor);
											}}
										>
											Remove
										</button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				<AlertDanger
					open={showAlert}
					setOpen={setShowAlert}
					title={"Remove vendor"}
					message={
						"Are you sure you want to remove this vendor? All of your data will be permanently removed from our servers forever. This action cannot be undone."
					}
					action={removeVendor}
				/>
			</section>
			<NewVendor setSuccess={setSuccess} />
		</>
	);
}
