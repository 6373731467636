import { Fragment, useState, useContext } from "react";
import { useQueryClient } from "react-query";
import { Dialog, Transition } from "@headlessui/react";
import {
	PencilSquareIcon,
	TrashIcon,
	XMarkIcon,
} from "@heroicons/react/24/outline";

import ProductOverview from "./ProductOverview";
import ProductTransactions from "./ProductTransactions";
import { UIContext } from "../../context/UIContext";
import { handleNavigate } from "../../utils/misc";
import { ProductContext } from "../../context/ProductContext";
import AlertDanger from "../utils/AlertDanger";
import * as Product from '../../utils/products';

export default function PreviewProduct({ open, setOpen, product }) {
    const queryClient = useQueryClient();
	const { setProduct } = useContext(ProductContext);
	const { setSlideOver } = useContext(UIContext);
	const [active, setActive] = useState("overview");
	const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);

	const remove = () => {
		setShowAlert(!showAlert);
	};

	const removeProduct = async () => {
        const [ success, _ ] = await Product.remove(product._id, setLoading);
        if(success) {
            queryClient.invalidateQueries('products');
            setOpen(false);
        }
    };

	const edit = (e) => {
		setProduct(product);
		handleNavigate(e, "showAddEditProductPage", setSlideOver);
	};

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={setOpen}>
				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-500"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-500"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-hidden">
					<div className="absolute inset-0 overflow-hidden">
						<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-x-full"
								enterTo="translate-x-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-x-0"
								leaveTo="translate-x-full"
							>
								<Dialog.Panel className="pointer-events-auto w-screen max-w-3xl">
									<div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
										<div className="px-4 sm:px-6">
											<div className="flex items-start justify-between">
												<Dialog.Title className="text-xl font-medium text-gray-900">
													{product.name}
												</Dialog.Title>
												<div className="ml-3 flex h-7 items-center">
													<button
														type="button"
														className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
														onClick={() =>
															setOpen(!open)
														}
													>
														<span className="sr-only">
															Close panel
														</span>
														<XMarkIcon
															className="h-6 w-6"
															aria-hidden="true"
														/>
													</button>
												</div>
											</div>
										</div>
										<div className="relative mt-2 flex-1 px-4 sm:px-6">
											<div className="mb-4 flex items-center gap-6">
												<button
													className="flex items-center"
													onClick={edit}
												>
													<PencilSquareIcon
														width={13}
														height={13}
													/>
													<span className="text-xs pl-1">
														Edit
													</span>
												</button>
												<button
													className="flex items-center"
													onClick={remove}
												>
													<TrashIcon
														width={13}
														height={13}
													/>
													<span className="text-xs pl-1">
														Delete
													</span>
												</button>
											</div>
											<div className="flex items-center gap-8 border-b border-gray-300">
												<button
													className={`text-sm pb-2 ${
														active === "overview"
															? "border-b-2 font-bold border-blue-500"
															: ""
													}`}
													onClick={() =>
														setActive("overview")
													}
												>
													Overview
												</button>
												<button
                                                    disabled
													className={`cursor-not-allowed text-sm pb-2 ${
														active ===
														"transactions"
															? "border-b-2 font-bold border-blue-500"
															: ""
													}`}
													onClick={() =>
														setActive(
															"transactions"
														)
													}
												>
													Transactions
												</button>
												<button className="text-sm pb-2 cursor-not-allowed" disabled>
													History
												</button>
											</div>
											<div className="flex justify-between">
												{active === "overview" && (
													<ProductOverview
														product={product}
													/>
												)}
												{active === "transactions" && (
													<ProductTransactions />
												)}
											</div>
											<AlertDanger
                                                loading={loading}
												action={removeProduct}
												open={showAlert}
												setOpen={setShowAlert}
												title={"Delete product"}
												message={
													"Are you sure you want to delete this product? All of your data will be permanently removed from our servers forever. This action cannot be undone."
												}
											/>
										</div>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
